import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import "./filterscreen.css";
import { West } from "@mui/icons-material";
import bgThreeCircleResponsive from '../../assets/images/bg-three-circle-responsive.svg';
import useAppStore from "../../store/app.store";

interface Props {
    setFiltersScreen: Dispatch<SetStateAction<boolean>>;
    setAIStrategiesdata: any;
  AIStrategiesdata: any;
}

const AIStrategiesFilterScreen: React.FC<Props> = ({ setFiltersScreen, setAIStrategiesdata,
    AIStrategiesdata, }) => {

    const [risk, setRisk] = useState(0);
    // const [investmentAmount, setInvestmentAmount] = useState('Any');
   // const [selectedMobileFilter, setSelectedMobileFilter ] = useState('');
    const [currentFilteredData, setCurrentFilteredData] =
    useState(AIStrategiesdata);
    const [investmentAmount, setInvestmentAmount] = useState('Any');
    const [noOfFilter, setNoOfFilters] = useState(0);

    const handleInvestmentAmountChange = (value: string) => {
        if (value !== 'Any' && investmentAmount === 'Any') {
          setNoOfFilters((prevCount) => prevCount + 1);
        }
        if (investmentAmount === 'Any' && noOfFilter >= 2) {
          setNoOfFilters((prevCount) => prevCount - 1);
        }
        setInvestmentAmount(value);
        let filteredData = [];
        switch (value) {
          case 'Any':
            filteredData = AIStrategiesdata;
            break;
          case 'Under 1 Lakh':
            filteredData = AIStrategiesdata.filter((strategy: any) => {
              const capital = parseInt(
                strategy.minimum_investment_capital?.replace(/,/g, '')
              );
    
              return capital <= 100000;
            });
            break;
          case 'Under 5 lakhs':
            filteredData = AIStrategiesdata.filter((strategy: any) => {
              const capital = parseInt(
                strategy.minimum_investment_capital?.replace(/,/g, '')
              );
              return capital <= 500000;
            });
    
            break;
          case 'Above 5 Lakhs':
            filteredData = AIStrategiesdata.filter((strategy: any) => {
              const capital = parseInt(
                strategy.minimum_investment_capital?.replace(/,/g, '')
              );
              return capital >= 500000;
            });
            break;
          default:
            filteredData = AIStrategiesdata;
            break;
        }
        // console.log(filteredData);
    
        setAIStrategiesdata(filteredData);
        setCurrentFilteredData(filteredData);
      };
    useEffect(() => {
        const container = document.querySelector(".container-responsive");
        if (container !== null) {
            (container as HTMLElement).style.height = "0px";
            (container as HTMLElement).style.overflow = "hidden";
        }
    }, []);

    function Checked() {
        return (
            <svg
                className="checked"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="none"
                viewBox="0 0 21 21"
            >
                <circle
                    cx="10.03"
                    cy="10.367"
                    r="7.53"
                    stroke="#E7AF1D"
                    strokeWidth="5"
                ></circle>
            </svg>
        );
    }

    function NotChecked() {
        return (
            <svg
                className="notchecked"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="none"
                viewBox="0 0 21 21"
            >
                <circle
                    cx="10.03"
                    cy="10.499"
                    r="9.361"
                    stroke="#000"
                    strokeWidth="1.337"
                ></circle>
            </svg>
        );
    }


    function High() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                fill="none"
                viewBox="0 0 39 38"
            >
                <circle
                    cx="19.523"
                    cy="19.218"
                    r="18.75"
                    fill="#000"
                    fillOpacity="0.7"
                ></circle>
                <path
                    fill="#F82929"
                    d="M21.338 15.037v12.91h-2.42v-12.91H15.29l4.84-4.29 4.838 4.29h-3.63z"
                ></path>
            </svg>
        );
    }

    function Mid() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                fill="none"
                viewBox="0 0 39 38"
            >
                <circle
                    cx="19.314"
                    cy="19.218"
                    r="18.75"
                    fill="#000"
                    fillOpacity="0.7"
                ></circle>
                <path
                    fill="#E7AF1D"
                    d="M24.758 23.658v-7.535h-2.42v7.535H18.71l4.839 4.29 4.839-4.29h-3.63zM16.29 8.598l-4.839 4.29h3.63v7.535H17.5v-7.535h3.628l-4.838-4.29z"
                ></path>
            </svg>
        );
    }

    function Low() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                fill="none"
                viewBox="0 0 38 38"
            >
                <circle
                    cx="19.105"
                    cy="19.218"
                    r="18.75"
                    fill="#000"
                    fillOpacity="0.7"
                ></circle>
                <path
                    fill="#2ACD1C"
                    d="M20.92 22.584V9.674H18.5v12.91h-3.629l4.84 4.288 4.838-4.288h-3.63z"
                ></path>
            </svg>
        );
    }

    function handleApply(invamount?: string) {
        setFiltersScreen(false);
       // setSelectedMobileFilter(invamount);
    }

    return (
        <div className="filter-screen">
            <img src={bgThreeCircleResponsive} alt="bg" style={{
                position: "absolute" as "absolute",
                top: "21px",
                right: "7px",
                zIndex: "-1",
            }} />
            <div className="filter-screen-part-1">
                <div className="filter-screen-heading">
                    {
                        <West
                            onClick={() => {
                                setFiltersScreen(false);
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                left: "0px",
                                top: "20px",
                            }}
                        />
                    }
                    <span>
                        Filters
                        <div>
                            No Filter Selected <p>0</p>
                        </div>
                    </span>
                </div>
            </div>
            <div className="filter-screen-part-2">
                <div className="filter-screen-part-2-inner">
                    <div>Clear All</div>
                    <button onClick={() => handleApply()}>Apply</button>
                </div>
            </div>
            <div className="filter-screen-part-3">
                {/* <div className="filter-screen-part-3-heading">Subscription Type</div>
                <div className="filter-screen-part-3-options">
                    <div>
                        <input type="radio" name="subtype" id="subtype1" />
                        <label htmlFor="subtype1">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>All types</span>
                    </div>
                    <div>
                        <input type="radio" name="subtype" id="subtype2" />
                        <label htmlFor="subtype2">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Free Access</span>
                    </div>
                    <div>
                        <input type="radio" name="subtype" id="subtype3" />
                        <label htmlFor="subtype3">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Fee Based</span>
                    </div>
                </div> */}
            </div>
            <div className="filter-screen-part-4">
                <div className="filter-screen-part-4-heading">Investment Amount</div>
                <div className="filter-screen-part-4-options">
                    <div>
                        <input type="radio" name="invamount" id="invamount1" value='Any'
            onChange={() => handleInvestmentAmountChange('Any')} />
                        <label htmlFor="invamount1">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Any</span>
                    </div>
                    <div>
                        <input type="radio" name="invamount" id="invamount2" value='Under 1 Lakh'
            onChange={() => handleInvestmentAmountChange('Under 1 Lakh')} />
                        <label htmlFor="invamount2">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Under 1 Lakh</span>
                    </div>
                    <div>
                        <input type="radio" name="invamount" id="invamount3" value='Under 5 lakhs'
            onChange={() => handleInvestmentAmountChange('Under 5 lakhs')} />
                        <label htmlFor="invamount3">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Under 5 lakhs </span>
                    </div>
                    <div>
                        <input type="radio" name="invamount" id="invamount4"  value='Above 5 Lakhs'
            onChange={() => handleInvestmentAmountChange('Above 5 Lakhs')}/>
                        <label htmlFor="invamount4">
                            <Checked />
                            <NotChecked />
                        </label>
                        <span>Above 5 Lakhs </span>
                    </div>
                </div>
            </div>
            <div className="filter-screen-part-5">
                <div className="filter-screen-part-5-heading">Risk</div>
                <div className="filter-screen-part-5-flex">
                    <div className={`${risk === 1 ? "filter-screen-part-5-flex-special-div" : ""}`} onClick={() => {
                        risk === 1 ? setRisk(0) : setRisk(1)
                    }}>
                        <Low />
                        <span>Low</span>
                    </div>
                    <div className={`${risk === 2 ? "filter-screen-part-5-flex-special-div" : ""}`} onClick={() => {
                        risk === 2 ? setRisk(0) : setRisk(2)
                    }}>
                        <Mid />
                        <span>Medium</span>
                    </div>
                    <div className={`${risk === 3 ? "filter-screen-part-5-flex-special-div" : ""}`} onClick={() => {
                        risk === 3 ? setRisk(0) : setRisk(3)
                    }}>
                        <High />
                        <span>High</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIStrategiesFilterScreen;
