import React from "react";
import AuthSetupPage from "./AuthSetupPage";
import { Styles, Theme, ThemeTypes } from "../../Utils/Constants";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TimelineIcon from "@mui/icons-material/Timeline";
import UpdateIcon from "@mui/icons-material/Update";
import { PlayCircleOutline } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

interface IntroAndAuthSectionProps {
  // Define the props for your component here
}

const IntroAndAuthSection: React.FC<IntroAndAuthSectionProps> = (props) => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  return (
    <>
      <div style={windowSize <= 1000 ? { ...styles.flexContainerResponsive, position: "relative" } : { ...styles.flexContainer, position: "relative" }}>
        <img
          src="/images/reverseCircleGrp.png"
          alt="circle"
          style={windowSize <= 1000 ? {
            position: "absolute",
            bottom: "100px",
            right: "1.53px",
            width: "auto",
            height: "67px",
            zIndex: 2
          } : {
            position: "absolute",
            top: "52px",
            right: "50px",
            zIndex: 2
          }}
        />

        <div style={{ position: "absolute", bottom: "0px", width: "100%", height: "123px" }} className="curved-line"></div>

        <div
          style={windowSize <= 1000 ? {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '40.16px',
            lineHeight: '55px',
            textAlign: 'center',
            letterSpacing: '0.05em',
            color: '#000000',
            position: "relative",
            gap: "9px",
            marginTop: "62px",
            flexWrap: "wrap",
            padding: "0px 30px"
          } : {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '50px',
            lineHeight: '46px',
            letterSpacing: '0.05em',
            color: '#000000',
            position: "relative",
            gap: "9px",
            marginTop: "99px"
          }}
        >
          Invest
          <span
            style={{
              color: "#2747DD",
            }}
          >

            Smarter
          </span>
          with
          <span
            style={{
              color: "#2747DD",
            }}
          >
            Moneyy.ai
          </span>
          <img
            src="/images/revurseCurve.png"
            alt="reverse curve"
            style={windowSize <= 1000 ? {
              position: "absolute",
              bottom: "-9px",
              right: "40px",
              width: "auto",
              height: "10px",
            } : {
              position: "absolute",
              bottom: "-23px",
              right: "0px",
            }}
          ></img>
        </div>

        <div
          style={windowSize <= 1000 ? {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '26px',
            textAlign: 'center',
            letterSpacing: '0.07em',
            color: '#000000',
            padding: "0px 28px",
            marginTop: "20px"
          } : {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '36px',
            textAlign: 'center',
            letterSpacing: '0.07em',
            color: '#000000',
          }}
        >
          Automate Trading with AI Algo Strategies by SEBI Reg. Experts
        </div>
        <button
          style={windowSize <= 1000 ? {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12.2844px',
            lineHeight: '18px',
            letterSpacing: '0.04em',
            color: '#FFFFFF',
            padding: "11.52px 20.73px 12.71px 22.27px",
            background: '#2747DD',
            borderRadius: '5px',
            filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))'
          } : {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.04em',
            color: '#FFFFFF',
            padding: "16px 29px",
            background: '#2747DD',
            borderRadius: '5px',
            filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))'
          }}

          className="hover-explore-strategies border-box"

          onClick={() => {
            navigate("/strategy");
            window.scrollTo(0, 0); // Scroll to the top of the page directly
          }}
        >
          Explore Strategies
        </button>

        <div className="graph-animation-container">
          <div className="graph-line-animation"></div>
          <div className="graph-text-animation"></div>
        </div>

        {/* <img src={"/images/graph.png"} style={{width: "100%", maxWidth: "1000px"}}/> */}

        <img
          src="/images/halfC.png"
          alt="half circle"
          style={windowSize <= 1000 ? {
            position: "absolute",
            top: "286px",
            left: "0%",
            width: "auto",
            height: "77px",
            zIndex: 2
          } : {
            position: "absolute",
            bottom: "135px",
            left: "0%",
            zIndex: 2
          }}
        />
      </div>
    </>
  );
};

const styles = {
  startNow: {
    padding: "0 0 0 4rem",
  },
  icons: {
    color: Theme.colors.black70,
    height: 24,
  },
  titleColorText: {
    color: Theme.colors.blueSolid,
  },
  pText: {
    backgroundColor: Theme.colors.blueTainted15,
    borderTopRightRadius: Theme.borderRadius,
    borderBottomRightRadius: Theme.borderRadius,
    display: "inline-flex",
    padding: "1rem 2rem 1rem " + Theme.gapXXLarge,
    margin: "1rem 0 0 0",
  },
  leftContainer: {
    flex: "2",
    display: "flex",
    flexDirection: "column" as const,
    paddingTop: Theme.gapLarge,
    padding: "4rem 0rem",
  },
  descriptionText: {
    paddingLeft: Theme.gapSmall,
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.black70,
  },
  landingText: {
    color: Theme.colors.white,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex" as const,
    flexDirection: "column" as const,
    justifyContent: "flex-start" as const,
    alignItems: "center",
    height: "811px",
    gap: "30px"
  },
  flexContainerResponsive: {
    display: "flex" as const,
    flexDirection: "column" as const,
    justifyContent: "flex-start" as const,
    alignItems: "center",
    height: "680px",
    gap: "21px"
  },
  rightContainer: {
    flex: "3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginBox: {
    backgroundImage: Theme.colors.blueWhiteGradient,
    borderRadius: Theme.borderRadiusLarge,
    padding: "4rem 0rem",
    margin: Theme.gapLarge,
    boxShadow: "0 0 16px 0 " + Theme.colors.yellow,
    justifyContent: "center",
    zIndex: 1,
  },
};

export default IntroAndAuthSection;
