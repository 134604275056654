// export const formatNumber = (amount: string): string => {
//   const numericAmount = parseInt(amount?.replace(/,/g, ''), 10);

//   if (numericAmount < 100) {
//     return numericAmount.toString(); // Display values less than 100 as is
//   } else if (numericAmount < 100000) {
//     return Math.floor(numericAmount / 1000) + 'K'; // Display values between 1000 and 99999 as K format without decimals
//   } else if (numericAmount < 10000000) {
//     return Math.floor(numericAmount / 100000) + 'L'; // Display values between 100000 and 9999999 as L format without decimals
//   } else if (numericAmount < 100000000) {
//     return Math.floor(numericAmount / 1000000) + ' L'; // Display values between 10000000 and 99999999 in lakh format
//   } else {
//     return Math.floor(numericAmount / 10000000) + 'Cr'; // Display values greater than or equal to 100000000 as Cr format without decimals
//   }
// }; 874128

export const formatNumber = (amount: string): string => {
  const numericAmount = parseFloat(amount?.replace(/,/g, ''));

  if (isNaN(numericAmount)) {
    return 'Invalid Number';
  }

  const isNegative = numericAmount < 0;
  const absoluteAmount = Math.abs(numericAmount);
  const integerPart = Math.floor(absoluteAmount);
  const decimalPart = (absoluteAmount - integerPart).toFixed(1).substring(1); // Keep one decimal place if present

  if (integerPart < 1000) {
    // Display values less than 1000 as is
    return (isNegative ? '-' : '') + absoluteAmount.toFixed(1);
  } else if (integerPart < 100000) {
    // Display values between 1000 and 99999 in K format without decimals
    return (isNegative ? '-' : '') + (absoluteAmount / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else if (integerPart < 10000000) {
    // Display values between 100000 and 9999999 in L format without decimals
    return (isNegative ? '-' : '') + (absoluteAmount / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
  } else {
    // Display values greater than or equal to 10000000 in Cr format without decimals
    return (isNegative ? '-' : '') + (absoluteAmount / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
  }
};


export const formatCAGR = (cagr:any) => {
  if (typeof cagr === 'string') {
    // Remove the '%' sign and parse the number
    const cagrNumber = parseFloat(cagr.replace('%', ''));
    return isNaN(cagrNumber) ? 'N/A' : cagrNumber.toFixed(1) + '%';
  } else {
    return 'N/A';
  }
};



export const formatMoneyManaged = (value: number) => {
  if (value >= 10000000) {
    return `${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    return `${(value / 100000).toFixed(2)} L`;
  } else {
    return value?.toString();
  }
};

export const FixedNumber = (value: number) => {
  return value.toFixed(2);
};
