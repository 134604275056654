import React, { useEffect } from 'react';
import { Styles, Theme } from '../Utils/Constants';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import youtube from '../assets/images/youtube.png';
import xsocial from '../assets/images/x.png';
import linkedin from '../assets/images/linkedin.png';
import instagram from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.svg';
import logo from '../assets/images/logo.svg';
import useWindowSize from './Hooks/useWindowSize';
import { Link, useNavigate } from 'react-router-dom';

type FooterProps = {
  special?: boolean;
};

const Footer: React.FC<FooterProps> = ({ special }) => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    //setIsLogoClicked(true);
    //navigate('/home');
  };
  const websiteDescription = (
    <div
      style={{ ...styles.column, ...styles.descriptionText, cursor: 'pointer' }}
      onClick={handleLogoClick}
    >
      <img
        src={logo}
        alt='logo'
        onClick={() => {
          navigate('/home');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? styles.profileImageStyleResponsive
            : styles.profileImageStyle
        }
      />

      <p
        style={
          windowSize <= 540 ? styles.webpageDescResponsive : styles.webpageDesc
        }
       className={special ? 'special' : 'not so '}
      >
        Automate Investing or Trading with our AI strategies, Backtest Your Idea, and stay informed with real-time market insights.
      </p>
    </div>
  );

  const ExploreSection = (
    <div style={styles.column}>
      <p style={windowSize <= 540 ? Styles.h3TextResponsive : Styles.h3Text}>
        Explore
      </p>

      <p
        onClick={() => {
          navigate('/strategy');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        AI Strategies
      </p>
      <p
        onClick={() => {
          navigate('/backtesting');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Backtesting
      </p>
      <p
        onClick={() => {
          navigate('/market-news');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Market feed
      </p>
      <p
        onClick={() => {
          // navigate("/learn");
          window.open('https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ', '_blank');
          // Or you can use navigate if you're navigating within your Gatsby app
          // navigate('https://www.youtube.com/your-youtube-video-link');
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Learn
      </p>
      {/* <p onClick={()=> {
        navigate("/pricing");
      }} style={windowSize <= 540? { fontSize: Theme.fontSizes.h5, cursor: "pointer" } : { fontSize: Theme.fontSizes.h4, cursor: "pointer" }}>Pricing</p>
      <p onClick={()=> {
        navigate("/leaderboard");
      }} style={windowSize <= 540? { fontSize: Theme.fontSizes.h5, cursor: "pointer" } : { fontSize: Theme.fontSizes.h4, cursor: "pointer" }}>Leaderboard</p> */}
    </div>
  );

  const CompanySection = (
    <div style={styles.column}>
      <p style={windowSize <= 540 ? Styles.h3TextResponsive : Styles.h3Text}>
        Company
      </p>
      <p
        onClick={() => {
          navigate('/risk-disclosure');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Terms of Use
      </p>
      <p
        onClick={() => {
          navigate('/risk-disclosure');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Privacy Policy
      </p>
      <p
        onClick={() => {
          navigate('/risk-disclosure');
          window.scrollTo(0, 0); // Scroll to the top of the page directly
        }}
        style={
          windowSize <= 540
            ? { fontSize: Theme.fontSizes.h5, cursor: 'pointer' }
            : { fontSize: Theme.fontSizes.h4, cursor: 'pointer' }
        }
      >
        Risk Disclosure
      </p>
    </div>
  );

  const ContactUsSection = (
    <div
      style={
        windowSize <= 540
          ? { ...styles.column, ...{ fontSize: Theme.fontSizes.h5 } }
          : { ...styles.column }
      }
    >
      <p style={windowSize <= 540 ? {...Styles.h3TextResponsive, margin: 0} : Styles.h3Text}>
        Contact Us
      </p>
      {/* <p style={styles.boxIcon}>
        <PhoneIcon
          style={
            windowSize <= 540
              ? { ...styles.icon, ...{ fontSize: '14px', marginRight: '4px' } }
              : styles.icon
          }
        />
        +91-1234567890
      </p> */}
      <p style={windowSize <= 540 ? {...styles.boxIcon, marginTop: "11.37px", marginBottom: "0px"} : styles.boxIcon}>
        <EmailIcon
          style={
            windowSize <= 540
              ? { ...styles.icon, ...{ fontSize: '14px', marginRight: '2.7px' } }
              : {...styles.icon, marginRight: '0px'}
          }
        />{' '} &nbsp;
        hi@moneyy.ai
      </p>
      <p style={windowSize <= 540 ? {...styles.boxIcon, marginTop: "8.23px", marginBottom: "0px"} : styles.boxIcon}>
        <LocationOnIcon
          style={
            windowSize <= 540
              ? { ...styles.icon, ...{ fontSize: '14px', marginRight: '4px' } }
              : styles.icon
          }
        />{' '}&nbsp;
        India
      </p>
    </div>
  );

  const socialListing = (
    <div style={{ ...styles.column, cursor:'pointer' }}>
      <p style={windowSize <= 540 ? Styles.h3TextResponsive : Styles.h3Text}>
        Social
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        // navigate("/learn");
        window.open('https://twitter.com/moneyy_ai', '_blank');
        // Or you can use navigate if you're navigating within your Gatsby app
        // navigate('https://www.youtube.com/your-youtube-video-link');
      }}>
        <img src={xsocial} style={styles.social} alt='x' />
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        // navigate("/learn");
        window.open('https://www.instagram.com/_moneyy.ai/', '_blank');
        // Or you can use navigate if you're navigating within your Gatsby app
        // navigate('https://www.youtube.com/your-youtube-video-link');
      }}>
        <img src={instagram} style={styles.social} alt='instagram' />
      </p>
      {/* <p style={styles.boxIcon}>
        <img src={facebook} style={styles.social} alt='facebook' />
      </p> */}
      <p style={styles.boxIcon}
      onClick={() => {
        // navigate("/learn");
        window.open('https://www.linkedin.com/company/moneyy-ai/', '_blank');
        // Or you can use navigate if you're navigating within your Gatsby app
        // navigate('https://www.youtube.com/your-youtube-video-link');
      }}>
        <img src={linkedin} style={styles.social} alt='linkedin' />
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        // navigate("/learn");
        window.open('https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ', '_blank');
        // Or you can use navigate if you're navigating within your Gatsby app
        // navigate('https://www.youtube.com/your-youtube-video-link');
      }}>
        <img src={youtube} style={styles.social} alt='youtube' />
      </p>
    </div>
  );

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div
      style={
        windowSize <= 540
          ? { background: '#ffffff' }
          : { backgroundColor: '#f3f6ff' }
      }
      className={special ? 'footer-bg-special' : ''}
    >
      <footer
        className='footer-special'
        style={
          windowSize <= 540 ? styles.containerResponsive : styles.container
        }
      >
        <div style={styles.footerHeader}>
          <p
            style={
              windowSize <= 540 ? styles.pageTitleResponsive : styles.pageTitle
            }
          >
            Unanswered Queries?
          </p>
          <p
            className='no-wrap'
            style={
              windowSize <= 540
                ? styles.contactMailResponsive
                : styles.contactMail
            }
          >
            Reach us out at{' '}
            <span
              style={
                windowSize <= 540
                  ? styles.contactEmailResponsive
                  : styles.contactEmail
              }
            >hi@moneyy.ai</span>
          </p>
        </div>
        <div
          style={
            windowSize <= 1400 && windowSize > 800
              ? styles.footerColumnsMid
              : windowSize <= 800 && windowSize > 540
              ? styles.footerColumnsSma
              : windowSize <= 540
              ? styles.footerColumnsResponsive
              : styles.footerColumns
          }
        >
          {windowSize > 540 ? (
            <>
              {websiteDescription}
              {ExploreSection}
              {CompanySection}
              {ContactUsSection}
              {socialListing}
            </>
          ) : (
            <>
              {websiteDescription}
              <div style={styles.footerTwoCol}>
                {ExploreSection}
                {CompanySection}
              </div>
              <div style={styles.footerTwoCol}>
                {socialListing}
                {ContactUsSection}
              </div>
            </>
          )}
        </div>
        <div
          style={
            windowSize <= 540
              ? styles.footerCopyrightResponsive
              : styles.footerCopyright
          }
        >
          <p>&copy; {currentYear} Moneyy.ai Pvt Ltd. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const styles = {
  pageTitle: {
    fontSize: Theme.fontSizes.h1,
    margin: 0,
  },
  pageTitleResponsive: {
    color: Theme.colors.grey100,
    fontWeight: Theme.fontWeight.semiBold,
    lineHeight: 'normal',
    letterSpacing: '1.08px',
    fontSize: Theme.fontSizes.p18,
    margin: 0,
  },
  container: {
    paddingLeft: Theme.gapXXLarge,
    paddingRight: Theme.gapXXLarge,
    paddingTop: '58px',
  },
  containerResponsive: {
    padding: '58px 57px 0px 36px',
  },
  webpageDesc: {
    fontSize: Theme.fontSizes.h6,
  },
  webpageDescResponsive: {
    fontSize: Theme.fontSizes.h5,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.07em',
    color: '#252525'
  },
  boxIcon: {
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'flex-start' as const,
  },
  icon: {
    color: Theme.colors.blueSolid,
  },
  descriptionText: {
    paddingRight: Theme.gapSmall,
  },
  profileImageStyle: {
    width: Theme.iconWidth,
  },
  profileImageStyleResponsive: {
    height: Theme.footerIconHeight,
  },
  footerHeader: {
    paddingBottom: Theme.gapSmall,
    textAlign: 'left' as const,
    borderBottom: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  footerColumns: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    columnGap: '100px',
    rowGap: '30px',
    justifyContent: 'space-around',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerColumnsMid: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '80px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerColumnsSma: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr',
    columnGap: '80px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerColumnsResponsive: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr',
    columnGap: '0px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerTwoCol: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr',
    columnGap: '0px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  column: {
    width: 'auto',
    minWidth: 150,
  },
  footerCopyright: {
    padding: Theme.gapSmall,
    textAlign: 'left' as const,
    fontSize: Theme.fontSizes.h6,
    borderTop: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  footerCopyrightResponsive: {
    padding: Theme.gapSmall,
    textAlign: 'left' as const,
    fontSize: Theme.fontSizes.p11,
    borderTop: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  contactMailResponsive: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h5,
    fontWeight: Theme.fontWeight.regular,
    marginTop: Theme.gap5,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.06em',
  },
  contactMail: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h4,
    fontWeight: Theme.fontWeight.light,
    margin: 0,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.06em',
  },
  contactEmailResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.06em',
    color: '#252525',
  },
  contactEmail: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    color: '#252525',
  },
  social: {
    width: 20,
  },
};

export default Footer;
