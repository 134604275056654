export interface UserLeaderboard {
  ranking: number;
  name: string;
  user_image: string;
  level: string;
  strategies_PnL: number;
  strategies_PnL_percentage: number;
  market_news_engagement: string;
  no_of_backtests: number;
}

export interface ApiResponse {
  statusCode: string;
  body: {
    Users_Leaderboard: UserLeaderboard[];
  };
}

async function fetchUserLeaderboard(payload?:any): Promise<UserLeaderboard[]> {
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'leaderboard';
  try {
    const response = await fetch(
        apiUrl,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data: ApiResponse = await response.json();
    return data.body.Users_Leaderboard;
  } catch (error) {
    console.error('Error fetching user leaderboard:', error);
    return [];
  }
}

export default fetchUserLeaderboard;
