import React from "react";
import Subscription from "../Subscription/Subscription";

const MySubscriptions: React.FC = () => {
  return (
    <Subscription/>
  );
};

export default MySubscriptions;
