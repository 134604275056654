import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { Theme } from '../../../Utils/Constants';
import { BackTestAPIResponse, BackTestResponse } from '../../../API/API.interface';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useWindowSize from '../../Hooks/useWindowSize';
import BackTestResultsParser from '../../../Utils/BackTestResultParser';

interface Props {
  results: BackTestResultsParser;
  special?: boolean;
}
interface DailyAlgoResults {
  [date: string]: number;
}

export function StrategyPerformance(params: { name: string }) {

  const windowSize = useWindowSize();
  return (
    <p style={windowSize <= 500 ? styles.metricsHeaderResponsive : styles.metricsHeader} className="relative">
      {params.name} <div className={windowSize <= 500 ? "small-dot-responsive" : "small-dot"}></div>
    </p>
  );
}

function years() {
  return (
    <>
      <option value={'2024'}>2024</option>
      <option value={'2023'}>2023</option>
      <option value={'2022'}>2022</option>
      <option value={'2021'}>2021</option>
      <option value={'2020'}>2020</option>
      <option value={'2019'}>2019</option>
      <option value={'2018'}>2018</option>
      <option value={'2017'}>2017</option>
      <option value={'2016'}>2016</option>
      <option value={'2015'}>2015</option>
      <option value={'2014'}>2014</option>
      <option value={'2013'}>2013</option>
      <option value={'2012'}>2012</option>
      <option value={'2011'}>2011</option>
      <option value={'2010'}>2010</option>
      <option value={'2009'}>2009</option>
      <option value={'2008'}>2008</option>
      <option value={'2007'}>2007</option>
      <option value={'2006'}>2006</option>
      <option value={'2005'}>2005</option>
      <option value={'2004'}>2004</option>
      <option value={'2003'}>2003</option>
      <option value={'2002'}>2002</option>
      <option value={'2001'}>2001</option>
      <option value={'2000'}>2000</option>
      <option value={'1999'}>1999</option>
    </>
  );
}

export const MaxDropDownBacktestGraph: React.FC<Props> = ({ results, special }) => {
  // console.log(results);
  const [dailyAlgoResults, setDailyAlgoResults] = useState<DailyAlgoResults>(
    {}
  );
  const AlgoResults = results.rawResults.dailyBackTestResults;
  
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>({
    from: new Date(),
    to: new Date(),
  });
  const [timeline, setTimeline] = useState<string>('1Y');
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: 'MaxDrawdown',
        data: [],

        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: '#F82929',
        borderWidth: 2,
        tension: 0.4,
        fill: false,
      },
    ],
  });
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('2024');
  const [totalYears, setTotalYears] = useState(0);

  const handleChangeDateRange = (from: Date, to: Date) => {
    setDateRange({ from, to });
  };

  const handleChangeTimeline = (selectedTimeline: string) => {
    setTimeline(selectedTimeline);
  };

  function getDrawdownData(AlgoResults: any) {
    const drawDown: any = {};
    Object.keys(AlgoResults).forEach((date) => {
      drawDown[date] = AlgoResults[date].drawdown;
    });
    return drawDown;
  }

  const [maxDrawDownValue, setMaxDrawDownValue] = useState(0);

  useEffect(() => {
    const drawDown = getDrawdownData(AlgoResults);
    setDailyAlgoResults(drawDown);
    // console.log(drawDown);

    const sortedEntries = Object.entries(dailyAlgoResults).sort((a, b) => {
      return new Date(a[0]).getTime() - new Date(b[0]).getTime();
    });

    const sortedDailyAlgoResults: { [key: string]: number } = {};
    sortedEntries.forEach(([key, value]) => {
      sortedDailyAlgoResults[key] = value;
    });

    const dates = Object.keys(sortedDailyAlgoResults);
    const firstDate = dates[0];

    const fromDate = dateRange.from;
    const toDate = dateRange.to;
    const diffTime = Math.abs(
      new Date(firstDate).getTime() - fromDate.getTime()
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const labels: string[] = [];
    let data: number[] = [];
    const currentDate = new Date();

    function getMonthName(monthIndex: any) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return months[monthIndex];
    }

    if (timeline === '1M') {
      setStartYear('');
      // setEndYear('');
      const pastDate = new Date();
      pastDate.setMonth(currentDate.getMonth() - 1);

      const filteredDrawDown = Object.entries(drawDown).filter(([date]) => {
        const entryDate = new Date(date);
        return entryDate >= pastDate && entryDate <= currentDate;
      });

      filteredDrawDown.forEach(([date, drawdown]) => {
        const entryDate = new Date(date);
        const formattedDate = `${entryDate.getDate()} ${getMonthName(
          entryDate.getMonth()
        )}`;

        labels.push(formattedDate);
        data.push(Number(drawdown));

        // if (Number(drawdown) > maxDrawDownValue) {
        //   setMaxDrawDownValue(Number(drawdown));
        // }
        if (data.length > 0) {
          const minValue = Math.min(...data);
          setMaxDrawDownValue(minValue);
        }
      });
    } else if (timeline === '1Y') {
      setStartYear('');
      Object.entries(drawDown).forEach(([date, drawdown]) => {
        let monthNum = date.substring(5, 7);
        let monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        let yearNum = date.substring(2, 4);
        let month = monthNames[parseInt(monthNum) - 1]; // + " '" + yearNum;

        if (!labels.includes(month)) {
        }
        // labels.push(month);
        if (!labels.includes(month)) {
          labels.push(month);
        } else {
          labels.push('');
        }
        // if (Number(drawdown) > maxDrawDownValue) {
        //   setMaxDrawDownValue(Number(drawdown));
        // }
        data.push(Number(drawdown));
        if (data.length > 0) {
          const minValue = Math.min(...data);
          setMaxDrawDownValue(minValue);
        }
      });
    } else if (timeline === '3Y') {
      setStartYear('');
      const pastDate = new Date();
      pastDate.setFullYear(currentDate.getFullYear() - 3);

      const filteredDrawDown = Object.entries(drawDown).filter(([date]) => {
        const entryDate = new Date(date);
        return entryDate >= pastDate && entryDate <= currentDate;
      });
      filteredDrawDown.forEach(([date, drawdown]) => {
        const year = new Date(date).getFullYear().toString();
        if (!labels.includes(year)) {
          labels.push(year);
        } else {
          labels.push('');
        }
        // if (Number(drawdown) > maxDrawDownValue) {
        //   setMaxDrawDownValue(Number(drawdown));
        // }
        data.push(Number(drawdown));
        if (data.length > 0) {
          const minValue = Math.min(...data);
          setMaxDrawDownValue(minValue);
        }
      });
    } else if (timeline === '5Y') {
      setStartYear('');
      const pastDate = new Date();
      pastDate.setFullYear(currentDate.getFullYear() - 5);

      const filteredDrawDown = Object.entries(drawDown).filter(([date]) => {
        const entryDate = new Date(date);
        return entryDate >= pastDate && entryDate <= currentDate;
      });

      filteredDrawDown.forEach(([date, drawdown]) => {
        const year = new Date(date).getFullYear().toString();
        if (!labels.includes(year)) {
          labels.push(year);
        } else {
          labels.push('');
        }
        // if (Number(drawdown) > maxDrawDownValue) {
        //   setMaxDrawDownValue(Number(drawdown));
        // }
        data.push(Number(drawdown));
        if (data.length > 0) {
          const minValue = Math.min(...data);
          setMaxDrawDownValue(minValue);
        }
      });
    } else if (timeline === 'MAX') {
      setStartYear('');
      Object.entries(drawDown).forEach(([date, drawdown]) => {
        const year = new Date(date).getFullYear().toString();
        if (!labels.includes(year)) {
          labels.push(year);
        } else {
          labels.push('');
        }
        // if (Number(drawdown) > maxDrawDownValue) {
        //   setMaxDrawDownValue(Number(drawdown));
        // }
        data.push(Number(drawdown));
        if (data.length > 0) {
          const minValue = Math.min(...data);
          setMaxDrawDownValue(minValue);
        }
      });
    }

    if (startYear !== '' && endYear !== '') {
      if (startYear <= endYear) {
        setTimeline('');
        let filteredDrawDown = Object.entries(drawDown).filter(([date]) => {
          const year = new Date(date).getFullYear().toString();
          return year >= startYear && year <= endYear;
        });
        filteredDrawDown = filteredDrawDown.sort();
        filteredDrawDown.forEach(([date, drawdown]) => {
          const year = new Date(date).getFullYear().toString();
          if (Number(endYear) - Number(startYear) <= 1) {
            let monthNum = date.substring(5, 7);
            let monthNames = [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ];
            let yearNum = date.substring(2, 4);
            let month = monthNames[parseInt(monthNum) - 1] + " '" + yearNum;
            if (!labels.includes(month)) {
              labels.push(month);
            } else {
              labels.push('');
            }
          } else {
            if (!labels.includes(year)) {
              labels.push(year);
            } else {
              labels.push('');
            }
          }

          data.push(Number(drawdown));
        });
      }
    }

    setChartData({
      labels: labels,
      datasets: [
        {
          ...chartData.datasets[0],
          data: data,
        },
      ],
    });
  }, [AlgoResults, timeline, dateRange, maxDrawDownValue, startYear, endYear]);
  // console.log(maxDrawDownValue);

  useEffect(() => {
    const tooltipCallback = (tooltipItems: any) => {
      return tooltipItems.yLabel;
    };
    const chartOptions = {
      plugins: {
        tooltip: {
          // callbacks: {
          //   label: tooltipCallback,
          // },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          border: {
            color: '#000000B2',
            width: 0.88,
          },
          beginAtZero: false,
          ticks: {
            font: {
              size: 17,
            },
          },
        },
        x: {
          position: 'top' as const,
          border: {
            color: '#000000B2',
            width: 0.88,
          },
          grid: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            font: {
              size: 17,
            },
          },
          // position: "-20",
        },
      },
      elements: {
        point: {
          radius: 0.5,
          backgroundColor: `white`,
        },
      },
    };
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: chartOptions,
    });

    return () => {
      myChart.destroy();
    };
  }, [chartData]);

  const windowSize = useWindowSize();

  return (
    <div style={{ padding: '1rem 24px 25px 25px' }}>
      <StrategyPerformance name={'Max Drawdown'} />
      <div style={windowSize <= 500 ? styles.dateFilterContainerResponsive : styles.dateFilterContainer}>
        {/* <div style={styles.dateRange}>
          <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              From
            </label>
            <select
              style={styles.input}
              className='select-label dropdown'
              value={startYear}
              onChange={(e) =>
                // handleChangeDateRange(e.target.value, dateRange.to)
                setStartYear(e.target.value)
              }
            >
              <option style={{ border: 'solid' }} value={''}>
                YYYY
              </option>
              {years()}
            </select>
            <div style={{ position: 'absolute', right: '18%', bottom: '12%' }}>
              <DateRangeIcon />
            </div>
          </div>
          <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              To
            </label>
            <select
              style={styles.input}
              className='select-label select-label dropdown'
              value={endYear}
              onChange={(e) =>
                // handleChangeDateRange(e.target.value, dateRange.to)
                setEndYear(e.target.value)
              }
            >
              <option value={''}>YYYY</option>
              {years()}
            </select>
            <div
              style={{
                position: 'absolute',
                right: '18%',
                bottom: '12%',
                fontSize: '16px',
              }}
            >
              <DateRangeIcon />
            </div>
          </div>
        </div> */}
        <div
          style={{
            border: '0.94px solid #0000001A',
            maxWidth: '182px',
            borderRadius: '5px',
            display: 'flex',
          }}
        >
          <button
            style={
              special
                ? {
                    backgroundColor:
                      timeline === '1M' ? '#FFE484' : 'rgb(243, 246, 255)',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
                : {
                    backgroundColor: timeline === '1M' ? '#FFE484' : '#FFFFFF',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
            }
            onClick={() => handleChangeTimeline('1M')}
          >
            1M
          </button>
          <button
            style={
              special
                ? {
                    backgroundColor:
                      timeline === '1Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
                : {
                    backgroundColor: timeline === '1Y' ? '#FFE484' : '#FFFFFF',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
            }
            onClick={() => handleChangeTimeline('1Y')}
          >
            1Y
          </button>
          <button
            style={
              special
                ? {
                    backgroundColor:
                      timeline === '3Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
                : {
                    backgroundColor: timeline === '3Y' ? '#FFE484' : '#FFFFFF',
                    color: 'black',
                    margin: '0px',
                    padding: '8px',
                  }
            }
            onClick={() => handleChangeTimeline('3Y')}
          >
            3Y
          </button>
          <button
            style={
              special
                ? {
                    backgroundColor:
                      timeline === '5Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                    color: 'black',
                    padding: '8px',
                    margin: '0px',
                  }
                : {
                    backgroundColor: timeline === '5Y' ? '#FFE484' : '#FFFFFF',
                    color: 'black',
                    padding: '8px',
                    margin: '0px',
                  }
            }
            onClick={() => handleChangeTimeline('5Y')}
          >
            5Y
          </button>
          <button
            style={
              special
                ? {
                    backgroundColor:
                      timeline === 'MAX' ? '#FFE484' : 'rgb(243, 246, 255)',
                    color: 'black',
                    padding: '8px',
                    margin: '0',
                  }
                : {
                    backgroundColor: timeline === 'MAX' ? '#FFE484' : '#FFFFFF',
                    color: 'black',
                    padding: '8px',
                    margin: '0',
                  }
            }
            onClick={() => handleChangeTimeline('MAX')}
          >
            Max
          </button>
        </div>
      </div>

      <div
        style={windowSize <= 500 ? { width: `${windowSize-80}px`, position: "relative", right: "20px", height: 'auto', padding: '20px 0px 0px 0px' } :{ padding: '20px 70px 0 70px', width: '881px', height: 'auto' }}
      >
        <canvas id='myChart'></canvas>
        <p
          style={windowSize <= 500 ? {
            marginTop: '35px',
            padding: '0px',
            marginBottom: '0px',
            marginLeft: '0px',
          }: {
            marginTop: '35px',
            padding: '0px',
            marginBottom: '25px',
            marginLeft: '35px',
          }}
        >
          Max DrawDown &nbsp; &nbsp;{' '}
          <span
            style={{
              backgroundColor: '#F3F6FF',
              padding: '5px',
              color: maxDrawDownValue > 0 ? '#8FE184' : '#F82929CC',
              fontWeight: '700',
            }}
          >
            {maxDrawDownValue.toFixed(1)}%
          </span>
        </p>
      </div>
    </div>
  );
};
const styles = {
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    margin: 1,
    padding: '0px 0px 22px 0px',
    width: 'fit-content',
    fontWeight: Theme.fontWeight.semiBold,
    borderBottom: '2px solid ' + Theme.colors.whiteGrey70,
    marginTop: '20px',
  },
  metricsHeaderResponsive: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: "0.63px solid " + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: "9.27px",
    marginBottom: "27px"
  },
  dateFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  dateFilterContainerResponsive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '270px',
    margin: '20px 0 20px 0',
    gap: '20px',
  },
  fromDate: {
    display: 'flex',
    flexDirection: 'column' as const,
    // maxWidth: '120px' as const,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    margin: '0',
    border: 'solid',
    borderRadius: '5px',
    fontSize: '16px',
  },

  label: {
    position: 'absolute' as const,
    top: '-30%',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    left: '30%',
    transform: 'translateX(-50%)',
    width: 'auto',
    zIndex: '999',
  },
  input: {
    display: 'flex',
    width: '139px',
    textDecoration: 'none',
    border: 'solid 0.5px #B2B2B2B2',
    height: '48px',
    backgroundColor: 'transparent',
    webkitAppearance: 'none',
    fontSize: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '30px',
  },
};
