import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { Theme } from '../../../Utils/Constants';
import { formatNumber } from '../../../API/FormatNumber';
import 'chartjs-adapter-moment';
import './pllinegraph.css';
import { StrategyPerformance } from '../MaxDrawdownGraph/MaxDrawDownGraph';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useWindowSize from '../../Hooks/useWindowSize';
interface PerformanceData {
  [date: string]: {
    nifty50: number;
    master_strategy: number;
    account_margin: number;
    pnl: number;
    total_pnl: number;
  };
}
interface Props {
  unsortedData: PerformanceData;
  special?: boolean;
  height?: number;
  strategyName?: string;
}

const LineGraph: React.FC<Props> = ({ unsortedData, height, special, strategyName }) => {

  const sortedKeys = Object.keys(unsortedData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const data = sortedKeys.reduce((acc, key) => {
    acc[key] = unsortedData[key];
    return acc;
  }, {} as { [key: string]: any });

  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart>();
  const [totalPnl, setTotalPnl] = useState(666);
  const [totalPnlValue, setTotalPnlValue] = useState(666);
  const [totalPercentage, setTotalPercentage] = useState(987);

  const [timeline, setTimeline] = useState<string>('1Y');
  const handleChangeTimeline = (selectedTimeline: string) => {
    setTimeline(selectedTimeline);
  };

  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('2024');

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.toLocaleDateString('en-us', { month: 'short' });
    const year = date.getFullYear().toString().substr(-2);
    return month; // + "'" + year;
  };

  useEffect(() => {
    const tooltipCallback = (tooltipItems: any) => {
      return tooltipItems.yLabel;
    };
    const chartOptions = {
      plugins: {
        tooltip: {
          callbacks: {
            label: tooltipCallback,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
      },
      scales: {
        y: {
          border: {
            color: '#000000B2',
            width: 0.88,
          },
          beginAtZero: false,
          ticks: {
            font: {
              size: 14,
            },
          },
        },
        x: {
          border: {
            color: '#000000B2',
            width: 0.88,
          },
          grid: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            font: {
              size: 14,
            },
          },
        },
      },
      elements: {
        point: {
          radius: 1,
          backgroundColor: `white`,
        },
      },
    };

    function getMonthName(monthIndex: any) {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return months[monthIndex];
    }

    if (chartRef.current && data) {
      let dates = Object.keys(data);
      let nifty50Values = dates.map((date) => data[date].nifty50);
      let masterStrategyValues = dates.map(
        (date) => data[date].master_strategy
      );
      let pnlValues = dates.map((date) => data[date].pnl);
      let totalPnlValues = dates.map((date) => data[date].total_pnl);
      let accountMarginValues = dates.map((date) => data[date].account_margin);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() +1;
      const latestMonthData: PerformanceData = {};
      const last1YearData: PerformanceData = {};
      const last36MonthsData: PerformanceData = {};
      const last5YearsData: PerformanceData = {};
      const yearRangeData: PerformanceData = {};


      if (timeline === '1M') {
        for (const dateKey in data) {
          const [year, month] = dateKey.split('-').map(Number);
          if (year === currentYear && month === currentMonth) {
            latestMonthData[dateKey] = data[dateKey];
          }
        }

        dates = Object.keys(latestMonthData);

        dates = dates
          .map((dateKey) => {
            const [year, month, day] = dateKey.split('-').map(Number);
            const formattedDate = `${day} ${getMonthName(month - 1)}`;
            return { formattedDate, originalDate: new Date(year, month - 1, day) };
          })
          .sort((a: any, b: any) => a.originalDate - b.originalDate)
          .map(({ formattedDate }) => formattedDate);

        masterStrategyValues = Object.values(latestMonthData).map(
          (value) => value.master_strategy
        );
        nifty50Values = Object.values(latestMonthData).map(
          (value) => value.nifty50
        );

        pnlValues = Object.values(latestMonthData).map((value) => value.pnl);
        totalPnlValues = Object.values(latestMonthData).map(
          (value) => value.total_pnl
        );
        accountMarginValues = Object.values(latestMonthData).map(
          (value) => value.account_margin
        );
      } else if (timeline === '1Y') {
        setStartYear('');
        // setEndYear('');
        const date1YearAgo = new Date(currentDate);
        date1YearAgo.setFullYear(date1YearAgo.getFullYear() - 1);
        const year1YearAgo = date1YearAgo.getFullYear();
        const month1YearAgo = date1YearAgo.getMonth() + 1;
        for (const dateKey in data) {
          const [year, month] = dateKey.split('-').map(Number);
          if (
            (year > year1YearAgo ||
              (year === year1YearAgo && month >= month1YearAgo)) &&
            (year < currentYear ||
              (year === currentYear && month <= currentMonth))
            ) {
            last1YearData[dateKey] = data[dateKey];
          }
        }
        let val = Object.keys(last1YearData);
        masterStrategyValues = dates.map((date) => data[date].master_strategy);

        nifty50Values = dates.map((date) => data[date].nifty50);
        pnlValues = dates.map((date) => data[date].pnl);
        totalPnlValues = dates.map((date) => data[date].total_pnl);
        accountMarginValues = dates.map((date) => data[date].account_margin);
        for (let i = 0; i < val.length; i++) {
          val[i] = formatDate(val[i]);
        }
        dates = [];
        val.map((dum) => {
          if (!dates.includes(dum)) {
            dates.push(dum);
          } else {
            dates.push('');
          }
        });
      } else if (timeline === '3Y') {
        setStartYear('');
        // setEndYear('');
        const date36MonthsAgo = new Date(currentDate);
        date36MonthsAgo.setMonth(date36MonthsAgo.getMonth() - 36);
        const year36MonthsAgo = date36MonthsAgo.getFullYear();
        const month36MonthsAgo = date36MonthsAgo.getMonth() + 1;
        for (const dateKey in data) {
          const [year, month] = dateKey.split('-').map(Number);
          if (
            (year > year36MonthsAgo ||
              (year === year36MonthsAgo && month >= month36MonthsAgo)) &&
            (year < currentYear ||
              (year === currentYear && month <= currentMonth))
          ) {
            last36MonthsData[dateKey] = data[dateKey];
          }
        }
        masterStrategyValues = dates.map((date) => data[date].master_strategy);
        nifty50Values = dates.map((date) => data[date].nifty50);
        pnlValues = dates.map((date) => data[date].pnl);
        totalPnlValues = dates.map((date) => data[date].total_pnl);
        accountMarginValues = dates.map((date) => data[date].account_margin);
        const extractYear = (dateString: string): string => {
          return dateString.split('-')[0];
        };

        const years: string[] = dates.map(extractYear);
        dates = [];

        const uniqueYears: string[] = Array.from(new Set(years));
        const recentYears: string[] = years.slice(-3);
        if (uniqueYears.length >= 3) {
          recentYears.map((test) => {
            if (!dates.includes(test)) {
              dates.push(test);
            } else {
              dates.push('');
            }
          });
        } else {
          years.map((test) => {
            if (!dates.includes(test)) {
              dates.push(test);
            } else {
              dates.push('');
            }
          });
        }
      } else if (timeline === '5Y') {
        // setStartYear('');
        const year5YearsAgo = currentYear - 5;
        for (const dateKey in data) {
          const [year] = dateKey.split('-').map(Number);
          if (year >= year5YearsAgo && year <= currentYear) {
            last5YearsData[dateKey] = data[dateKey];
          }
        }
        // dates.map((date) => data[date].nifty50);
        masterStrategyValues = dates.map((date) => data[date].master_strategy);
        nifty50Values = dates.map((date) => data[date].nifty50);
        pnlValues = dates.map((date) => data[date].pnl);
        totalPnlValues = dates.map((date) => data[date].total_pnl);
        accountMarginValues = dates.map((date) => data[date].account_margin);
        const extractYear = (dateString: string): string => {
          return dateString.split('-')[0];
        };

        const years: string[] = dates.map(extractYear);
        dates = [];

        const uniqueYears: string[] = Array.from(new Set(years));
        const recentYears: string[] = years.slice(-5);
        if (uniqueYears.length >= 5) {
          recentYears.map((test) => {
            if (!dates.includes(test)) {
              dates.push(test);
            } else {
              dates.push('');
            }
          });
        } else {
          years.map((test) => {
            if (!dates.includes(test)) {
              dates.push(test);
            } else {
              dates.push('');
            }
          });
        }
      } else {
        const extractYear = (dateString: string): string => {
          return dateString.split('-')[0];
        };
        masterStrategyValues = dates.map((date) => data[date].master_strategy);
        nifty50Values = dates.map((date) => data[date].nifty50);
        pnlValues = dates.map((date) => data[date].pnl);
        totalPnlValues = dates.map((date) => data[date].total_pnl);
        accountMarginValues = dates.map((date) => data[date].account_margin);
        const years: string[] = dates.map(extractYear);
        dates = [];
        years.map((test) => {
          if (!dates.includes(test)) {
            dates.push(test);
          } else {
            dates.push('');
          }
        });
      }

      if (startYear !== '' && endYear !== '') {
        if (startYear <= endYear) {
          setTimeline('');
          for (const dateKey in data) {
            const [year] = dateKey.split('-').map(Number);
            if (year >= Number(startYear) && year <= Number(endYear)) {
              yearRangeData[dateKey] = data[dateKey];
            }
          }
          dates = [];
          dates = Object.keys(yearRangeData).sort();

          dates.map((date) => data[date].nifty50);
          masterStrategyValues = dates.map(
            (date) => data[date].master_strategy
          );
          if (Number(endYear) - Number(startYear) <= 1) {
            let val = Object.keys(yearRangeData).sort();
            for (let i = 0; i < val.length; i++) {
              val[i] = formatDate(val[i]);
            }
            dates = [];
            val.map((dum) => {
              if (!dates.includes(dum)) {
                dates.push(dum);
              } else {
                dates.push('');
              }
            });
          } else {
            const extractYear = (dateString: string): string => {
              return dateString.split('-')[0];
            };

            const years: string[] = dates.map(extractYear);
            dates = [];
            years.map((test) => {
              if (!dates.includes(test)) {
                dates.push(test);
              } else {
                dates.push('');
              }
            });
          }
        }
      }

      // masterStrategyValues[0] = (pnlValues[0] / accountMarginValues[0]) * 100 + 100;
      // for (let index = 1; index < masterStrategyValues.length; index++) {
      //   let currentPnlPercentage =
      //     (pnlValues[index] / accountMarginValues[index]) * 100;
      //     // console.log("currentPnlPercentage", currentPnlPercentage, pnlValues[index], accountMarginValues[index]);

      //   masterStrategyValues[index] =
      //     currentPnlPercentage + masterStrategyValues[index - 1];
      // }

      let totalPnlSum = 0;
      let percentageVal = 0;
      let totalFinalPnlSum = 0;
      for (let i = 0; i < pnlValues.length; i++) {
        totalPnlSum += pnlValues[i];
        percentageVal += (pnlValues[i] / accountMarginValues[i]) * 100;
      }

      setTotalPnl(totalPnlSum);
      setTotalPercentage(Number(percentageVal?.toFixed(1)));

      for (let i = 0; i < totalPnlValues.length; i++) {
        totalFinalPnlSum += totalPnlValues[i];
      }

      setTotalPnlValue(totalFinalPnlSum);

      masterStrategyValues[0] = 100;
      for (let index = 1; index < masterStrategyValues.length; index++) {
        let currentPnlPercentage =
          (pnlValues[index - 1] / accountMarginValues[index - 1]) * 100;
        masterStrategyValues[index] =
          currentPnlPercentage + masterStrategyValues[index - 1];
      }

      // let firstDayNifty50 = nifty50Values[0];
      // nifty50Values[0] = 100;
      // for (let i = 1; i < nifty50Values.length; i++) {
      //   // let currentDayNiftyPercentage =
      //   //   ((nifty50Values[i] - firstDayNifty50) / firstDayNifty50) * 100;
      //    let currentDayNiftyPercentage = 100 * (nifty50Values[i])/ firstDayNifty50
      //     console.log("nifty 50 values ",nifty50Values[i], firstDayNifty50, currentDayNiftyPercentage);
      //   nifty50Values[i] = currentDayNiftyPercentage + nifty50Values[i - 1];
      // }
      let firstDayNifty50 = nifty50Values[0];
      nifty50Values[0] = 100;
      for (let i = 1; i < nifty50Values.length; i++) {
        let currentDayNiftyPercentage =
          (nifty50Values[i] / firstDayNifty50) * 100;
        nifty50Values[i] = currentDayNiftyPercentage;
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: dates,
            datasets: [
              {
                label: 'Nifty50',
                data: nifty50Values,
                borderColor: 'blue',
                fill: false,
              },
              {
                label: strategyName ? strategyName :'Master Strategy',
                data: masterStrategyValues,
                borderColor: 'red',
                fill: false,
              },
            ],
          },
          options: chartOptions,
        });
      }
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [data, timeline, totalPnl, totalPercentage, startYear, endYear]);

  const windowSize = useWindowSize();

  return (
    <div style={styles.pllineContainer} className='pl-line-container'>
      <StrategyPerformance name={'Relative Performance'} />
      <div
        style={
          windowSize <= 500
            ? styles.dateFilterContainerResponsive
            : styles.dateFilterContainer
        }
      >
        {/* <div style={styles.dateRange}>
          <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              From
            </label>
            <select
              style={styles.input}
              className='select-label dropdown'
              value={startYear}
              onChange={(e) =>
                // handleChangeDateRange(e.target.value, dateRange.to)
                setStartYear(e.target.value)
              }
            >
              <option style={{ border: 'solid' }} value={''}>
                YYYY
              </option>
              {years()}
            </select>
            <div style={{ position: 'absolute', right: '18%', bottom: '12%' }}>
              <DateRangeIcon />
            </div>
          </div>
          <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              To
            </label>
            <select
              style={styles.input}
              className='select-label select-label dropdown'
              value={endYear}
              onChange={(e) =>
                // handleChangeDateRange(e.target.value, dateRange.to)
                setEndYear(e.target.value)
              }
            >
              <option value={''}>YYYY</option>
              {years()}
            </select>
            <div
              style={{
                position: 'absolute',
                right: '18%',
                bottom: '12%',
                fontSize: '16px',
              }}
            >
              <DateRangeIcon />
            </div>
          </div>
        </div> */}
        <div
          style={{
            border: '0.94px solid #0000001A',
            width: '182px',
            borderRadius: '5px',
            display: 'flex',
          }}
        >
          <button
            style={
              special
                ? {
                  backgroundColor:
                    timeline === '1M' ? '#FFE484' : 'rgb(243, 246, 255)',
                  color: 'black',
                  fontSize: '14px',
                  margin: '0px',
                  padding: '8px',
                  left: '0',
                }
                : {
                  backgroundColor: timeline === '1M' ? '#FFE484' : '#FFFFFF',
                  color: 'black',
                  fontSize: '14px',
                  margin: '0px',
                  padding: '8px',
                  left: '0',
                }
            }
            onClick={() => handleChangeTimeline('1M')}
          >
            1M
          </button>
          <button
            style={
              special
                ? {
                  backgroundColor:
                    timeline === '1Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                  color: 'black',
                  margin: '0px',
                  padding: '8px',
                }
                : {
                  backgroundColor: timeline === '1Y' ? '#FFE484' : '#FFFFFF',
                  color: 'black',
                  margin: '0px',
                  padding: '8px',
                }
            }
            onClick={() => handleChangeTimeline('1Y')}
          >
            1Y
          </button>
          <button
            style={
              special
                ? {
                  backgroundColor:
                    timeline === '3Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                  color: 'black',
                  margin: '0px',
                  padding: '8px',
                }
                : {
                  backgroundColor: timeline === '3Y' ? '#FFE484' : '#FFFFFF',
                  color: 'black',
                  margin: '0px',
                  padding: '8px',
                }
            }
            onClick={() => handleChangeTimeline('3Y')}
          >
            3Y
          </button>
          <button
            style={
              special
                ? {
                  backgroundColor:
                    timeline === '5Y' ? '#FFE484' : 'rgb(243, 246, 255)',
                  color: 'black',
                  padding: '8px',
                  margin: '0px',
                }
                : {
                  backgroundColor: timeline === '5Y' ? '#FFE484' : '#FFFFFF',
                  color: 'black',
                  padding: '8px',
                  margin: '0px',
                }
            }
            onClick={() => handleChangeTimeline('5Y')}
          >
            5Y
          </button>
          <button
            style={
              special
                ? {
                  backgroundColor:
                    timeline === 'MAX' ? '#FFE484' : 'rgb(243, 246, 255)',
                  color: 'black',
                  padding: '8px',
                  margin: '0px',
                }
                : {
                  backgroundColor: timeline === 'MAX' ? '#FFE484' : '#FFFFFF',
                  color: 'black',
                  padding: '8px',
                  margin: '0px',
                }
            }
            onClick={() => handleChangeTimeline('MAX')}
          >
            Max
          </button>
        </div>
      </div>
      <div style={windowSize <= 500 ? { width: `${windowSize - 80}px`, position: "relative", right: "20px", height: 'auto', padding: '20px 0px 20px 0px' } : { width: '917px', height: 'auto', padding: '0 70px 0 70px' }}>
        <canvas ref={chartRef} />
      </div>
      <div style={styles.totalPNL} className='total-pnl'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '18px',
          }}
        >
          Total P&L
          <span style={styles.infoSpan}>
            {/* <span style={{ color: totalPnLColor }}></span> */}
            <span style={{ color: totalPnl >= 0 ? '#8FE184' : '#F82929CC' }}>
              {/* {formatNumber('50000')} */}
              {/* {formatNumber(String(totalPnl))} */}
              {formatNumber(String(totalPnlValue))}
            </span>
          </span>{' '}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          Total Percentage{' '}
          <span style={styles.infoSpan}>
            {' '}
            {/* <span style={{ color: totalPnLColor }}>{totalPnLPercentage}%</span> */}
            <span
              style={{ color: totalPercentage >= 0 ? '#8FE184' : '#F82929CC' }}
            >
              {totalPercentage}%
            </span>
          </span>{' '}
        </div>
      </div>
    </div>
  );
};

const styles = {
  pllineContainer: {
    padding: '30px',
    paddingTop: '1rem',
    paddingRight: '46px',
  },
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    margin: 1,
    width: 'fit-content',
    fontWeight: Theme.fontWeight.semiBold,
    borderBottom: '2px solid ' + Theme.colors.whiteGrey70,
    paddingBottom: 17,
    marginTop: '20px',
  },
  dateFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  dateFilterContainerResponsive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '270px',
    margin: '20px 0 20px 0',
    gap: '20px',
    // border: 'solid',
  },
  fromDate: {
    display: 'flex',
    flexDirection: 'column' as const,
    // maxWidth: '120px' as const,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    margin: '0',
    border: 'solid',
    borderRadius: '5px',
    fontSize: '16px',
  },
  label: {
    position: 'absolute' as const,
    top: '-30%',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    left: '30%',
    transform: 'translateX(-50%)',
    width: 'auto',
    zIndex: '999',
  },
  input: {
    display: 'flex',
    width: '139px',
    textDecoration: 'none',
    border: 'solid 0.5px #B2B2B2B2',
    height: '48px',
    backgroundColor: 'transparent',
    webkitAppearance: 'none',
    fontSize: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '30px',
  },
  totalPNL: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '35px',
    marginLeft: '10%',
    marginBottom: '19px',
    marginTop: '15px',
  },
  infoSpan: {
    backgroundColor: '#F3F6FF',
    padding: '5px',
    fontWeight: '700',
    borderRadius: '6px',
  },
};

export default LineGraph;
