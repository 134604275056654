import fetchSubscriptionData from './SubscriptionData';
import './Subscription.css';
import Header from '../Header/Header';
import SubscriptionTable from './SubscriptionTable';
import { useEffect, useState } from 'react';
import Loader from '../Dialogs/Loader';
import { FetchUserDetails } from '../../API/ProfileAPI';
import useWindowSize from '../Hooks/useWindowSize';

interface SubscriptionData {
  planName: string;
  startDate: string;
  endDate: string;
  invoiceAmount: string;
}

function Subscription() {
  const windowSize = useWindowSize();
  
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData[]>([
    {
      planName: 'Standard',
      startDate: '03 Nov 2019',
      endDate: '03 Nov 2025',
      invoiceAmount: '₹35,000',
    },
  ]);

  const [userData, setUserData] = useState<{
    email: string;
    phone_number: string;
    name: string;
    is_active: boolean;
    joined_date: string;
  } | null>(null);

  function formatDate(dateString: any): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await FetchUserDetails('9949465818', 'Titan#12');
        // console.log(userData);

        setUserData(userData);
       // setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData = async () => {
      const susbData = await fetchSubscriptionData();
      // filter the data based on their plan name if null or undefined not to push in the array
      const filteredData = susbData['Response data'].filter((item: { plan_name: null; }) => item.plan_name !== null && item.plan_name !== undefined);
      // Sort subscriptionData by startDate in descending order (most recent first)
      const sortedSubscriptionData = filteredData.sort((a: { startDate: string | number | Date; }, b: { startDate: string | number | Date; }) =>
      new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );
      setSubscriptionData(sortedSubscriptionData);
         
     // setSubscriptionData(susbData['Response data']);
     setTimeout(() => {
       setIsLoading(false);
        }, 1500);
    };

    fetchData();
    fetchUserData();
  }, []);


  if (isLoading) {
    return <Loader />;
  }


  return (
    <>
      <div className='subscription-section-child2 special-width-background'>
        <div
          style={{ paddingLeft: '15px' }}
          className='subscription-table-main'
        >
          Member Since {formatDate(userData?.joined_date)}
        </div>
        <div className="subscription-table-container" style={windowSize <= 800 ? {width: `${windowSize - 32}px`} : {}}>
        <table>
          <thead>
            <tr className='subscription-table-heading'>
              <th
                style={{ paddingLeft: '15px' }}
                className='subscription-header-row-data'
              >
                Plan Name
              </th>
              <th className='subscription-header-row-data'>Start Date</th>
              <th className='subscription-header-row-data'>End Date</th>
              <th className='subscription-header-row-data'>Invoice Amount</th>
              <th className='subscription-header-row-data'></th>
            </tr>
          </thead>
          <tbody>
            {subscriptionData?.map((data, index) => (
              <SubscriptionTable key={index} data={data} />
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </>
  );
}

export default Subscription;
