import React, { useEffect, useRef } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Theme } from '../../Utils/Constants';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import profilePerson from '../../assets/images/profile-person.png';
import liveStrategiesIcon from '../../assets/images/live-strategies.png';
import borderedRect from '../../assets/images/bordered-rect.svg';

import {
  ArticleOutlined,
  DescriptionOutlined,
  SubscriptionsOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useAppStore from '../../store/app.store';
import useOutside from '../../hooks/useOutSide';
import useWindowSize from '../Hooks/useWindowSize';

interface HeaderDropDownProps {
  onSignOut: () => void;
  setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderDropDown: React.FC<HeaderDropDownProps> = ({
  onSignOut,
  setDropdownVisible,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const windowSize = useWindowSize();
  const {
    profileName,
    userLevel,
    starRating,
    userBacktestData,
    monthlyPnlValue,
    totalPnlValue,
  } = useAppStore();
  const { profileImage } = useAppStore();

  const { setSelectedSectionIndex } = useAppStore();

  function handleSignOut() {
    onSignOut();
    localStorage.clear();
  }

  useOutside(containerRef, () => {
    setDropdownVisible(false);
  });

  function StartIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='13'
        fill='none'
        viewBox='0 0 14 13'
      >
        <path
          fill='#2747DD'
          d='M7 0l1.572 4.837h5.085l-4.114 2.99 1.572 4.836L7 9.673l-4.114 2.99 1.571-4.837-4.114-2.99h5.085L7 0z'
        ></path>
      </svg>
    );
  }

  function GraphIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        fill='none'
        viewBox='0 0 18 18'
      >
        <path
          fill='#fff'
          d='M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM6 14H4V7h2v7zm4 0H8V4h2v10zm4 0h-2v-4h2v4z'
        ></path>
      </svg>
    );
  }

  function TooltipIcon(props: any) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='39'
        height='30'
        fill='none'
        viewBox='0 0 39 30'
        {...props}
      >
        <g>
          <path
            fill='#F3F6FF'
            d='M10.102 19.505s.002 0 .002-.002l5.978-8.233a3 3 0 014.583-.324l8.158 8.427a.452.452 0 01.127.314l-18.85-.182h.002z'
          ></path>
        </g>
      </svg>
    );
  }

  return (
    <div
      style={
        windowSize > 500 ? styles.dropdownStyleDesktop : styles.dropdownStyle
      }
      ref={containerRef}
      className='border-box dropdownStyle'
    >
      {windowSize <= 500 && (
        <>
          <div style={styles.dropDownProfileContainer}>
            <img
              src={profileImage ? profileImage : profilePerson}
              style={styles.dropDownProfileFirst}
              alt='profile-pic'
            />
            <div style={styles.dropDownProfileSecond}>
              <div style={styles.dropDownProfileName}> {profileName}</div>
              <div style={styles.dropDownProfileStatus}>
                <div style={styles.dropDownProfileStatusText}>{userLevel}</div>
                <div style={styles.dropDownProfileStart}>
                  <StartIcon /> {starRating}
                </div>
              </div>
            </div>
          </div>
          <div style={styles.yourStatsHeading}>
            <GraphIcon />
            Your Stats
          </div>
          <div style={{ ...styles.statsRectangle, marginTop: '24px' }}>
            Total Backtests
            <div style={styles.statsRectContainer}>
              <div style={styles.statsRectFirstPart}></div>
              <div style={{ ...styles.statsRectangleInner, width: '31.37px' }}>
                {userBacktestData}
              </div>
            </div>
          </div>
          <div style={styles.liveStratHeading}>
            <img src={liveStrategiesIcon} alt='live-strategies' />
            Live Strategies
          </div>
          <div style={{ ...styles.statsRectangle, marginTop: '14px' }}>
            Total P/L
            <div style={styles.statsRectContainer}>
              <div style={styles.statsRectFirstPart}></div>
              <div style={{ ...styles.statsRectangleInner2, width: '73.29px' }}>
                {totalPnlValue}
              </div>
            </div>
          </div>
          <div style={{ ...styles.statsRectangle, marginTop: '14px' }}>
            Monthly P&L
            <div style={styles.statsRectContainer}>
              <div style={styles.statsRectFirstPart}></div>
              <div style={{ ...styles.statsRectangleInner2, width: '92.63px' }}>
                {monthlyPnlValue}
              </div>
            </div>
          </div>
          {/* <button style={styles.viewButton}>View Leaderboard</button> */}
          <button
            className='btn'
            style={styles.viewButton}
            onClick={() => {
              setSelectedSectionIndex(4);
              navigate('/profile/leaderboard', { replace: false });
            }}
          >
            View Leaderboard
          </button>
          <div style={styles.seperator} />
        </>
      )}
      {windowSize > 500 && (
        <TooltipIcon
          style={{ position: 'absolute', right: '0px', top: '-18px' }}
        />
      )}
      <div
        style={
          windowSize <= 500
            ? styles.dropDownItemContainer
            : styles.dropDownItemContainerDesktop
        }
      >
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            setSelectedSectionIndex(0);
            navigate('/profile', { replace: true });
          }}
        >
          <PersonOutlineIcon
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Profile</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            navigate('/subscription', { replace: false });
          }}
        >
          <SubscriptionsOutlined
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>My Subscription</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            setSelectedSectionIndex(2);
            navigate('/broker', { replace: false });
          }}
        >
          <DescriptionOutlined
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Broker</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            setSelectedSectionIndex(3);
            navigate('/pricing', { replace: false });
          }}
        >
          <OfflineBoltOutlinedIcon
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Plan & Pricing</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            setSelectedSectionIndex(4);
            navigate('/profile/leaderboard', { replace: false });
          }}
        >
          <LeaderboardIcon
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Leaderboard</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={() => {
            setSelectedSectionIndex(5);
            navigate('/support', { replace: false });
          }}
        >
          <ArticleOutlined
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Help & Support</span>
        </div>
        <div
          style={
            windowSize <= 500 ? styles.dropDownItem : styles.dropDownItemDesktop
          }
          onClick={handleSignOut}
        >
          <PowerSettingsNewIcon
            style={windowSize <= 500 ? styles.icon : styles.iconResponsive}
          />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  icon: {
    marginRight: Theme.gapSmall,
  },
  iconResponsive: {
    marginRight: '10px',
    width: '18px',
  },
  dropDownItemContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center' as 'center',
    alignItems: 'flex-start' as 'flex-start',
    gap: '18.7px',
    marginTop: '14px',
  },
  dropDownItemContainerDesktop: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center' as 'center',
    alignItems: 'flex-start' as 'flex-start',
    gap: '13px',
    marginTop: '0px',
  },
  viewButton: {
    background: '#FFE484',
    borderRadius: '3px',
    padding: '9px 14px 8px 13px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '20px',
    marginTop: '18px',
  },
  seperator: {
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    position: 'absolute' as 'absolute',
    left: '0',
  },
  dropDownItem: {
    display: 'flex',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.03em',
    color: '#FFFFFF',
  },
  dropDownItemDesktop: {
    display: 'flex',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    color: '#000000',
  },
  dropdownStyleDesktop: {
    position: 'absolute' as 'absolute',
    zIndex: 99999,
    top: '60px',
    right: '12px',
    background: '#FFFFFF',
    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',
    borderRadius: '3px',
    padding: '20px 7px 17px 12px',
    height: 'auto',
    width: '170px',
  },
  dropdownStyle: {
    position: 'absolute' as 'absolute',
    zIndex: 99999,
    top: '65px',
    right: '0px',
    backgroundColor: '#2747DD',
    color: '#000',
    padding: '27px 16px 50px 20px',
    borderRadius: '3px',
    // height: "calc(100vh - 65px)",
    height: 'auto',
    width: '290px',
    minHeight: "calc(100vh - 65px)"
    // overflow: "scroll"
  },
  dropDownProfileContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start' as 'flex-start',
    alignItems: 'center' as 'center',
    gap: '17px',
  },
  dropDownProfileFirst: {
    width: '77px',
    height: '73px',
    filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25))',
    borderRadius: '3px',
  },
  dropDownProfileSecond: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center' as 'center',
    alignItems: 'flex-start' as 'flex-start',
    gap: '11px',
  },
  dropDownProfileName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    color: '#FFFFFF',
  },
  dropDownProfileStatus: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start' as 'flex-start',
    alignItems: 'center' as 'center',
    gap: '6px',
    background: '#FFE484',
    borderRadius: '5px',
    padding: '5px 11px 3px 8px',
  },
  dropDownProfileStatusText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
    paddingRight: '8px',
    borderRight: '0.5px solid rgba(0, 0, 0, 0.7)',
  },
  yourStatsHeading: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start' as 'flex-start',
    alignItems: 'center' as 'center',
    gap: '17px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.06em',
    color: '#FFFFFF',
    marginTop: '31px',
  },
  liveStratHeading: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start' as 'flex-start',
    alignItems: 'center' as 'center',
    gap: '8.6px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.06em',
    color: '#FFFFFF',
    marginTop: '14px',
  },
  statsRectangle: {
    width: '228.94px',
    height: '32.24px',
    background: '#FFFFFF',
    borderRadius: '5.37415px',
    paddingLeft: '18.27px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11.8231px',
    lineHeight: '18px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center' as 'center',
  },
  statsRectContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    gap: '0px',
  },
  statsRectFirstPart: {
    width: '17.65px',
    height: '32.24px',
    backgroundSize: 'cover',
    backgroundImage: `url(${borderedRect})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '5px center',
  },
  statsRectangleInner: {
    height: '32.24px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    background: '#FFE484',
    borderRadius: '5.37415px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15.0476px',
    lineHeight: '23px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  statsRectangleInner2: {
    height: '32.24px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    background: '#FFE484',
    borderRadius: '5.37415px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11.8231px',
    lineHeight: '18px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  dropDownProfileStart: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    gap: '5px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.06em',
    color: 'rgba(0, 0, 0, 0.7)',
  },
};

export default HeaderDropDown;
