import { log } from "console";

export class AuthModule {
  private static instance: AuthModule;
  private accessToken: string | null;
  private refreshToken: string | null;

  public constructor() {
    this.accessToken = localStorage.getItem('accessToken');
    this.refreshToken = localStorage.getItem('refreshToken');
  }

  public isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.accessToken && this.refreshToken) {
        this.getAboutMe()
          .then((data) => {
            this.processAboutMe(data, resolve, reject);
          })
          .catch((error) => {
            console.error('Error:', error);
            reject(false);
          });
      } else {
        resolve(!!this.accessToken);
      }
    });
  }

  private processAboutMe(
    data: any,
    resolve: (value: boolean | PromiseLike<boolean>) => void,
    reject: (reason?: any) => void
  ) {
    if (data && this.accessToken) {
      resolve(true);
    } else if (this.refreshToken) {
      this.getNewAccessToken(this.refreshToken).then((data) => {
        if (data) {
          // this.accessToken = data.access;
          console.log('new access token', data);
          resolve(true);
        } else {
          reject(false);
        }
      });
    } else {
      reject(false);
    }
  }

  public static getInstance(): AuthModule {
    if (!AuthModule.instance) {
      AuthModule.instance = new AuthModule();
    }
    return AuthModule.instance;
  }

  public performLogout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.clear();
  }

  public getAccessToken(): string {
    if (!this.accessToken) {
      this.performLogout();
      return '';
    } else {
      return this.accessToken;
    }
  }

  public async getAboutMe(): Promise<any> {
    const apiUrl = process.env.REACT_APP_ME_API as string + 'me';
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.accessToken,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            // console.log(data);

            resolve(data);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(false);
        });
    });
  }

  public async getNewAccessToken(refreshFromCache: string): Promise<string> {
    const apiUrl = process.env.REACT_APP_ME_API as string + 'token/refresh';
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh: refreshFromCache,
        }),
      })
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            this.setToken(data);
            resolve(data);
          } else {
            reject('Login failed.');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  }

  public async sendOTP(
    email: string,
    password: string,
    phone_number: string,
    name: string,
    passwordReset: boolean = false,
    forceSend: boolean = false
  ): Promise<string> {
    const apiUrl = process.env.REACT_APP_ME_API as string + 'signup';
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          phone_number,
          name,
          password,
          reset_password: passwordReset,
          force_resend: forceSend,
        }),
      }).then(async (response:any) => {
        const data = await response.json();
        if (response.ok) {
          resolve('success');
        } else if (data === 'EMAIL_ALREADY_EXISTS') {
          reject('EMAIL_ALREADY_EXISTS');
        } else if (data === 'PHONE_NUMBER_ALREADY_EXISTS') {
          reject('PHONE_NUMBER_ALREADY_EXISTS');
        } else if (data === 'USER_NOT_FOUND') {
          reject('USER_NOT_FOUND');
        } else {
          console.error('Login failed.');
          reject(data);
          if (data?.errors?.[0].includes('email')) {
            reject('EMAIL_EXISTS');
          }
          reject('GENERIC_ERROR');
        }
      });
    });
  }

  public async completeSignUp(
    email: string,
    otp: string,
    password: string | null,
    phone_number?: string | null
  ) {
    const requestBody = password
      ? { email, otp, password, phone_number }
      : { email, phone_number, otp };
      const apiUrl = process.env.REACT_APP_ME_API as string + 'signup/activate';
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            resolve('success');
          } else if (data === 'OTP_INCORRECT') {
            reject('OTP_INCORRECT');
          } else {
            reject(data.message);
            console.error('Login failed.');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  }

  public async performLogin(email: string, password: string): Promise<string> {
    const apiUrl = process.env.REACT_APP_ME_API as string + 'token';
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then(async (response) => {
          const data = await response.json();
          // console.log(data,'data');
          if (response.ok) {
            
            if (data === "No active account found with the given credentials") {
              reject('NO_ACTIVE_ACCOUNT');
            } else if (data === 'CREDENTIALS_INVALID') {
              reject('CREDENTIALS_INVALID');
            } else {
              this.setToken(data);
              resolve(data);
            }
          } else {
            console.error('Login failed.');
            reject(data);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  }

  private setToken(data: AuthResponse) {
    this.accessToken = data.access;
    this.refreshToken = data.refresh;
    this.cacheTokens(data);
  }

  private cacheTokens(data: AuthResponse) {
    localStorage.setItem('accessToken', data.access);
    localStorage.setItem('refreshToken', data.refresh);
  }
}

export interface AuthResponse {
  access: string;
  refresh: string;
}

// this api for getting otp
export async function getPasswordResetOTP(
  email: string
): Promise<{ Message: string }> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'password/reset';
  const requestBody = { email };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    return { Message: responseData.Message };
  } catch (error) {
    console.error('Error:', error);
    return {
      Message: 'Failed to reset password. Please try again later.',
    };
  }
}

// after geeting otp we are restting the password
export interface UpdatePasswordResponse {
  Status: number;
  Message: string;
}

export async function updateUserPassword(
  email: string,
  new_password: string,
  confirm_password: string,
  otp: string
): Promise<UpdatePasswordResponse> {
  const apiUrl = process.env.REACT_APP_ME_API as string + 'password/update';
  const requestBody = {
    email,
    new_password,
    confirm_password,
    otp,
  };

  console.log(requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    // if (!response.ok) {
    //   throw new Error('Failed to update password');
    // }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    return {
      Status: 500,
      Message: 'Failed to update password. Please try again later.',
    };
  }
}
