import { useEffect, useState } from 'react';
import { Styles, Theme, ThemeTypes } from '../../Utils/Constants';
import StrategyInputs, { IStrategyInput } from './StrategyInputs';
import StrategyLeg, { DefaultLeg, IStrategyLeg } from './StrategyLeg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import useWindowSize from '../Hooks/useWindowSize';
import { West } from '@mui/icons-material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  SaveBacktestRequestBody,
  saveBacktestResults,
  // saveResultsJSONData,
} from '../../API/HomePageAPI';
import { decodeJwtToken } from '../../API/DecodeJWTFunction';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { BackTestResponse } from '../../API/API.interface';
import { useNavigate } from 'react-router-dom';
import SavedStrategyInputs from './SavedStrategyInputs';
import SavedStrategyLeg from './SavedStrategyLeg';
import { fetchUserPlanPricing } from '../../API/ProfileAPI';
import { AuthModule } from '../../API';
import { is } from 'date-fns/locale';
import { UpGradeBacktestPlanMessagePopup } from '../AIStrategies/UpGradeBacktestPlanPopUp';
import { UpGradeBacktestPlanBeforeMessagePopup } from '../AIStrategies/UpgradePlanPopUpBefore';
import useAppStore from '../../store/app.store';
import Toast from '../../Utils/Toast';

export interface IInputsPageResult {
  strategy: IStrategyInput;
  legs: IStrategyLeg[];
}

export interface IInputsPageProps {
  onInputsChanged: (strategy: IInputsPageResult) => void;
  isLoading: boolean;
  showPrintButton: boolean;
  rawResults: BackTestResponse;
  savedData?: any;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InputsPage: React.FC<IInputsPageProps> = (props) => {
  const navigate = useNavigate();
  let user_ID: string = '';
  const decodedToken = decodeJwtToken();
  user_ID = String(decodedToken?.user_id ?? '');
  const [saving, setSaving] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [saveBacktestName, setSaveBacktestName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { userBacktestData, setUserBacktestData } = useAppStore();
  const today = new Date(); // Get today's date
  // const {dailyBackTestResults, dayBackTestResults} = props.rawResults
  const lessThanOneDayAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate() - 1); // Calculate 2 year ago
  const defaultStrategyInputs = {
    fromDate: twoYearsAgo,
    toDate: lessThanOneDayAgo,
    entryTime: new Date('2023-06-14 09:16'),
    exitTime: new Date('2023-06-14 15:05'),
    index: 'NIFTYBANK',
    strategy: 'ShortStrangle',
    slippage: '1',
  };

  const [strategyInputs, setStrategyInputs] = useState<IStrategyInput>(
    defaultStrategyInputs
  );
  const [savedstrategyInputs, setSavedStrategyInputs] =
    useState<IStrategyInput>(defaultStrategyInputs);
  const [legs, setLegs] = useState<IStrategyLeg[]>([DefaultLeg]);
  const [savedlegs, setSavedLegs] = useState<IStrategyLeg[]>([DefaultLeg]);
  const [showWatermark, setShowWatermark] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [userBacktestPlan, setUserBacktestPlan] = useState('Free');
  const [userStrategyPlan, setUserStrategyPlan] = useState('Free');
  const [backtestRunCount, setBacktestRunCount] = useState(0);
  const [isPlanFree, setIsPlanFree] = useState(false);
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const todayDate = `${day}-${month}-${year}`;

  const dailyBackTestResults = props.rawResults?.dailyBackTestResults;
  const dayBackTestResults = props.rawResults?.dayBackTestResults;
  const mockTestAPIRequest = props.rawResults?.mockTestAPIRequest;
  const monthBackTestResults = props.rawResults?.monthBackTestResults;
  const resultsSummary = props.rawResults?.resultsSummary;
  const relative_performance = props.rawResults?.relative_performance;

  useEffect(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated);
      })
      .catch((error) => {
        setIsAuthenticated(false);
      });
  }, []);

  const [requestBody, setRequestBody] = useState<SaveBacktestRequestBody>({
    input_filters: {
      index: '',
      strategy: '',
      start_date: `${strategyInputs.fromDate}`,
      end_date: `${strategyInputs.toDate}`,
      entry_time: `${strategyInputs.entryTime}`,
      exit_time: `${strategyInputs.exitTime}`,
      underlying: `${strategyInputs.index}`,
      strategy_type: `${strategyInputs.strategy}`,
      risk_percent: `${strategyInputs.slippage}`,
      legs: legs.map((leg) => ({
        direction: `${leg.actionType}`,
        option_type: `${leg.optionType}`,
        expiry: 'Weekly',
        lots: `${leg.lots}`,
        strike: `${leg.atmDiff}`,
        stop_loss: `${leg.slPerct}`,
        target_percent: `${leg.tpPerct}`,
      })),
    },
    runtest_data: {
      dailyBackTestResults: dailyBackTestResults ? dailyBackTestResults : {},
      dayBackTestResults: dayBackTestResults ? dayBackTestResults : {},
      mockTestAPIRequest: mockTestAPIRequest ? mockTestAPIRequest : {},
      monthBackTestResults: monthBackTestResults ? monthBackTestResults : {},
      resultsSummary: resultsSummary ? resultsSummary : {},
      relative_performance: relative_performance?relative_performance:{},
    },
    metadata: {
      name: `${saveBacktestName}`,
      date: `${todayDate}`,
      user_id: `${user_ID}`,
    },
  });

  const windowSize = useWindowSize();
  const handleContinueClick = () => {
    // Logic to handle "Continue" click
    props.onInputsChanged({
      strategy: strategyInputs,
      legs: legs,
    });
  };

  const submitInputs = () => {
    if(backtestRunCount >= 10 && userBacktestPlan === 'Free') {
      UpGradeBacktestPlanMessagePopup(backtestRunCount);
    }else{
      if (backtestRunCount <= 10 && userBacktestPlan === 'Free') {
    UpGradeBacktestPlanBeforeMessagePopup(backtestRunCount, handleContinueClick);
  }
    }
  };

  const printPage = () => {
    const options = {
      filename: 'backtest.pdf',
      margin: 0,
      image: { type: 'jpeg', quality: 0.98 },
      // html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
      html2canvas: {
        scale: window.devicePixelRatio,
        windowWidth: '150%',
        useCORS: true,
      },
      pagebreak: { mode: 'avoid-all' },
      jsPDF: {
        unit: 'in',
        format: 'a2',
        orientation: 'portrait',
      },
    };
    // setTimeout(() => {
    //   window.print();
    // }, 200);
    html2pdf().set(options).from(document.body).save();

   
  };

  const saveStrategyInputs = (newLegs: IStrategyLeg[]) => {
    const clonedLegs = [...newLegs];
    clonedLegs.map((leg) => ({ ...leg }));
    setLegs(clonedLegs);
  };

  const removeLegEntry = (idx: number) => {
    if (legs.length === 1) {
      return;
    }
    setLegs([]);
    const newLegs = [...legs];
    newLegs.splice(idx, 1);
    setTimeout(() => {
      setLegs(newLegs);
    }, 100);
  };

  function getWaterMarks() {
    const paragraphElements = [];
    for (let i = 0; i < 1000; i++) {
      paragraphElements.push(
        <p style={styles.watermarkText} key={i}>
          Moneyy.ai
        </p>
      );
    }
    return <div style={styles.watermarksWrapper}>{paragraphElements}</div>;
  }

  function flipShowMore() {
    setShowMore(!showMore);
  }

  // console.log(props.savedData.metadata.name !== '');

  function getStrategyLegsCreation() {
    // console.log("getStrategyLegsCreation");
    return (
      <StrategyLeg
        legs={legs}
        saveStrategyInputs={saveStrategyInputs}
        removeLegEntry={removeLegEntry}
      />
    );
  }
  function getSavedStrategyLegsCreation() {
    // console.log("getSavedStrategyLegsCreation");
    return (
      <SavedStrategyLeg
        legs={savedlegs}
        saveStrategyInputs={saveStrategyInputs}
        removeLegEntry={removeLegEntry}
      />
    );
  }

  const handleSavePopup = () => {
    setShowPopUp(true);
  };

  const handleBacktestName = (event: any) => {
    const inputValue = event.target.value;
    setSaveBacktestName(inputValue);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // const objectUrl = await saveResultsJSONData(
      //   Number(user_ID),
      //   props.rawResults
      // );
      // await saveBacktestResults(Number(user_ID), objectUrl);
      console.log(requestBody);

      saveBacktestResults(requestBody)
        .then((responseData: any) => {
          // console.log('API Response', responseData);
          if (responseData.response_code === 200) {
            setShowPopUp(false);
            Toast.success(responseData.response_message);
            props.setIsUpdated((preValue) => !preValue);
          } else {
            setShowPopUp(false);
            Toast.error('Error in saving Backtest');
          }
        })
        .catch((error) => {
          setShowPopUp(false);
          console.error('API Error', error);
          Toast.error('Error in saving Backtest');
        });
      setSaving(false);
    } catch (error) {
      console.error('Error saving data', error);
      setSaving(false);
      setShowPopUp(false);
    }
  };

  const handleCancelClick = () => {
    setShowPopUp(false);
    setSaveBacktestName('');
  };

  useEffect(() => {
    setRequestBody((prevState) => ({
      ...prevState,
      runtest_data: {
        ...prevState.runtest_data,
        dailyBackTestResults: dailyBackTestResults || {},
        dayBackTestResults: dayBackTestResults ? dayBackTestResults : {},
        mockTestAPIRequest: mockTestAPIRequest ? mockTestAPIRequest : {},
        monthBackTestResults: monthBackTestResults ? monthBackTestResults : {},
        resultsSummary: resultsSummary ? resultsSummary : {},
      },
      metadata: {
        ...prevState.metadata,
        name: saveBacktestName || '',
      },
    }));
  }, [
    dailyBackTestResults,
    saveBacktestName,
    mockTestAPIRequest,
    monthBackTestResults,
    resultsSummary,
    dayBackTestResults,
  ]);

  useEffect(() => {
    setRequestBody((prevState) => ({
      ...prevState,
      input_filters: {
        index: '',
        strategy: '',
        start_date: `${strategyInputs.fromDate}`,
        end_date: `${strategyInputs.toDate}`,
        entry_time: `${strategyInputs.entryTime}`,
        exit_time: `${strategyInputs.exitTime}`,
        underlying: `${strategyInputs.index}`,
        strategy_type: `${strategyInputs.strategy}`,
        risk_percent: `${strategyInputs.slippage}`,
        legs: legs.map((leg) => ({
          direction: `${leg.actionType}`,
          option_type: `${leg.optionType}`,
          expiry: 'Weekly',
          lots: `${leg.lots}`,
          strike: `${leg.atmDiff}`,
          stop_loss: `${leg.slPerct}`,
          target_percent: `${leg.tpPerct}`,
        })),
      },
    }));
  }, [strategyInputs, legs, savedlegs]);

  useEffect(() => {
    if (props.savedData.metadata.name !== '') {
      const transformedLegs = props.savedData.input_filters.legs.map(
        (legData: any, index: number) => ({
          index: index + 1,
          actionType: legData.direction === 'Buy' ? 'Buy' : 'Sell',
          optionType: legData.option_type,
          lots: parseInt(legData.lots, 10),
          slPerct: parseInt(legData.stop_loss, 10),
          tpPerct: parseInt(legData.target_percent, 10),
          atmDiff: parseInt(legData.strike, 10),
          instrument: 'NIFTYBANK',
        })
      );
      setSavedLegs(transformedLegs);
    }
  }, [props.savedData]);

  useEffect(() => {
    if (props.savedData.metadata.name !== '') {
      const transformedInputs: IStrategyInput = {
        fromDate: new Date(props.savedData.input_filters.start_date),
        toDate: new Date(props.savedData.input_filters.end_date),
        entryTime: new Date(props.savedData.input_filters.entry_time),
        exitTime: new Date(props.savedData.input_filters.exit_time),
        index: props.savedData.input_filters.underlying,
        strategy: props.savedData.input_filters.strategy_type,
        slippage: props.savedData.input_filters.risk_percent,
      };

      setSavedStrategyInputs(transformedInputs);
    }
  }, [props.savedData]);

  // console.log(savedstrategyInputs);

  const fetchUserData = async () => {
    try {
      const userData = await fetchUserPlanPricing();
      const { strategy, backtesting } = userData.user_plan_details;
      setUserDetails(userData);
      setUserBacktestPlan(userData.user_plan_details.backtesting.plan_name);
      setUserStrategyPlan(userData.user_plan_details.strategy.plan_name);
      setBacktestRunCount(userData.runbacktest_count.last_month_backtesting_count);
      setTimeout(() => {
        setUserBacktestData(userData.runbacktest_count.total_backtesting_count);
      }, 1000);
      if (userBacktestPlan === 'Free') {
        setIsPlanFree(true);
      }
      if (backtesting.plan_name === 'Free') {
        // setIsBuyNowClicked(false);
      } else if (backtesting.plan_name === 'PREMIUM') {
        // setIsBuyNowClicked(true);
      }
      if (strategy.plan_name === 'Free') {
        // setIsStrategyBuyNowClicked(false);
      } else if (strategy.plan_name === 'PREMIUM') {
        // setIsStrategyBuyNowClicked(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchUserData(); // Fetch user data on page load
  }, [props.rawResults]);

  return (
    <>
      {/* <ToastContainer /> */}
      {windowSize > 800 ? (
        <p style={Styles.strategySectionHeaderText}>
          Choose Index and Strategy
        </p>
      ) : (
        <p
          style={{
            ...Styles.strategySectionHeaderText,
            display: 'flex' as const,
            alignItems: 'center' as const,
            justifyContent: 'flex-start' as const,
            gap: '17.44px',
            marginTop: 13,
            marginBottom: 25,
          }}
        >
          <West
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          />
          Choose Index and Strategy
        </p>
      )}
      {props.savedData.metadata.name === '' ? (
        <StrategyInputs
          onInputsChanged={setStrategyInputs}
          strategyInputs={strategyInputs}
          showMore={showMore}
          isPlanFree={isPlanFree}
        />
      ) : (
        <SavedStrategyInputs
          onInputsChanged={setSavedStrategyInputs}
          strategyInputs={savedstrategyInputs}
          showMore={showMore}
          savedData={props.savedData}
        />
      )}

      <button style={styles.transparentButton} onClick={flipShowMore}>
        {showMore ? (
          <KeyboardArrowUpIcon style={ThemeTypes.buttonIcon} />
        ) : (
          <KeyboardArrowDownIcon style={ThemeTypes.buttonIcon} />
        )}
        {showMore ? 'View Less' : 'View More'}
      </button>
      <p style={Styles.strategySectionHeaderText}>Design Strategy Legs</p>
      {props.savedData.metadata.name === ''
        ? getStrategyLegsCreation()
        : getSavedStrategyLegsCreation()}

      <div
        style={
          windowSize <= 500 ? styles.buttonListResponsive : styles.buttonList
        }
      >
        {props.savedData.metadata.name === '' && (
          <button
            // disabled={
            //   props.isLoading ||
            //   (userBacktestPlan === 'Free' && backtestRunCount >= 10)
            // }
            onClick={submitInputs}
            style={{ ...ThemeTypes.yellowButton, marginLeft: 0 }}
            className='no-wrap'
          >
            <PlayCircleIcon style={ThemeTypes.yellowButtonIcon} />
            Start Backtest
          </button>
        )}

        {showWatermark && <div style={styles.watermark}>{getWaterMarks()}</div>}
        {props.savedData.metadata.name !== '' && (
          <button
            // disabled={props.isLoading || userBacktestPlan !== 'PREMIUM'}
            onClick={printPage}
            style={
              windowSize <= 500
                ? { ...ThemeTypes.yellowButton, marginLeft: 0 }
                : { ...ThemeTypes.yellowButton }
            }
            className='no-wrap'
          >
            <CloudDownloadOutlinedIcon style={ThemeTypes.yellowButtonIcon} />
            Download
          </button>
        )}
        {props.showPrintButton && (
          <>
            <button
              // disabled={props.isLoading || userBacktestPlan !== 'PREMIUM'}
              onClick={printPage}
              style={
                windowSize <= 500
                  ? { ...ThemeTypes.yellowButton, marginLeft: 0 }
                  : { ...ThemeTypes.yellowButton }
              }
              className='no-wrap'
            >
              <CloudDownloadOutlinedIcon style={ThemeTypes.yellowButtonIcon} />
              Download
            </button>
            <button
              onClick={handleSavePopup}
              disabled={saving}
              style={
                windowSize <= 500
                  ? { ...ThemeTypes.yellowButton, marginLeft: 0 }
                  : { ...ThemeTypes.yellowButton }
              }
              className='no-wrap'
            >
              <BookmarkBorderOutlinedIcon style={ThemeTypes.yellowButtonIcon} />
              {saving ? 'Saving...' : 'Save'}
            </button>
          </>
        )}

        {showPopUp && (
          <div className='subscribe-popup-card' style={{ height: '100vh' }}>
            <div
              className='popup-card-header'
              style={{ height: '200px', width: '550px' }}
            >
              <article>Saving Backtest</article>
              <br />

              <div
                className='popup-card-row'
                style={{ width: '450px', border: 'solid' }}
              >
                <article style={{ width: '180px', fontSize: '16px' }}>
                  Enter Backtest Name
                </article>
                <input
                  style={{ width: '170px' }}
                  type='text'
                  id='lots'
                  name='lots'
                  value={saveBacktestName}
                  placeholder='Type anything...'
                  onChange={(e) => handleBacktestName(e)}
                />
              </div>

              <br />

              <div className='popup-card-button-row'>
                <button className='popup-subscribe-btn' onClick={handleSave}>
                  {' '}
                  <DoneIcon style={{ width: '16px', height: '36px' }} /> Save
                </button>
                <button className='popup-close-btn' onClick={handleCancelClick}>
                  <CloseIcon style={{ width: '16px', height: '36px' }} />
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const styles = {
  buttonList: {
    display: 'flex' as 'flex',
    justifyContent: 'left' as 'left',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
  },
  buttonListResponsive: {
    display: 'flex' as 'flex',
    justifyContent: 'left' as 'left',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    width: '300px',
  },
  watermarksWrapper: {
    position: 'relative' as 'relative',
    top: 0,
    left: 0,
    display: 'flex' as 'flex',
    flexWrap: 'wrap' as 'wrap',
    height: '100vh',
  },
  yellowButton: {
    ...ThemeTypes.yellowButton,
  },
  transparentButton: {
    ...ThemeTypes.transparentButton,
    marginTop: Theme.gap22,
    marginBottom: Theme.gap33,
    marginLeft: 0,
  },
  watermark: {
    position: 'absolute' as 'absolute',
    top: 0,
    zIndex: 9999,
    fontSize: 46,
    textAlign: 'center' as 'center',
    color: '#cccccc22',
    height: '250vh',
    overflow: 'hidden',
  },
  inputBoxes: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    backgroundColor: Theme.colors.backgroundEA,
    padding: Theme.gapSmall,
    borderRadius: Theme.borderRadius,
  },
  watermarkText: {
    width: '88%',
    transform: 'rotate(-45deg)',
    padding: Theme.gapLarge,
  },
};

export default InputsPage;
