 const fetchSubscriptionData = async () => {
  const apiUrl = process.env.REACT_APP_ME_API + 'plan/invoice';
      try {
        const response = await fetch(
          apiUrl,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const subsData = await response.json();
        return subsData;
      } catch (error) {
        console.error(error);
        return [];
      }
    };


export default fetchSubscriptionData;