import { useEffect, useState } from 'react';
import { Theme } from '../../../Utils/Constants';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useWindowSize from '../../Hooks/useWindowSize';

interface DatePickerProps {
  label: string;
  minDate: Date;
  maxDate: Date;
  defaultDate: Date;
  onChange: (date: Date) => void;
  views?: ('year' | 'month' | 'day')[];
}

function DateInput({
  label,
  onChange,
  minDate,
  maxDate,
  defaultDate,
  views
}: DatePickerProps) {
  const [date, setDate] = useState<Date | null>(defaultDate);

  const windowSize = useWindowSize();

  function handleDateChange(dateValue: Date | null, _: any) {
    setDate(dateValue);
    onChange(dateValue || defaultDate);
  }
  useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate]);

  return (
    <div style={windowSize <= 670 ? styles.dateBoxResponsive : styles.dateBox}>
      <DatePicker
        label={label}
        value={date}
        timezone='UTC'
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        views={['year', 'month', 'day']}
        className={windowSize <= 670 ? 'date-picker-responsive' : ''}
      />
    </div>
  );
}

const styles = {
  dateBox: {
    minWidth: Theme.inputElementMinWidth,
    margin: Theme.gapTiny,
  },
  dateBoxResponsive: {
    width: 163,
    height: 36.9,
  },
};

export default DateInput;
