import { IResultsAPIInput } from './API.interface';
import { AuthModule } from './Auth';

export class ResultsAPIModule {
  private auth: AuthModule;
  public constructor() {
    this.auth = AuthModule.getInstance();
  }

  public async getResults(data: IResultsAPIInput) {
    const accessToken = this.auth.getAccessToken();
    const apiUrl = process.env.REACT_APP_ME_API as string + 'backtest/wrapper';
    try {
      return fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
