import React, { useEffect, useState } from 'react';
import { Theme } from '../../Utils/Constants';
import { getPercentages } from '../../Utils/Converters';
import { formatNumber } from '../../API/FormatNumber';

interface Props {
  margins: number;
  showPerct: boolean;
  results: { [key: string]: { [key: string]: number } };
}

const MonthlyViewContainer: React.FC<Props> = (props) => {
  const [isWidthLessThan1350, setIsWidthLessThan1350] =
    useState<boolean>(false);

  const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
    'ROI'
  ];
  const [isAbsoluteSelected, setIsAbsoluteSelected] = useState(false);

  const handleClick = () => {
    setIsAbsoluteSelected(!isAbsoluteSelected);
  };

  // const formatNumber = (amount: string): string => {
  //   const suffixes = ['', 'K', 'M', 'B', 'T'];
  //   const numberAmount = parseFloat(amount.replace(/,/g, ''));
  //   const isNegative = numberAmount < 0;
  //   const absoluteAmount = Math.abs(numberAmount);
  //   if (absoluteAmount >= 1000) {
  //     const suffixIndex = Math.floor(Math.log10(absoluteAmount) / 3);
  //     const shortValue = (absoluteAmount / Math.pow(1000, suffixIndex)).toFixed(
  //       1
  //     );
  //     return (isNegative ? '-' : '') + shortValue + suffixes[suffixIndex];
  //   } else {
  //     return amount;
  //   }
  // };

  useEffect(() => {
    function handleResize() {
      setIsWidthLessThan1350(window.innerWidth < 1350);
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let minValue = 0;
  let maxValue = 0;
  for (const year in props.results) {
    for (const weekDay in props.results[year]) {
      const incoming = props.results[year][weekDay];
      if (incoming < minValue) {
        minValue = incoming;
      }
      if (incoming > maxValue) {
        maxValue = incoming;
      }
    }
  }

  function getBackgroundColorTranparency(value: number) {
    let perct = 0;
    if (value < 0) {
      perct = (value / minValue) * 100;
    } else {
      perct = (value / maxValue) * 100;
    }
    if (perct > 66) {
      return 'CC';
    } else if (perct > 33) {
      return '99';
    } else {
      return '66';
    }
  }

  function getAllMonths() {
    return months.map((month) => {
      return (
        <th
          key={month}
          style={{
            ...styles.headerCell,
            padding: isWidthLessThan1350 ? '2px' : '6px',
          }}
        >
          {month.substring(0, 3)}
        </th>
      );
    });
  }

  function getPerctAppendStr(value: number) {
    if (isAbsoluteSelected) {
      return '';
    }

    if (isNaN(value) || value === undefined) {
      return '0%';
    }

    const percentage = getPercentages(value, props.margins);
    return percentage;
  }

  function getAllMonthsValues(year: string) {
    return months.map((month) => {
      const value = props.results[year][month];
      const isROI = month === 'ROI';
      let numericValue = 0;
  
      let renderedValue: string | number = '';
      if (isROI) {
        renderedValue = value;
        let val = String(props.results[year][month])
        numericValue = parseFloat(val.replace("%", ""));
      } else if (typeof value === 'number') {
        renderedValue = isAbsoluteSelected ? formatNumber(value.toFixed(2)) : '';
        if (!isAbsoluteSelected) {
          renderedValue = `${renderedValue} ${getPerctAppendStr(value)}`;
        }
      }
  
      if(month === 'ROI'){
        return (
          <td
            style={{
              ...styles.cellStyle,
              padding: isWidthLessThan1350 ? '2px' : '6px',
              ...styles.rowTopBorder,
            }}
            key={month}
          >
            {value && (
              <p style={getClassName(numericValue)}>
                {`${numericValue.toFixed(1)}%`}
              </p>
            )}
          </td>
        );
      }else{
        return (
          <td
            style={{
              ...styles.cellStyle,
              padding: isWidthLessThan1350 ? '2px' : '6px',
              ...styles.rowTopBorder,
            }}
            key={month}
          >
            {value && (
              <p style={getClassName(value)}>
                {renderedValue}
              </p>
            )}
          </td>
        );
      }
    });
  }
  

  function getAllDaysTotalValue() {
    return months.map((month) => {
      let totalValue = 0;
      let totalROI = 0;
      let count = 0;
      for (const year in props.results) {
        const value = props.results[year][month];
        if(month === 'ROI'){
         let val = String(props.results[year][month])
         const numericValue = parseFloat(val.replace("%", ""));
         if (!isNaN(numericValue)) {
          totalROI += numericValue;
          count++;
        }
        }else{
          totalValue += value ? value : 0;
        }
      }
  
      let renderedValue: string | number = '';
      if (month === 'ROI' && count > 0) {
        const avgROI = totalROI;
        renderedValue = `${avgROI.toFixed(1)}%`; // Display average ROI as percentage
      } else if (typeof totalValue === 'number') {
        renderedValue = isAbsoluteSelected ? formatNumber(totalValue.toFixed(2)) : '';
        if (!isAbsoluteSelected) {
          renderedValue = `${renderedValue} ${getPerctAppendStr(totalValue)}`;
        }
      }
  
      if(month === 'ROI'){
        return (
          <td
            key={totalROI}
            style={{
              ...styles.cellStyle,
              padding: isWidthLessThan1350 ? '2px' : '6px',
              ...styles.rowTotalTopBorder,
              ...styles.totalRow,
            }}
          >
            <p style={getClassName(totalROI, true)}>
              {renderedValue}
            </p>
          </td>
        );
      }else{
        return (
          <td
            key={totalValue}
            style={{
              ...styles.cellStyle,
              padding: isWidthLessThan1350 ? '2px' : '6px',
              ...styles.rowTotalTopBorder,
              ...styles.totalRow,
            }}
          >
            <p style={getClassName(totalValue, true)}>
              {renderedValue}
            </p>
          </td>
        );
      }
    });
  }
  

  function getTotalRow() {
    return (
      <tr key='monthTotalRow'>
        <td
          style={{
            ...styles.headerCell,
            padding: isWidthLessThan1350 ? '2px' : '6px',
            ...styles.rowTotalTopBorder,
            ...styles.totalColumn,
          }}
        >
          Total
        </td>
        {getAllDaysTotalValue()}
      </tr>
    );
  }

  function getClassName(digit: number, textOnly?: boolean) {
    let colorCode = Theme.colors.red;
    if (digit >= 0) {
      colorCode = Theme.colors.green;
    }
    if (textOnly) {
      return {
        ...styles.valueBox,
        padding: isWidthLessThan1350 ? '2px' : '6px',
        color: colorCode,
        fontSize: Theme.fontSizes.h4,
        fontWeight: Theme.fontWeight.semiBold,
      };
    }
    return {
      ...styles.valueBox,
      padding: isWidthLessThan1350 ? '2px' : '6px',
      backgroundColor: colorCode + getBackgroundColorTranparency(digit),
      fontSize: Theme.fontSizes.h5,
    };
  }

  function getAllYears() {
    const years = Object.keys(props.results);
    return years.map((year) => {
      return (
        <tr key={year + 'monthView'}>
          <td
            style={{
              ...styles.headerCell,
              padding: "20px 0px",
              ...styles.rowTopBorder,
              ...styles.yearColumn,
            }}
          >
            {year}
          </td>
          {getAllMonthsValues(year)}
        </tr>
      );
    });
  }

  return (
    <table style={styles.tableStyle}>
      <div
        style={{
          borderRadius: '5px',
          width: '172px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '27px',
          boxShadow: isAbsoluteSelected
            ? '0'
            : 'inset 4px 4px 5px 0px #BBBE189C',
          backgroundColor: "#FFE484",
          marginBottom: Theme.gap25
        }}
      >
        
        <button
          style={{
            backgroundColor: isAbsoluteSelected ? '#FFFFFF' : 'rgba(0,0,0,0)',
            margin: '0',
            color: '#000000B2',
            height: '100%',
            padding: '4px 0px 3px 8px',
            width: '92px',
            boxShadow: isAbsoluteSelected
              ? 'inset 0px 1px 4px 0px #00000040'
              : 'none',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left'
          }}
          onClick={handleClick}
        >
          Percentage
        </button>
        <button
          style={{
            backgroundColor: isAbsoluteSelected ? 'rgba(0,0,0,0)' : '#FFFFFF',
            margin: '0',
            color: '#000000B2',
            height: '100%',
            padding: '4px 0px 3px 8px',
            width: '80px',
            boxShadow: isAbsoluteSelected
              ? 'none'
              : 'inset 0px 1px 4px 0px #00000040',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left'
          }}
          onClick={handleClick}
        >
          Absolute
        </button>
      </div>
      <thead>
        <tr style={{borderBottom: '1px solid ' + Theme.colors.whiteGrey70,}}>
          <th
            style={{
              ...styles.headerCell,
              padding: "19px 0px",
              fontSize: Theme.fontSizes.h3,
              paddingLeft: "20px",
            }}
          >
            moneyy.ai
          </th>
          {getAllMonths()}
          <th style={{width: "20px"}}></th>
        </tr>
      </thead>
      <tbody>
        {getAllYears()}
        {getTotalRow()}
      </tbody>
    </table>
  );
};

const styles = {
  totalRow: {
    fontWeight: Theme.fontWeight.semiBold,
    fontSize: Theme.fontSizes.h1,
  },
  totalColumn: {
    fontSize: Theme.fontSizes.h3,
    fontWeight: Theme.fontWeight.semiBold,
  },
  yearColumn: {
    fontWeight: Theme.fontWeight.semiBold,
    fontSize: Theme.fontSizes.h4,
  },
  rowTotalTopBorder: {
    borderTop: '1px solid ' + Theme.colors.whiteGrey70,
  },
  rowTopBorder: {
    borderTop: '1px solid ' + Theme.colors.whitishGrey,
  },
  tableStyle: {
    borderSpacing: 0,
    display: 'inline-block',
    width: '100%',
  },
  headerCell: {
    color: Theme.colors.black70,
    fontWeight: 'normal' as 'normal',
    fontSize: Theme.fontSizes.h5,
    margin: 0,
    textAlign: 'center' as const,
  },
  cellStyle: {
    textAlign: 'center' as const,
    width: '8.333%',
  },
  valueBox: {
    textAlign: 'center' as const,
    fontSize: Theme.fontSizes.h5,
    width: 'fit-content',
    margin: 'auto',
    borderRadius: Theme.borderRadius,
  },
};

export default MonthlyViewContainer;
