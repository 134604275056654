import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Styles, Theme, ThemeTypes } from '../../Utils/Constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
interface PopupProps {
  strategyName: string;
}

const StrategyUnsubscribePopup: React.FC<PopupProps> = ({ strategyName }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className='subscribe-popup-card'
      style={{
        display: isVisible ? 'block' : 'none',
      }}
    >
      <div
        className='flex-center'
        style={{
          padding: '10px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          zIndex: 999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CheckCircleIcon
            style={{ fontSize: 60 }}
            className='check-circle-icon'
          />
          <span
            style={{
              fontWeight: Theme.fontWeight.medium,
              fontSize: Theme.fontSizes.h3,
            }}
          >
            "{strategyName}" Unsubscribed Successfully..
          </span>
        </div>
      </div>
    </div>
  );
};

export const StrategyUnsubscribeMessagePopup = (strategyName: string) => {
  const popupElement = document.createElement('div');
  document.body.appendChild(popupElement);

  const closePopup = () => {
    ReactDOM.unmountComponentAtNode(popupElement);
    popupElement.remove();
  };

  ReactDOM.render(
    <StrategyUnsubscribePopup strategyName={strategyName} />,
    popupElement
  );

  setTimeout(() => {
    closePopup();
  }, 3000);
};
