import React, { useEffect, useState, useRef } from "react";
import { AuthModule } from "../../API";
import { useNavigate } from "react-router-dom";
import { Styles, Theme } from "../../Utils/Constants";
import Header, { CurrentPage } from "../Header/Header";

import WebsiteOptions from "./WebsiteOptions";
import PricingPlans from "./PricingPlans";
import MediaAppearances from "./MediaAppearances";
import Footer from "../Footer";
import UserTestimonials from "./UserTestimonials";
import FreeTrialSection from "./FreeTrialSection";
import IntroAndAuthSection from "./IntroAndAuthSection";
import PlanAndPricing from "../Profile/PlanAndPricing";
import Pricing from "../Profile/Pricing";
import useAppStore from "../../store/app.store";
import useWindowSize from "../Hooks/useWindowSize";

const LandingPageAfterLogin: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const pricingAndPlanRef = useRef<HTMLDivElement>(null);
  const { isPricingClicked, setIsPricingClicked } = useAppStore();

  const windowSize = useWindowSize();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isAuthenticated =
          await AuthModule.getInstance().isAuthenticated();
        setIsAuthenticated(isAuthenticated);
        // If not authenticated and isPricingClicked is true, scroll to the Pricing and Plan component
        if (!isAuthenticated && isPricingClicked && pricingAndPlanRef.current) {
          pricingAndPlanRef.current.scrollIntoView({ behavior: "smooth" });
          // Reset isPricingClicked after scrolling
          setIsPricingClicked(false);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    checkAuthentication();
  }, [isPricingClicked]); // Listen for changes in isPricingClicked

  const getLandingPageTitle = () => {
    return (
      <>
        <p style={windowSize <= 1000 ? styles.h1TextResponsive : styles.h1Text}>
          CHOOSE YOUR PRICING PLANS
        </p>
        <p
          style={
            windowSize <= 1000 ? styles.descTextResponsive : styles.descText
          }
        >
          Affordable Plans, Incredible Gains.
        </p>
      </>
    );
  };

  return (
    <div
      style={{
        backgroundColor: Theme.colors.backgroundF3,
        maxWidth: 1600,
        margin: "0px auto",
      }}
    >
      <Header currentPage={CurrentPage.None} />
      <IntroAndAuthSection />
      <WebsiteOptions />
      <div ref={pricingAndPlanRef}>
        {windowSize <= 800 ? (
          <div
            className="border-box"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "40px 0px"
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
              {getLandingPageTitle()}
              <div style={styles.dividerLine}></div>
            </div>
            <PlanAndPricing special={true} width={windowSize - 28} />
          </div>
        ) : isAuthenticated ? (
          <Pricing />
        ) : (
          <PricingPlans />
        )}
      </div>
      {/* {isAuthenticated ? <Pricing /> : <PricingPlans />} */}
      {/* Render PlanAndPricing if authenticated, else PricingPlans */}
      {/* <MediaAppearances />
      <UserTestimonials /> */}
      {/* <FreeTrialSection /> */}
      <Footer special={true} />
    </div>
  );
};

const styles = {
  h1Text: {
    ...Styles.h1Text,
    marginTop: "51px",
  },
  h1TextResponsive: {
    ...Styles.h1TextHomeResponsive,
    marginTop: "24px",
    padding: "0px 30px",
  },
  descText: {
    ...Styles.h4Text,
    textAlign: "center" as const,
  },
  descTextResponsive: {
    ...Styles.h4TextHomeResponsive,
    textAlign: "center" as const,
  },
  dividerLine: {
    ...Styles.bottomBorderLineResponsive,
  },
};

export default LandingPageAfterLogin;
