export async function fetchHomePageData(): Promise<any> {
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'home-page/details';

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData.response_data;
    } else {
      throw new Error(responseData.response_message);
    }
  } catch (error: any) {
    throw new Error(`Error: ${error?.message}`);
  }
}

// inputspage api's call
export interface SaveBacktestRequestBody {
  input_filters: {
    index: string;
    strategy: string;
    start_date: string;
    end_date: string;
    entry_time: string;
    exit_time: string;
    underlying: string;
    strategy_type: string;
    risk_percent: string;
    legs: {
      direction: string;
      option_type: string;
      expiry: string;
      lots: string;
      strike: string;
      stop_loss: string;
      target_percent: string;
    }[];
  };
  runtest_data: {
    dailyBackTestResults: any;
    dayBackTestResults: any;
    mockTestAPIRequest: any;
    monthBackTestResults: any;
    resultsSummary: any;
    relative_performance: any;
  };
  metadata: {
    name: string;
    date: string;
    user_id: string;
  };
}



// function to save backtest reslts with object url & user ID
export async function saveBacktestResults(requestBody: any): Promise<void> {
  const apiUrl = (process.env.REACT_APP_BASE_URL as string) + 'backtest/save';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      throw new Error(`fail to save backtest`);
    }
  } catch (error: any) {
    console.error('Error saving backtest:', error);
    throw error;
  }
}

// navbar page api call for latest 3 backtests

export async function getBacktestsData(userID: number): Promise<any> {
  const apiUrl = (process.env.REACT_APP_BASE_URL as string) + 'backtest/get';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      throw new Error('failed to fetch data');
    }

    const responseData = await response.json();
    return responseData.response_data;
  } catch (error) {
    console.log('error in fetching backtest data', error);
    throw error;
  }
}

interface DeleteBacktestApiResponse {
  response_code: number;
  response_message: string;
}

export async function deleteSavedBacktest(
  userId: number,
  // name: string,
  backtest_id: number
): Promise<DeleteBacktestApiResponse> {
  const url =
    (process.env.REACT_APP_BASE_URL as string) + 'backtest/delete';

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const requestBody = {
    user_id: userId,
    // name: name,
    // date: formattedDate,
    backtest_id: backtest_id,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData: DeleteBacktestApiResponse = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error deleting backtest:', error);
    throw error;
  }
}
