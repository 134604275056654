import React from "react";
import AuthSetupPage from "./AuthSetupPage";
import { Styles, Theme, ThemeTypes } from "../../Utils/Constants";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TimelineIcon from "@mui/icons-material/Timeline";
import UpdateIcon from "@mui/icons-material/Update";
import { PlayCircleOutline } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";

interface IntroAndAuthSectionProps {
  // Define the props for your component here
}

const IsLoginSection: React.FC<IntroAndAuthSectionProps> = (props) => {
  const windowSize = useWindowSize();

  const getLandingPageTitle = () => {
    return (
      <p
        style={
          windowSize <= 800
            ? windowSize <= 420
              ? windowSize <= 380
                ? {
                  ...Styles.h1TextUltraSmallResponsive,
                  textTransform: "none" as const,
                  position: "relative",
                  margin: 0
                }
                : {
                  ...Styles.h1TextSmallResponsive,
                  textTransform: "none" as const,
                  position: "relative",
                  margin: 0
                }
              : {
                ...Styles.h1TextResponsive,
                textTransform: "none" as const,
                position: "relative",
              }
            : {
              ...Styles.h1Text,
              textTransform: "none" as const,
              position: "relative",
            }
        }
      >
        Trade <span style={styles.titleColorText}>Smarter</span> with{" "}
        <span style={styles.titleColorText}>Moneyy.ai</span>
        <img
          src="/images/revurseCurve.png"
          alt="reverse curve"
          style={{
            position: "absolute",
            top: "100%",
            left: "70%",
          }}
        />
        {windowSize > 800 && (
          <img
            src="/images/Dots.svg"
            alt="dotted background"
            style={{
              position: "absolute",
              top: "690%",
              right: "-114%",
            }}
          />
        )}
        {windowSize > 800 && (
          <img
            src="/images/circlesGroups.png"
            alt="circle"
            style={{
              position: "absolute",
              top: "215%",
              left: "185%",
              right: "2.5%",
            }}
          />
        )}
        {windowSize > 800 && (
          <img
            src="/images/Dots.svg"
            alt="dotted background"
            style={{
              position: "absolute",
              top: "8%",
              left: "-115%",
            }}
          />
        )}
      </p>
    );
  };

  return (
    <>
      <div
        style={
          windowSize <= 800
            ? { ...styles.flexContainerResponsive, position: "relative" }
            : { ...styles.flexContainer, position: "relative" }
        }
      >
        {windowSize > 800 && (
          <img
            src="/images/reverseCircleGrp.png"
            alt="circle"
            style={{
              position: "absolute",
              top: "0%",
              left: "auto",
              right: "2.5%",
            }}
          />
        )}

        {windowSize > 800 ? (
          <img
            src="/images/halfC.png"
            alt="half circle"
            style={{
              position: "absolute",
              top: "337px",
              left: "0%",
            }}
          />
        ) : (
          <img
            src="/images/halfC.png"
            alt="half circle"
            style={{
              width: "38.5px",
              height: "77px",
              position: "absolute",
              bottom: "-80px",
              left: "0%",
            }}
          />
        )}

        <img
          src="/images/curve.png"
          alt="curve"
          style={{
            position: "absolute",
            top: "88%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            width: "100%",
          }}
        />

        <div
          style={
            windowSize <= 800
              ? styles.centeredContainerResponsive
              : styles.centeredContainer
          }
        >
          {getLandingPageTitle()}
          <div
            style={
              windowSize <= 800 ? windowSize <= 420 ? {...styles.loginBoxResponsive, marginTop: 60} : styles.loginBoxResponsive : styles.loginBox
            }
          >
            <AuthSetupPage />
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  centeredContainerResponsive: {
    display: "flex",
    flexDirection: "column" as "column", // Explicitly specify the type
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "0px 23px 0px 18.18px",
    // overflow: "hidden",
  },
  centeredContainer: {
    display: "flex",
    flexDirection: "column" as "column", // Explicitly specify the type
    alignItems: "center",
    justifyContent: "center",
  },
  titleColorText: {
    color: Theme.colors.blueSolid,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0 48px 0",
  },
  flexContainerResponsive: {
    display: "flex",
    justifyContent: "center",
    margin: "45px 0 168px 0",
  },
  loginBox: {
    backgroundImage: Theme.colors.blueWhiteGradient,
    borderRadius: Theme.borderRadiusLarge,
    padding: "4rem 0rem",
    margin: "2rem",
    boxShadow: "0 0 16px 0 " + Theme.colors.yellow,
    justifyContent: "center",
    width: "80%", // Adjust the width as needed
    maxWidth: "600px", // Adjust the max width as needed
  },
  loginBoxResponsive: {
    backgroundImage: Theme.colors.blueWhiteGradient,
    borderRadius: Theme.borderRadiusLarge,
    padding: "4rem 0rem",
    margin: "94px 0px 2rem 0px",
    boxShadow: "0 0 16px 0 " + Theme.colors.yellow,
    justifyContent: "center",
    width: "100%",
    maxWidth: "386px",
  },
};

export default IsLoginSection;
