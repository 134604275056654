import  useRazorpay  from "react-razorpay";
import TransactionPic from "../../assets/images/default_user-img.jpg";

const useInitiateRazorpay = () => {
  const [Razorpay] = useRazorpay();

  const initiateRazorpay = (paymentDetails: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY as string,
        amount: paymentDetails.amount, // Use the amount from paymentDetails
        currency: "INR",
        name: paymentDetails.name, // Use the name from paymentDetails
        description: paymentDetails.description, // Use the description from paymentDetails
        image: TransactionPic, // Use the image from paymentDetails
        order_id: "", // This should be set to the actual order ID from your backend
        handler: function (response: any) {
          resolve(response); // Resolve the promise with the response
        },
        prefill: {
          name: paymentDetails.prefill.name, // Use the name from paymentDetails
          email: paymentDetails.prefill.email, // Use the email from paymentDetails if available
          contact:paymentDetails.prefill.contact, // Use the contact from paymentDetails if available
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response: any) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
        reject(response); // Reject the promise with the error response
      });
      
      // rzp1.on("payment.authorized", function (response: any) {
      //   console.log(response);
      // });

      rzp1.open();
    });
  };

  return initiateRazorpay;
};

export default useInitiateRazorpay;
