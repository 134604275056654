import { useEffect, useState } from 'react';
import { Theme } from '../../../Utils/Constants';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import useWindowSize from '../../Hooks/useWindowSize';

interface DatePickerProps {
  label: string;
  minTime: Date;
  maxTime: Date;
  defaultTime: Date;
  onChange: (date: Date) => void;
}

function TimeInput({
  label,
  onChange,
  minTime,
  maxTime,
  defaultTime,
}: DatePickerProps) {
  const [time, setTime] = useState<Date | null>(defaultTime);
  const windowSize = useWindowSize();

  function handleDateChange(dateValue: Date | null, _: any) {
    setTime(dateValue);
    onChange(dateValue || defaultTime);
  }

  useEffect(() => {
    setTime(defaultTime);
  }, [defaultTime]);

  return (
    <div style={windowSize <= 670 ? styles.dateBoxResponsive : styles.dateBox}>
      <TimePicker
        label={label}
        value={time}
        onChange={handleDateChange}
        timezone='UTC'
        minTime={minTime}
        timeSteps={{ hours: 1, minutes: 1 }}
        maxTime={maxTime}
        className={windowSize <= 670 ? 'date-picker-responsive' : ''}
      />
    </div>
  );
}

const styles = {
  dateBox: {
    minWidth: Theme.inputElementMinWidth,
    margin: Theme.gapTiny,
    color: Theme.colors.black70,
  },
  dateBoxResponsive: {
    width: 163,
    height: 36.9,
  },
};

export default TimeInput;
