// Toast.ts
import { toast } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'info' | 'loading';

const Toast = {
  success: (message: string) => {
    toast.success(message);
  },
  error: (message: string) => {
    toast.error(message);
  },
  info: (message: string) => {
    toast(message);
  },
  loading: (message: string) => {
    toast.loading(message);
  },
};

export default Toast;
