import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Styles, ThemeTypes } from '../../Utils/Constants';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import useAppStore from '../../store/app.store';

export const Popup = ({ maxLots }: { maxLots: number }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  const closePopup = () => {
    setIsVisible(false);
  };

  const navigateToPlans = () => {
    // setSelectedSectionIndex(3);
    localStorage.setItem('isUpgradePlanClicked', 'true');
    navigate('/profile/pricing'); // Use navigate for navigation
  };

  return (
    <div
      className='subscribe-popup-card'
      style={{
        display: isVisible ? 'block' : 'none',
        height: '100vh',
      }}
    >
      <div
        className='flex-center'
        style={{
          padding: '20px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          zIndex: 999,
          width: '25rem',
          height: '11rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className='flex-center flex-col'>
            <span>
              <ReportProblemIcon style={{ fontSize: 40, color: 'red' }} />
            </span>
            <span style={Styles.h3Text}>Alert!</span>
            <div className='flex-center flex-col'>
              <span>Your current plan accommodates only {maxLots} lot(s)</span>
              <span>Upgrade Now to unlock.</span>
              <span
                style={{
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
                className='flex-center flex-row'
              >
                {/* <Link style={{ textDecoration: 'none' }} to='/profile/pricing'>
              </Link> */}
                <a style={{ textDecoration: 'none' }} href='/profile/pricing'>
                  <button
                    onClick={navigateToPlans}
                    className='flex flex-center'
                  >
                    <UpgradeIcon />
                    Upgrade Plan
                  </button>
                </a>

                <button
                  onClick={closePopup}
                  style={{
                    color: '#2747dd',
                    backgroundColor: '#fff',
                    border: 'solid',
                  }}
                  className='flex-center '
                >
                  <CloseIcon />
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export const UpGradePlanMessagePopup = () => {
//   // const popupElement = document.createElement('div');
//   // document.body.appendChild(popupElement);

//   // ReactDOM.render(<Popup />, popupElement);
//   return (
//     <div>
//       <Popup />
//     </div>
//   );
// };

export const UpGradePlanMessagePopup = (maxLots: number) => {
  const popupElement = document.createElement('div');
  document.body.appendChild(popupElement);
  ReactDOM.render(
    <BrowserRouter>
      {' '}
      {/* Wrap the component hierarchy with BrowserRouter */}
      <Popup maxLots={maxLots} />
    </BrowserRouter>,
    popupElement
  );
  return null;
};
