export const Theme = {
  linkColor: "#5221FF",
  greyColor: "#959595",
  colors: {
    black: "#000000",
    black70: "#000000B3",
    grey100: "#252525",
    grey70: "#252525B3",
    whitishGrey: "#E5E7EB",
    whiteGrey: "#B2b2b2",
    whiteGrey70: "#B2B2B2B2",
    whiteGrey30: "#B2B2B24D",
    white75: "#FFFFFFB3",
    blueSolid: "#2747DD",
    blueSolid10: "#2747DD1A",
    blueTainted: "#5468C4",
    blueTainted15: "#5468C426",
    yellow: "#FFE484",
    orange: "#E7AF1D",
    white: "#FFFFFF",
    green: "#2ACD1C",
    red: "#F82929",
    backgroundF3: "#F3F6FF",
    backgroundEA: "#EAF3FF",
    backgroundE8: "#E8EDFC",
    blueWhiteGradient: "linear-gradient(#2747DD, #5468C4)",
  },
  fontSizes: {
    h1: "30px",
    h2: "22px",
    h3: "20px",
    p18: "18px",
    h4: "16px",
    h5: "14px",
    h6: "12px",
    p11: "11px",
  },
  fontWeight: {
    light: "200",
    regular: "400",
    medium: "500",
    semiBold: "600",
  },
  gapTiny: "0.5rem",
  gapSmall: "1rem",
  gapLarge: "2rem",
  gapXXLarge: "5rem",
  gap4: "4px",
  gap5: "5px",
  gap36: "36px",
  gap10: "10px",
  gap13: "13px",
  gap15: "15px",
  gap20: "20px",
  gap22: "22px",
  gap17: "17px",
  gap25: "25px",
  gap26: "26px",
  gap28: "28px",
  gap31: "31px",
  gap33: "33px",
  gap39: "39px",

  borderRadius: "0.3rem",
  borderRadiusLarge: "0.6rem",

  dialogWidth: "16rem",
  profileImageWidth: "87px",
  navBarWidth: "16rem",
  logoWidthNavBarGap: "3rem",
  logoWidthNavBar: "calc(100% - 6rem)",
  responsiveLogoTopNavBarGap: "20px",
  responsiveLogoBottomNavBarGap: "20px",
  responsiveNavBarHorizondalGapLeft: "12px",
  responsiveNavBarHorizondalGapRight: "12px",
  footerIconHeight: "28px",
  iconWidth: "8rem",
  inputElementMinWidth: "9rem",
  dialogHeight: "8rem",
};

export const Styles = {
  h1Text: {
    fontSize: Theme.fontSizes.h1,
    color: Theme.colors.black,
    fontWeight: Theme.fontWeight.semiBold,
    padding: "4px",
    margin: "0px",
    textAlign: "center" as const,
    textTransform: "uppercase" as const,
  },
  h1TextHomeResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center' as const,
    letterSpacing: '0.03em',
    textTransform: 'uppercase' as const,
    color: '#000000'
  },
  h1TextResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '31px',
    letterSpacing: '0.05em',
    color: '#000000'
  },
  h1TextSmallResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '31px',
    letterSpacing: '0.05em',
    color: '#000000'
  },
  h1TextUltraSmallResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '31px',
    letterSpacing: '0.05em',
    color: '#000000'
  },
  h3Text: {
    fontSize: Theme.fontSizes.h3,
    color: Theme.colors.black,
    fontWeight: Theme.fontWeight.semiBold,
    margin: "0px",
  },
  h3TextResponsive: {
    fontSize: Theme.fontSizes.p18,
    color: Theme.colors.black,
    fontWeight: Theme.fontWeight.semiBold,
    margin: "0px",
  },
  strategySectionHeaderText: {
    textAlign: "left" as const,
    fontSize: Theme.fontSizes.h4,
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.06em',
  },
  h4Text: {
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.black,
    fontWeight: Theme.fontWeight.light,
    padding: "4px",
    margin: "0px",
  },
  h4TextHomeResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1em',
    color: '#000000'
  },
  bottomBorderLine: {
    margin: "1rem 0rem",
    height: "4px",
    backgroundColor: Theme.colors.blueSolid,
    width: "6rem",
    textAlign: "center" as const,
    position: "relative" as const,
    left: "calc(50% - 3rem)",
    borderRadius: "2rem",
  },
  bottomBorderLineResponsive: {
    margin: "0rem",
    height: "4px",
    backgroundColor: Theme.colors.blueSolid,
    width: "6rem",
    textAlign: "center" as const,
    borderRadius: "2rem",
    marginTop: "17px"
  },
};

export const ThemeTypes = {
  transparentButton: {
    display: "flex",
    background: "transparent",
    border: "1px solid #00000066",
    paddingLeft: Theme.gapSmall,
    paddingRight: Theme.gapSmall,
    margin: Theme.gapSmall,
    cursor: "pointer",
    fontSize: Theme.fontSizes.h5,
    fontWeight: Theme.fontWeight.semiBold,
    color: Theme.colors.blueSolid,
    alignSelf: "center",
    alignContent: "center",
    borderRadius: Theme.borderRadius,
  },
  buttonIcon: {
    fontSize: Theme.fontSizes.h3,
    margin: "auto",
  },
  yellowButtonIcon: {
    fontSize: Theme.fontSizes.h3,
    margin: "auto 4px",
  },
  yellowButton: {
    display: "flex",
    borderRadius: Theme.borderRadius,
    background: Theme.colors.yellow,
    border: "none",
    paddingLeft: Theme.gapSmall,
    paddingRight: Theme.gapSmall,
    margin: Theme.gapTiny,
    cursor: "pointer",
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.black70,
    alignSelf: "center",
    alignContent: "center",
  },
};
