import React from 'react';
import TestResultsParser from '../../Utils/TestResultsParser';
import DailyViewContainer from './DailyViewContainer';
import MonthlyViewContainer from './MonthlyViewContainer';
import WeekDayViewContainer from './WeekDayViewContainer';
import ResultsSummaryContainer from './ResultsSummaryContainer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Theme } from '../../Utils/Constants';
import BackTestResultsParser from '../../Utils/BackTestResultParser';
import BackTestDailyViewContainer from './BackTestDailyViewContainer';
import BackTestMonthlyViewContainer from './BackTestMonthlyViewContainer';
import BackTestWeekDayViewContainer from './BackTestWeekDayViewContainer';
import useWindowSize from '../Hooks/useWindowSize';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import FilterIcon from '../Vector Components/FilterIcon';
import { MaxDropDownBacktestGraph } from '../Graphs/MaxDrawdownGraph/MaxDrawdownBacktestGraph';
import TradingCalendar from '../Graphs/YearlyPerformance/TradingCalander';
import BacktestTradingCalendar from '../Graphs/YearlyPerformance/BacktestTradingCalender';
import PLLineBacktestGraph from '../Graphs/PLLineGraph/PLLineBacktestGraph';

interface IResultsPageProps {
  results: BackTestResultsParser;
}

const ResultsPage: React.FC<IResultsPageProps> = (props) => {
  // console.log(props.results);

  const [showROIPerct, setROIPerct] = React.useState<boolean>(false);

  const windowSize = useWindowSize();

  function getMaxDrawDownContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        {/* <StrategyPerformance name={'Key Parameters'} /> */}
        <MaxDropDownBacktestGraph
          key='resultsSummaryContainer'
          results={props.results}
        />
      </div>
    );
  }

  function getDailyResultsContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <p
          style={
            windowSize <= 500
              ? styles.metricsHeaderResponsive
              : styles.metricsHeader
          }
          className='relative'
        >
          Trade Log <div className='small-dot-responsive'></div>
        </p>
        <BackTestDailyViewContainer
          key='DailyViewContainer'
          results={props.results}
        />
      </div>
    );
  }

  function getMonthlyViewContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <p
          style={
            windowSize <= 500
              ? styles.metricsHeaderResponsive
              : styles.metricsHeader
          }
          className='relative'
        >
          Month Wise Breakup <div className='small-dot-responsive'></div>
        </p>
        <BackTestMonthlyViewContainer
          key='monthlyViewContainer'
          showPerct={showROIPerct}
          margins={props.results.getMargins()}
          results={props.results.getMonthlyView()}
        />
      </div>
    );
  }

  function getWeekDayViewContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <p
          style={
            windowSize <= 500
              ? styles.metricsHeaderResponsive
              : styles.metricsHeader
          }
          className='relative'
        >
          Day Wise Breakup <div className='small-dot-responsive'></div>
        </p>
        <BackTestWeekDayViewContainer
          key='weeklyDayView'
          showPerct={showROIPerct}
          margins={props.results.getMargins()}
          results={props.results.getWeekDayView()}
        />
      </div>
    );
  }

  function getResultsSummaryContainer() {
    return (
      <div style={styles.container} className='relative'>
        <p
          style={
            windowSize <= 500
              ? styles.metricsHeaderResponsiveNoMarginBottom
              : styles.metricsHeader
          }
          className='relative'
        >
          Key Parameters <div className='small-dot-responsive'></div>
        </p>
        {windowSize <= 500 && (
          <div
            style={{
              width: '88.82px',
              position: 'absolute',
              top: 16,
              right: 10.2,
            }}
          >
            <span className='filter-span pointer'>
              {' '}
              <FilterIcon /> Filters
            </span>
          </div>
        )}
        <ResultsSummaryContainer
          key='resultsSummaryContainer'
          data={props.results.getResultsSummary()}
        />
      </div>
    );
  }

  // function handleOptionTypeChange(checked: boolean): void {
  //   setROIPerct(checked);
  // }

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <p style={styles.headerText}>
        <AssignmentIcon style={styles.buttonIconGrey} />
        Backtest Results
      </p>
      {/* <SwitchInput
        trueLabel="-"
        falseLabel="%"
        checked={showROIPerct}
        onChange={handleOptionTypeChange}
      /> */}
      {getResultsSummaryContainer()}
      {getWeekDayViewContainer()}
      {getMonthlyViewContainer()}
      {getMaxDrawDownContainer()}
      <div style={{ ...styles.Yearlycontainer, ...styles.marginTopper }}>
      <BacktestTradingCalendar  results={props.results} avgCapital={props.results.getResultsSummary().estimatedMargin} />
      </div>
      <div style={{ ...styles.container, ...styles.marginTopper }}>
      <PLLineBacktestGraph
              data={props.results.rawResults.relative_performance}
              />
      </div>
              {getDailyResultsContainer()}
    </div>
  );
};

const styles = {
  marginTopper: {
    marginTop: Theme.gapXXLarge,
  },
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginBottom: 0,
    paddingBottom: Theme.gapSmall,
    width: 'fit-content',
    fontWeight: Theme.fontWeight.semiBold,
    overflow: 'hidden',
    borderBottom: '2px solid ' + Theme.colors.whiteGrey70,
    marginTop: '20px',
  },
  metricsHeaderResponsive: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: '0.63px solid ' + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: '9.27px',
    marginBottom: '33px',
    width: 'fit-content',
  },
  metricsHeaderResponsiveNoMarginBottom: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: '0.63px solid ' + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: '9.27px',
    width: 'fit-content',
  },
  headerText: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginTop: Theme.gapLarge,
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center' as 'center',
    justifyContent: 'flex-start' as 'flex-start',
    gap: '13px',
  },
  buttonIconGrey: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h1,
    marginRight: Theme.gapTiny,
    margin: '0px',
    verticalAlign: 'middle',
  },
  container: {
    backgroundColor: Theme.colors.backgroundF3,
    padding: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
    overflow: 'scroll' as const,
    width: 'auto',
  },
  Yearlycontainer: {
    backgroundColor: Theme.colors.backgroundF3,
    padding: '1.5rem',
    borderRadius: Theme.borderRadiusLarge,
    overflow: 'scroll' as const,
    width: '100%',
  },
};

export default ResultsPage;
