import React from "react";
import { IResultsSummary } from "../../API/API.interface";
import { Theme } from "../../Utils/Constants";
import { formatNumber } from "../../API/FormatNumber";
import useWindowSize from "../Hooks/useWindowSize";

interface ResultsSummaryContainerProps {
  data: IResultsSummary;
  isAbsoluteSelected?: boolean;
}

const ResultsSummaryContainer: React.FC<ResultsSummaryContainerProps> = ({
  data,
  isAbsoluteSelected
}) => {
  const windowSize = useWindowSize();

  function InfoIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        fill="none"
        viewBox="0 0 9 9"
        style={{ marginLeft: "9px" }}
      >
        <path
          fill="#000"
          fillOpacity="0.7"
          d="M4.5 0A4.502 4.502 0 000 4.5C0 6.984 2.016 9 4.5 9S9 6.984 9 4.5 6.984 0 4.5 0zm.45 6.75h-.9v-2.7h.9v2.7zm0-3.6h-.9v-.9h.9v.9z"
        ></path>
      </svg>
    );
  }

  function getResultsBox(
    title: string,
    value: number,
    valSuffix?: string,
    isYellow?: boolean,
    format?: boolean,
    haveInfo?: boolean
  ) {
    let roundedVal = Math.round(value * 100) / 100;
    return (
      <div style={windowSize <= 500 ? (isYellow ? styles.boxContainerYellowResponsive : styles.boxContainerResponsive) : (isYellow ? styles.boxContainerYellow : styles.boxContainer)}>
        <p style={styles.boxHeader}>
          {title}

          {haveInfo ? <InfoIcon /> : ""}

          <span style={styles.boxValue}>
            {format ? formatNumber(String(roundedVal)) : roundedVal} {valSuffix}
          </span>
        </p>
      </div>
    );
  }
  //   const formatNumber = (amount: number): string => {
  //     const suffixes = ['', 'K', 'M', 'B', 'T'];
  //     const integerPartLength = Math.floor(amount).toString().replace(/,/g, '').length;
  //     const suffixNum = Math.max(0, Math.floor((integerPartLength - 1) / 3));

  //     let shortValue = parseFloat(
  //         (suffixNum !== 0
  //             ? parseFloat(amount.toString().replace(/,/g, '')) / Math.pow(1000, suffixNum)
  //             : parseFloat(amount.toString().replace(/,/g, ''))
  //         ).toPrecision(2)
  //     );

  //     if (shortValue % 1 !== 0) {
  //         shortValue = parseFloat(shortValue.toFixed(1));
  //     }

  //     return shortValue + suffixes[suffixNum];
  // };

  // console.log(typeof data.estimatedMargin)

  return (
    <div
      style={
        windowSize <= 500 ? styles.summaryPageResponsive : styles.summaryPage
      }
    >
      <div
        style={
          windowSize <= 500
            ? { ...styles.sectionBox }
            : { ...styles.sectionBox, ...styles.borderRight }
        }
      >
        <p
        className={windowSize <= 500 ? "no-wrap result-summary-responsive" : ""}
          style={
            windowSize <= 500
              ? styles.sectionHeaderResponsive
              : styles.sectionHeader
          }
        >
          <div className="section-responsive-line"><span></span></div>
          Key Metrics
          <div className="section-responsive-line"><span></span></div>
        </p>
        {getResultsBox(
          "Avg. Capital Deployed",
          data.estimatedMargin,
          "",
          true,
          true
        )}
        {getResultsBox( isAbsoluteSelected? "Total P/L %": "Total P/L", data.overallProfit,isAbsoluteSelected?"%":"" , false, true, true)}
        {getResultsBox( isAbsoluteSelected?"Profit (Days) %":"Profit% (Days)", data.winPerctInDays, isAbsoluteSelected?"%":"%", true,true)}
        {getResultsBox(isAbsoluteSelected?"Loss (Days) %":"Loss% (Days)", data.lossPerctInDays, isAbsoluteSelected?"%":"%", false, true)}
        {getResultsBox( isAbsoluteSelected?"Max Profit %":"Max Profit", data.maxDayProfit, isAbsoluteSelected?"%":"", true, true)}
        {getResultsBox( isAbsoluteSelected?"Max Loss %":"Max Loss", data.maxDayLoss, isAbsoluteSelected?"%":"", false, true)}
        {/* {getResultsBox("Lot Sizes", data.lotSizes, "", true)} */}
      </div>
      <div
        style={
          windowSize <= 500
            ? { ...styles.sectionBox }
            : { ...styles.sectionBox, ...styles.borderRight }
        }
      >
        <p
        className={windowSize <= 500 ? "no-wrap result-summary-responsive" : ""}
          style={
            windowSize <= 500
              ? styles.sectionHeaderResponsive
              : styles.sectionHeader
          }
        >
          <div className='section-responsive-line'><span></span></div>
          Aggregates
          <div className='section-responsive-line'><span></span></div>
        </p>
        {getResultsBox( isAbsoluteSelected? "Avg Monthly P&L %":"Avg Monthly P&L", data.avgMonthlyProfit, isAbsoluteSelected?"%":"", true, true)}
        {getResultsBox( isAbsoluteSelected?"Avg Yearly P&L %":"Avg Yearly P&L", data.avgYearlyProfit, isAbsoluteSelected?"%":"", false, true)}
        {getResultsBox( isAbsoluteSelected?"Avg Day P&L %":"Avg Day P&L", data.avgDayProfit, isAbsoluteSelected?"%":"", true, true, true)}
        {getResultsBox( isAbsoluteSelected?"Avg on Profit Days %":
          "Avg on Profit Days",
          data.avgProfitOnWinDays,
          isAbsoluteSelected?"%":"",
          false,
          true
        )}
        {getResultsBox( isAbsoluteSelected?"Avg on Loss Days %":
          "Avg on Loss Days",
          data.avgLossOnLossDays,
          isAbsoluteSelected?"%":"",
          true,
          true
        )}
        {getResultsBox(
          "Max Profit Streak",
          data.maxWinningStreak,
          "",
          false,
          false,
          true
        )}
        {getResultsBox( "Max Loss Streak", data.maxLosingStreak,"", true)}
      </div>
      <div
        style={
          windowSize <= 500
            ? { ...styles.borderNone }
            : { ...styles.borderNone, ...styles.sectionBox }
        }
      >
        <p
        className={windowSize <= 500 ? "no-wrap result-summary-responsive" : ""}
          style={
            windowSize <= 500
              ? styles.sectionHeaderResponsive
              : styles.sectionHeader
          }
        >
          <div className="section-responsive-line"><span></span></div>Performance Ratios
          <div className="section-responsive-line"><span></span></div>
        </p>
        {getResultsBox( "Sharpe Ratio", data.sharpeRatio, "", true, false, true)}
        {getResultsBox( isAbsoluteSelected?"Max Draw Down %":"Max Draw Down", data.maxDrawDown, isAbsoluteSelected?"%":"", false, true)}
        {getResultsBox( "MDD Days", data.maxDrawDownDays,"", true)}
        {getResultsBox( 
          "P&L To MDD Ratio",
          data.returnToMDDRatio,
          "",
          false,
          true,
          true
        )}
        {getResultsBox(
          "Recovery Days",
          data.maxDrawDaysRecoveryPeriod,
          "",
          true
        )}
        {getResultsBox( isAbsoluteSelected?"Max Peak profit %":"Max Peak profit", data.maxPeakProfit, isAbsoluteSelected?"%":"", false, true)}
        {getResultsBox( "Peak Profit / MDD", data.maxProfitByMDD,"", true,true)}
      </div>
    </div>
  );
};

const styles = {
  borderRight: {
    borderRight: "1px solid " + Theme.colors.whiteGrey70,
    marginRight: Theme.gapSmall,
  },
  borderNone: {
    border: "none",
  },
  summaryPage: {
    display: "flex",
    justifyContent: "spaceAround",
  },
  summaryPageResponsive: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "spaceAround",
  },
  sectionHeader: {
    padding: 0,
    paddingTop: Theme.gapSmall,
    paddingBottom: Theme.gapSmall,
    fontSize: Theme.fontSizes.h3,
    margin: 0,
    color: Theme.colors.black70,
    fontWeight: Theme.fontWeight.semiBold,
  },
  sectionHeaderResponsive: {
    padding: 0,
    paddingTop: Theme.gapSmall,
    paddingBottom: Theme.gap31,
    margin: 0,
    color: Theme.colors.black70,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.06em",
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: 16
  },
  boxHeader: {
    padding: 0,
    margin: 0,
    width: "100%",
    height: "auto",
    fontSize: Theme.fontSizes.h5,
    textAlign: "left" as "left",
  },
  boxValue: {
    padding: 0,
    margin: 0,
    float: "right" as "right",
    fontWeight: Theme.fontWeight.semiBold,
    fontSize: Theme.fontSizes.h4,
  },
  sectionBox: {
    marginTop: Theme.gapSmall,
    marginBottom: Theme.gapSmall,
    width: "100%",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    minWidth: "256px",
    padding: Theme.gapSmall,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
  },
  boxContainerYellow: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    backgroundColor: Theme.colors.yellow,
    minWidth: "256px",
    padding: Theme.gapSmall,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
  },
  boxContainerResponsive: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    minWidth: "256px",
    padding: Theme.gapSmall,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: Theme.borderRadiusLarge,
  },
  boxContainerYellowResponsive: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    backgroundColor: Theme.colors.yellow,
    minWidth: "256px",
    padding: Theme.gapSmall,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: Theme.borderRadiusLarge,
  },
};

export default ResultsSummaryContainer;
