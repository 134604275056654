import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Styles, ThemeTypes } from '../../Utils/Constants';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import useAppStore from '../../store/app.store';

export const Popup = ({ maxLots, onContinueClicked  }: { maxLots: number,onContinueClicked: () => void }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();


  const onContinue = () => {
      onContinueClicked();
      setIsVisible(false);
  };

  const navigateToPlans = () => {
    // setSelectedSectionIndex(3);
    localStorage.setItem('isUpgradePlanClicked', 'true');
    navigate('/profile/pricing'); // Use navigate for navigation
  };

  return (
    <div
      className='subscribe-popup-card'
      style={{
        display: isVisible ? 'block' : 'none',
        height: '100vh',
      }}
    >
      <div
        className='flex-center '
        style={{
          padding: '30px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          zIndex: 999,
          width: '25rem',
          height: '11rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className='flex-center flex-col'>
            <span>
              <ReportProblemIcon style={{ fontSize: 40, color: 'red' }} />
            </span>
            <span style={Styles.h3Text}>Alert!</span>
            <div className='flex-center flex-col'>
              <span style={{ textAlign: 'center' }}>Running this will use {maxLots+1} of your 10 monthly Free Backtests.
               Want to proceed? You can upgrade for unlimited backtests.</span>
              <span
                style={{
                  justifyContent: 'space-evenly',
                  width: '100%',
                  padding: '8px',
                }}
                className='flex-center flex-row'
              >
                {/* <Link style={{ textDecoration: 'none' }} to='/profile/pricing'>
              </Link> */}
                
                  <button
                    onClick={onContinue}
                    className='flex flex-center'
                  >
                    {/* <UpgradeIcon /> */}
                    Continue
                  </button>
                
                  <a style={{ textDecoration: 'none' }} href='/profile/pricing'>
                <button
                  onClick={navigateToPlans}
                //   style={{
                //     color: '#2747dd',
                //     backgroundColor: '#fff',
                //     border: 'solid',
                //   }}
                className='flex flex-center'
                >
                  <UpgradeIcon />
                  Upgrade
                </button>
                </a>

              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpGradeBacktestPlanBeforeMessagePopup = (maxLots: number, onContinueClicked: (() => void) | undefined) => {
    return new Promise((resolve) => {
        const handleContinueClick = () => {
            resolve(true); // Resolve the promise when "Continue" is clicked
            if (onContinueClicked) {
                onContinueClicked(); // Call the callback if it exists
            }
        };

        const popupElement = document.createElement('div');
        document.body.appendChild(popupElement);
        ReactDOM.render(
            <BrowserRouter>
                <Popup maxLots={maxLots} onContinueClicked={handleContinueClick} />
            </BrowserRouter>,
            popupElement
        );
    });
  };
  
