import React, { useEffect, useState } from 'react';
import { Theme } from '../../Utils/Constants';
import DailyViewPerDayBox from './DailyViewPerDayBox';
import TestResultsParser from '../../Utils/TestResultsParser';
import Pagination from '@mui/material/Pagination';
import FilterIcon from '../Vector Components/FilterIcon';
import DownloadIcon from '../Vector Components/DownloadIcon';
import { compareDesc, parseISO } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useWindowSize from '../Hooks/useWindowSize';
import DownloadIconResponsive from '../Vector Components/DownloadIconResponsive';
interface Props {
  results: TestResultsParser;
}

const DailyViewContainer: React.FC<Props> = (props) => {
  const [page, setPage] = useState(1);

  const windowSize = useWindowSize();

  function handleDownload() {
    const tableElement = document.getElementById('table-to-download');

    if (!tableElement) {
      console.error('Table element not found.');
      return;
    }

    html2canvas(tableElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgHeight = (canvas.height * 208) / canvas.width; // Adjust width and height as needed
      pdf.addImage(imgData, 'PNG', 0, 0, 208, imgHeight); // Adjust width and height as needed
      pdf.save('daily_view_data.pdf');
    });
  }

  function getNumberOfResult() {
    const dailyAlgoResults =
      props.results?.rawResults.response_data.user_daily_results;

    if (!dailyAlgoResults) {
      return 0; // Return 0 if dailyAlgoResults is not defined or empty
    }

    let count = 0;

    // Iterate over each key (date) in dailyAlgoResults and increment count
    Object.keys(dailyAlgoResults).forEach((item) => {
      count = count + dailyAlgoResults[item].strategyLegs.length;
    });

    return count;
  }

  function getNumberOfResultUpto(page: number) {
    const dailyAlgoResults =
      props.results?.rawResults.response_data.dailyAlgoResults;

    if (!dailyAlgoResults) {
      return 0; // Return 0 if dailyAlgoResults is not defined or empty
    }

    let count = 0;
    let iteration = 0;

    // Iterate over each key (date) in dailyAlgoResults and increment count
    Object.keys(dailyAlgoResults).forEach((item) => {
      if (iteration >= page) {
        return; // Exit the forEach loop if iteration exceeds page
      }
      count = count + dailyAlgoResults[item].strategyLegs.length;
      iteration++; // Increment the counter
    });

    return count;
  }

  function getNumberOfPages() {
    const dailyAlgoResults =
      props.results?.rawResults.response_data.dailyAlgoResults;
    return Object.keys(dailyAlgoResults).length;
  }

  function getSections() {
    const from = page - 1;
    const to = page;

    // Sort keys of dailyAlgoResults based on date
    const sortedKeys = Object.keys(
      props.results?.rawResults.response_data.dailyAlgoResults || {}
    ).sort((a, b) => compareDesc(parseISO(a), parseISO(b)));

    // Slice the sorted keys based on pagination
    const keysToDisplay = sortedKeys.slice(from, to);

    return keysToDisplay.map((key) => {
      return (
        <DailyViewPerDayBox
          key={'container' + key}
          data={props.results.getDailyViewDataFor(key)}
        />
      );
    });
  }

  function getStrategyLegs() {
    return props.results.getStrategyLegs().map((leg) => {
      return (
        <th
          style={
            windowSize <= 500 ? styles.headerCellResponsive : styles.headerCell
          }
          key={leg.sequence}
        >
          {leg.optionType} {leg.actionType} SL={leg.slPerct}% TP={leg.tpPerct}%
        </th>
      );
    });
  }

  function EditIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='10'
        fill='none'
        viewBox='0 0 10 10'
      >
        <path
          fill='#000'
          fillOpacity='0.7'
          d='M0 7.917V10h2.083l6.144-6.144-2.083-2.084L0 7.917zm1.622.972h-.511v-.511l5.033-5.034.51.512-5.032 5.033zM9.838 1.46l-1.3-1.3A.545.545 0 008.143 0a.546.546 0 00-.389.161L6.738 1.178 8.82 3.26l1.017-1.017a.553.553 0 000-.783z'
        ></path>
      </svg>
    );
  }

  return (
    <>
      <div style={styles.tradeLogTopbar} className='border-box'>
        <div style={{ visibility:"hidden" }}>
          <div
            style={
              windowSize <= 500
                ? styles.tradeLogTopbarFirstResponsive
                : styles.tradeLogTopbarFirst
            }
          >
            <EditIcon /> Edit Columns
          </div>
        </div>
        <div style={styles.tradeLogTopbarSecond}>
          <div style={{ visibility:"hidden" }}>
            <div
              style={
                windowSize <= 500
                  ? styles.tradeLogTopbarSecondItemsResponsive
                  : styles.tradeLogTopbarSecondItems
              }
            >
              <FilterIcon /> Filters
            </div>
          </div>
          <div
            onClick={handleDownload}
            style={
              windowSize <= 500
                ? styles.tradeLogTopbarSecondItemsResponsive
                : styles.tradeLogTopbarSecondItems
            }
          >
            {windowSize <= 500 ? <DownloadIconResponsive /> : <DownloadIcon />}{' '}
            Download
          </div>
          {/* <button onClick={handleDownload}>Download</button> */}
        </div>
      </div>
      <table id='table-to-download' style={styles.tableStyle}>
        <thead>
          <tr key='dailyViewHeaderRow'>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='date'
            >
              Date
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='expiryornonexpiry'
            >
              Expiry/Non Expiry
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='indexcolumn'
            >
              Index
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='positions'
            >
              Positions
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='entryprice'
            >
              Entry Price
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='exitprice'
            >
              Exit Price
            </th>

            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='entrytime'
            >
              Entry Time
            </th>
            <th
              style={
                windowSize <= 500
                  ? styles.headerCellResponsive
                  : styles.headerCell
              }
              key='exittime'
            >
              Exit Time
            </th>

            {/* {getStrategyLegs()} */}
          </tr>
        </thead>
        <tbody>{getSections()}</tbody>
      </table>
      <div className='border-box trade-log-custom' style={styles.paginationContainer}>
        <div style={{ ...styles.paginationText }}>
          {getNumberOfResultUpto(page - 1)} - {getNumberOfResultUpto(page)} of{' '}
          {getNumberOfResult()} Results

          {windowSize <= 500 &&
            <div
            style={{
              ...styles.paginationText,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '25px',
            }}
          >
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (page !== 1) {
                  setPage(page - 1);
                }
              }}
            >
              {' '}
              {'< Previous'}{' '}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (page !== getNumberOfPages()) {
                  setPage(page + 1);
                }
              }}
            >
              {' '}
              {'Next >'}{' '}
            </div>
          </div>
          }
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '46px',
          }}
        >
          <div style={{ ...styles.paginationText }}>
            {page} of {getNumberOfPages()} pages
          </div>
          {windowSize > 500 &&
            <div
            style={{
              ...styles.paginationText,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '25px',
            }}
          >
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (page !== 1) {
                  setPage(page - 1);
                }
              }}
            >
              {' '}
              {'< Previous'}{' '}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (page !== getNumberOfPages()) {
                  setPage(page + 1);
                }
              }}
            >
              {' '}
              {'Next >'}{' '}
            </div>
          </div>
          }
        </div>
      </div>
    </>
  );
};

const styles = {
  tradeLogTopbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '26px',
    marginTop: '32px',
  },
  tradeLogTopbarFirst: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
    padding: Theme.gap10,
    border: '1px solid #B2B2B2B2',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  tradeLogTopbarFirstResponsive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
    padding: Theme.gap10,
    border: '1px solid #B2B2B2B2',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  tradeLogTopbarSecond: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '13px',
  },
  tradeLogTopbarSecondItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
    padding: Theme.gap10,
    border: '1px solid #B2B2B2B2',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  tradeLogTopbarSecondItemsResponsive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
    padding: Theme.gap4,
    border: '1px solid #B2B2B2B2',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '41px',
    paddingLeft: Theme.gap10,
    paddingRight: Theme.gap10,
  },
  paginationText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '32px',
    color: '#000000B2',
  },
  tableStyle: {
    borderSpacing: 0,
    width: '100%',
  },
  headerCell: {
    padding: Theme.gapSmall,
    fontWeight: 'normal',
    borderBottom: '1px solid ' + Theme.colors.whiteGrey70,
    margin: 0,
  },
  headerCellResponsive: {
    padding: Theme.gapSmall,
    borderBottom: '1px solid ' + Theme.colors.whiteGrey70,
    margin: 0,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12.1905px',
    lineHeight: '28px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
};

export default DailyViewContainer;
