import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { Theme } from "../../Utils/Constants";

const MyComponent: React.FC = () => {
  return (
    <Dialog open={true} style={{ borderRadius: Theme.borderRadius }} className="backtesting-dialog">
      <DialogContent style={styles.dialog} className="backtesting-dialogcontent">
        <div style={styles.container}>
          <h2>Loading Backtest Results</h2>
          <img style={styles.loading} src="./images/spinner.svg" alt="logo" />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  container: {
    width: "100%",
    display: 'flex',
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    width: "71.49px",
    height: "71.22px"
  },
  dialog: {
    borderRadius: Theme.borderRadius,
  },
};

export default MyComponent;
