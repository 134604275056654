import React, { useEffect, useMemo, useState, useRef } from 'react';
import { AuthModule } from '../API';
import Header, { CurrentPage } from './Header/Header';
import { Theme } from '../Utils/Constants';
import Footer from './Footer';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import StrategyPL from './Profile/StrategyPL';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StrategyPnL from './Profile/StrategyPnl';
import { getSubscriptionDetails } from '../API/LiveTodayAPI';
import useOutSideClick from '../hooks/useOutSide';
import { DateRangePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { decodeJwtToken } from '../API/DecodeJWTFunction';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import useWindowSize from './Hooks/useWindowSize';
import Toast from '../Utils/Toast';

const StrategyPnLPage: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState('All');
  const [isStrategyDropDownOpen, setIsStrategyDropDownOpen] = useState(false);
  const [isInstrumentDropDownOpen, setIsInstrumentDropDownOpen] =
    useState(false);
  const containerRef = useRef(null);
  const strategyContainerRef = useRef(null);
  const instrumentContainerRef = useRef(null);
  const [strategyOptions, setStrategyOptions] = useState<string[]>([]);
  const [strategyData, setStrategyData] = useState<any[]>([]);

  const [selectedInstrument, setSelectedInstrument] =
    useState('All');
  const [strategyId, setStrategyId] = useState<string>('All');
  const [payload, setPayload] = useState<any>(null);

  const instrumentOptions = [
    'All',
    'MidcapNifty',
    'FinNifty',
    'Banknifty',
    'Nifty50',
    'Sensex',
  ];
  const [startDate, setStartDate] = useState<Moment | null>(moment()); // Specify the type as Moment or null

  const [endDate, setEndDate] = useState<Moment | null>(
    moment().add(7, 'days')
  ); // Specify the type as Moment or null
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null); // Declare focusedInput state
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    //console.log('dates', startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD'));
  };

  var userId: string = '';
  const decodedToken = decodeJwtToken();
  userId = String(decodedToken?.user_id ?? '');

  interface filters {
    strategy_id: string;
    from_date: any;
    to_date: any;
    instrument: string;
  }
  interface updateData {
    userId: string;
    filters: filters;
  }

  const isOutsideRange = (day: Moment | null) => {
    return false; // Allow selection of all dates, including future dates
  };

  const fetchSubsriptionDetailsData = async () => {
    try {
      const details = await getSubscriptionDetails();
      const responseDataArray = details.Response_data;
      const filteredStrategyArray = responseDataArray.filter(
        (item) =>
          item.strategy_id.trim() !== 'undefined' &&
          item.strategy_id.trim() !== ''
      );

      const filterDataWithID = filteredStrategyArray.filter(
        (item) =>
          item.strategy_id.trim() !== '' && item.strategy_id.trim() !== ''
      );
      setStrategyData(filterDataWithID);
      const strategyNames = filteredStrategyArray.map(
        (strategy) => strategy.strategy_name
      );
      setStrategyOptions(strategyNames);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchSubsriptionDetailsData();
  }, []);

  const getUpdatedData = () => {
    let payload: updateData = {
      userId: '',
      filters: {
        strategy_id: '',
        from_date: '',
        to_date: '',
        instrument: '',
      },
    };
    payload.userId = userId;
    payload.filters.from_date = startDate?.format('YYYY-MM-DD');
    payload.filters.to_date = endDate?.format('YYYY-MM-DD');
    if (selectedInstrument === 'Nifty50') {
      payload.filters.instrument = 'Nifty';
    } else {
      payload.filters.instrument = selectedInstrument;
    }
    payload.filters.strategy_id = strategyId;
    if (
      payload.filters.strategy_id !== '' &&
      payload.filters.instrument !== 'Select Instrument' &&
      payload.filters.from_date !== '' &&
      payload.filters.to_date !== ''
    ) {
      setPayload(payload);
    } else {
      Toast.error('Please select all the filters');
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated);
        if (!isAuthenticated) {
          navigate('/home');
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
      });
  }, [navigate]);

  const handleStrategyDropDown = () => {
    setIsStrategyDropDownOpen(!isStrategyDropDownOpen);
  };
  const handleInstrumentDropDown = () => {
    setIsInstrumentDropDownOpen(!isInstrumentDropDownOpen);
  };
  const handleSelectedStrategy = (option: any) => {
    setStrategyId(option.strategy_id);
    setSelectedStrategy(option.strategy_name);
    handleStrategyDropDown();
  };
  const handleSelectedInstrument = (option: string) => {
    setSelectedInstrument(option);
    handleInstrumentDropDown();
  };

  useOutSideClick(strategyContainerRef, () => {
    setIsStrategyDropDownOpen(false);
  });

  useOutSideClick(instrumentContainerRef, () => {
    setIsInstrumentDropDownOpen(false);
  });
  function CustomArrow() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='20'
        fill='none'
        viewBox='0 0 21 20'
      >
        <path
          fill='#fff'
          d='M8.894 1.626l1-1a1.077 1.077 0 011.528 0l8.762 8.757a1.077 1.077 0 010 1.528l-8.762 8.762a1.077 1.077 0 01-1.528 0l-1-1a1.083 1.083 0 01.018-1.547l5.431-5.174H1.39A1.08 1.08 0 01.308 10.87V9.428c0-.6.482-1.082 1.081-1.082h12.954L8.912 3.172a1.075 1.075 0 01-.018-1.546z'
        ></path>
      </svg>
    );
  }
  const updateRequestBody = (newRequestBody: any) => { };
  const [isUpdated, setIsUpdated] = useState(false);

  const windowSize = useWindowSize();

  return (
    <>
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div style={styles.container}>
        {windowSize > 800 && (
          <div style={styles.navBar}>
            <NavBar
              updateRequestBody={updateRequestBody}
              isUpdated={isUpdated}
            />
          </div>
        )}

        <div style={{ width: '100%' }} className='str-p-l-container'>
          <div style={styles.fullPageContainer} className='border-box'>
            <div className='str-p-l-live-today-heading'>
              <span
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '22px',
                  fontWeight: 600,
                  lineHeight: '33px',
                  letterSpacing: '0.03em',
                  textAlign: 'left',
                }}
              >
                Strategy P&L
              </span>
            </div>
            <div
              style={styles.selectStrategyContainer}
              className='border-box str-p-l-flex-top StrategyPLPickerResponsive'
            >
              <div
                style={{
                  ...styles.selectStrategy,
                  width: '203.65px',
                  minWidth: '203.65px',
                }}
                ref={strategyContainerRef}
              >
                <label style={styles.label}>Strategy</label>
                <span
                  style={{ ...styles.span, padding: '0px 17.65px 0px 25px' }}
                  onClick={handleStrategyDropDown}
                >
                  {selectedStrategy}
                  {isStrategyDropDownOpen ? (
                    <>
                      <KeyboardArrowUpIcon />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownIcon />
                    </>
                  )}
                </span>
                {isStrategyDropDownOpen && (
                  <div className='sortby-options'>
                    {/* Static option */}
                    <button
                      className='sort-option'
                      style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                      onClick={() => handleSelectedStrategy({ strategy_name: 'All', strategy_id: 'all' })}
                    >
                      All
                    </button>
                    {strategyData.map((option) => (
                      <button
                        key={option.strategy_id}
                        className='sort-option'
                        style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                        onClick={() => handleSelectedStrategy(option)}
                      >
                        {option.strategy_name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div
                style={{
                  ...styles.selectStrategy,
                  width: '220.65px',
                  minWidth: '220.65px',
                }}
                ref={instrumentContainerRef}
              >
                <label style={styles.label}>Instrument</label>
                <span
                  style={{ ...styles.span, padding: '0px 22.65px 0px 20px' }}
                  onClick={handleInstrumentDropDown}
                >
                  {selectedInstrument}
                  {isInstrumentDropDownOpen ? (
                    <>
                      <KeyboardArrowUpIcon />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownIcon />
                    </>
                  )}
                </span>
                {isInstrumentDropDownOpen && (
                  <div className='sortby-options'>
                    {instrumentOptions.map((option) => (
                      <button
                        key={option}
                        className='sort-option'
                        style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                        onClick={() => handleSelectedInstrument(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{ ...styles.selectStrategy, minWidth: '290.8px' }}
                  className='date-range-picker-strategy-pnl'
                >
                  <label style={styles.label}>Date Range</label>
                  <DateRangePicker
                    startDate={startDate}
                    startDateId='startDateId'
                    endDate={endDate}
                    endDateId='endDateId'
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    displayFormat='DD MMM YYYY'
                    isOutsideRange={isOutsideRange}
                  />
                </div>
              </div>

              <div style={{ ...styles.selectStrategy }}>
                <div onClick={getUpdatedData} style={styles.iconCircle}>
                  <CustomArrow />
                </div>
              </div>
            </div>

            <hr
              style={{
                marginTop: 32,
                marginBottom: 32,
                borderColor: 'rgba(178, 178, 178, 0.3)',
              }}
            ></hr>

            <StrategyPnL payload={payload} />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex' as const,
    overflow: 'hidden',
  },
  navBar: {
    minWidth: Theme.navBarWidth,
    width: Theme.navBarWidth,
  },
  fullPageContainer: {
    padding: Theme.gapSmall,
    width: '100%',
    height: '100%',
    paddingLeft: '28px',
  },

  dateFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
  },

  selectStrategyContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '100px',
    backgroundColor: '#F3F6FF',
    paddingTop: '26px',
    paddingBottom: '29px',
  },
  selectStrategy: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
  },

  label: {
    position: 'absolute' as const,
    top: '-8px',
    fontSize: '12px',
    left: '30%',
    transform: 'translateX(-50%)',
    width: 'auto',
    backgroundColor: '#F3F6FF',
    zIndex: 1,
  },
  span: {
    display: 'flex',
    minWidth: '100%',
    textDecoration: 'none',
    border: 'solid 0.5px #B2B2B2B2',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
  },
  iconCircle: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2747DD',
    borderRadius: '50%',
    cursor: 'pointer',
  },
};

export default StrategyPnLPage;
