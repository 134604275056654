import { create } from 'zustand';

type AppManagementStore = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isPricingClicked: boolean;
  isLogoClicked: boolean;
  profileName: string | null;
  profileImage: string | null;
  selectedSectionIndex: number | null;
  isSubscribed: boolean;
  isFaqButtonClicked: boolean;
  fAQs: boolean;
  userLevel: string | null;
  starRating: number | null;
  userBacktestData: number | null;
  totalPnlValue: number | null;
  monthlyPnlValue: number | null;
  isSignUpClickedFromHeader: boolean;
  averageCAGR: number;
  averageMinCapitalRequired: number;
  isStartBacktestClicked: boolean;
  isSubscribeNowClicked: boolean;
  subscribeNowURL: string;
  isExplorePlanClicked: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setSubscribeNowURL: (subscribeNiwURL: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setProfileName: (profileName: string) => void;
  setProfileImage: (profileImage: string) => void;
  setSelectedSectionIndex: (selectedSectionIndex: number) => void;
  setIsPricingClicked: (isPricingClicked: boolean) => void;
  setIsLogoClicked: (isLogoClicked: boolean) => void;
  setIsSubscribed: (isSubscribed: boolean) => void;
  setIsFaqButtonClicked: (isFaqButtonClicked: boolean) => void;
  setFAQs: (fAQs: boolean) => void;
  setUserLevel: (userLevel: string) => void;
  setStarRating: (starRating: number) => void;
  setUserBacktestData: (userBacktestData: number) => void;
  setTotalPnlValue: (totalPnlValue: number) => void;
  setMonthlyPnlValue: (monthlyPnlValue: number) => void;
  setIsSignUpClickedFromHeader: (isSignUpClickedFromHeader: boolean) => void;
  setAverageCAGR: (averageCAGR: number) => void;
  setAverageMinCapitalRequired: (averageMinCapitalRequired: number) => void;
  setIsStartBacktestClicked: (isStartBacktestClicked: boolean) => void;
  setIsSubscribeNowClicked: (isSubscribeNowClicked: boolean) => void;
  setIsExplorePlanClicked: (isExplorePlanClicked: boolean) => void;

};

const useAppStore = create<AppManagementStore>((set) => ({
  isAuthenticated: false,
  isLoading: true,
  profileName: null,
  profileImage: null,
  selectedSectionIndex: 0,
  isPricingClicked: false,
  isLogoClicked: false,
  isSubscribed: false,
  isFaqButtonClicked: false,
  fAQs: false,
  userLevel: null,
  starRating: 0,
  userBacktestData: 0,
  totalPnlValue: 0,
  monthlyPnlValue: 0,
  averageCAGR: 0,
  averageMinCapitalRequired: 0,
  isStartBacktestClicked: false,
  isSignUpClickedFromHeader: false,
  isSubscribeNowClicked: false,
  isExplorePlanClicked: false,
  subscribeNowURL: '',
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
  setIsStartBacktestClicked(isStartBacktestClicked) {
    set({ isStartBacktestClicked });
  },
  setSubscribeNowURL(subscribeNowURL) {
    set({ subscribeNowURL });
  },
  setIsSubscribeNowClicked(isSubscribeNowClicked) {
    set({ isSubscribeNowClicked });
  },
  setIsExplorePlanClicked(isExplorePlanClicked) {
    set({ isExplorePlanClicked });
  },
  setIsLoading: (isLoading) => set({ isLoading }),
  setProfileName: (profileName) => set({ profileName }),
  setProfileImage: (profileImage) => set({ profileImage }),
  setIsPricingClicked: (isPricingClicked) => set({ isPricingClicked }),
  setIsLogoClicked: (isLogoClicked) => set({ isLogoClicked }),
  setIsSubscribed: (isSubscribed) => set({ isSubscribed }),
  setSelectedSectionIndex: (selectedSectionIndex) =>
    set({ selectedSectionIndex }),
  setIsFaqButtonClicked: (isFaqButtonClicked) => set({ isFaqButtonClicked }),
  setFAQs: (fAQs) => set({ fAQs }),
  setUserLevel: (userLevel) => set({ userLevel }),
  setStarRating: (starRating) => set({ starRating }),
  setUserBacktestData: (userBacktestData) => set({ userBacktestData }),
  setTotalPnlValue: (totalPnlValue) => set({ totalPnlValue }),
  setMonthlyPnlValue: (monthlyPnlValue) => set({ monthlyPnlValue }),
  setIsSignUpClickedFromHeader: (isSignUpClickedFromHeader) =>
    set({ isSignUpClickedFromHeader }),
  setAverageCAGR: (averageCAGR) => set({ averageCAGR }),
  setAverageMinCapitalRequired: (averageMinCapitalRequired) =>
    set({ averageMinCapitalRequired }),
}));

export default useAppStore;
