import React, { useEffect, useState } from 'react';
import { Styles, Theme } from '../../Utils/Constants';
import { Switch } from '@mui/joy';
import { CheckCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import leftArrowImg from '../../assets/homepageimages/leftarrowimg.png';
import rightArrowImg from '../../assets/homepageimages/rightarrowimg.png';
import getStrategyList from '../AIStrategies/AIstrategiesdata';
import { fetchUserPlanPricing } from '../../API/ProfileAPI';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import useAppStore from '../../store/app.store';
const PricingPlans: React.FC = () => {
  const [isBacktest, setIsBacktest] = React.useState(false);
  const [isMonthly, setIsMonthly] = React.useState(true);
  const navigate = useNavigate();
  const [finalAmtWithGST, setFinalAmtWithGST] = useState(999);
  const [finalAmtWithoutGST, setFinalAmtWithoutGST] = useState(999);
  const [AllAIStrategiesdata, setAllAIStrategiesdata] = useState<any>([]);
  const [userPnL, setUserPnL] = useState<number>(0);
  const strings = {
    backtestDesc: 'Backtest your strategies with historical data',
    strategyDesc: 'Subscribe to deploy AI strategies in your account',
  };
  const [isSliderValueChanged, setIsSliderValueChanged] = useState(false);
  // console.log(isMonthly);
  const [sliderValue, setSliderValue] = useState<number>(1);
  const [sliderState, setSliderState] = useState<number>(1);
  const { averageCAGR, averageMinCapitalRequired } = useAppStore();
  const {setIsExplorePlanClicked} = useAppStore();

  const redirectToLoginSignup = () => {
    setIsExplorePlanClicked(true);
    navigate('/login');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0); // Scroll to the top of the page directly
  };
  const strategyPlans = [
    {
      advantage: true,
      text: 'Access to All AI Strategies',
    },
    {
      advantage: true,
      text: 'One-Click Strategy Subscription',
    },
    {
      advantage: true,
      text: 'Live P&L, Manage Strategies',
    },
    {
      advantage: true,
      text: 'Your Strategy P&L Console',
    },
    {
      advantage: true,
      text: '20+ Performance Ratios',
    },
    {
      advantage: true,
      text: '6+ Comparison Charts',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: true,
      text: 'Trade Level Deep-dive',
    },
  ];
  const freemiums = [
    {
      advantage: true,
      text: 'Save & Download Backtest      ',
    },
    {
      advantage: true,
      text: '20+ Performance Ratios      ',
    },
    {
      advantage: true,
      text: '6+ Comparison Charts      ',
    },
    {
      advantage: true,
      text: 'Trade Level Deep-dive',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: false,
      text: '10 Free Monthly Backtests      ',
    },
    {
      advantage: false,
      text: 'Only BankNifty',
    },
    {
      advantage: false,
      text: ' Last 6 Months Data Only      ',
    },
    {
      advantage: false,
      text: ' Slow Backtest, 5min    ',
    },
  ];
  const premiumsAdvantage = [
    {
      advantage: true,
      text: 'Unlimited Backtests      ',
    },
    {
      advantage: true,
      text: 'All Instruments    ',
    },
    {
      advantage: true,
      text: '6+ Years Data      ',
    },
    {
      advantage: true,
      text: 'Fast Backtest < 10sec',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: true,
      text: 'Everything in Freemium    ',
    },
  ];

  const getPricingPlansSwitcher = () => {
    return (
      <div style={styles.buttonSwitcher}>
        <p style={styles.hintText}>{strings.strategyDesc}</p>
        <img style={styles.arrowLeft} src={leftArrowImg} alt='' />
        <div style={styles.buttonBox}>
          <button
            style={!isBacktest ? { margin: 0 } : styles.selectedButton}
            onClick={() => setIsBacktest(false)}
          >
            Strategy Plans
          </button>
          <button
            style={
              isBacktest
                ? {
                    margin: 0,
                  }
                : styles.selectedButton
            }
            onClick={() => setIsBacktest(true)}
          >
            BackTest Plans
          </button>
        </div>
        <img style={styles.arrowRight} src={rightArrowImg} alt='' />
        <p style={styles.hintText}>{strings.backtestDesc}</p>
      </div>
    );
  };

  const getPayFrequencySwitcher = () => {
    return isBacktest ? (
      <>
        <div style={styles.frequencyBox}>
          <p style={styles.freqText}>Bill Monthly</p>
          <Switch
            size='lg'
            onChange={() => setIsMonthly(!isMonthly)}
            checked={!isMonthly}
            slotProps={{
              track: {
                sx: {
                  justifyContent: 'space-around',
                  backgroundColor: Theme.colors.blueSolid,
                  color: Theme.colors.yellow,
                },
              },
            }}
            sx={{
              '--Switch-thumbSize': '24px',
              '--Switch-trackWidth': '64px',
              '--Switch-trackHeight': '32px',
            }}
          />
          <p style={styles.freqText}>
            Bill Annually{' '}
            <span style={{ color: Theme.colors.blueSolid }}>( Save 40% )</span>
          </p>
        </div>
      </>
    ) : (
      <></>
    );
  };

  const getAdvantagesList = (advantages: any) => {
    return advantages.map(
      (freemium: { advantage: any; text: string }, index: number) => {
        return (
          <div key={index} style={styles.advatages}>
            <CheckCircle
              style={{
                color: freemium.advantage
                  ? Theme.colors.blueSolid
                  : Theme.colors.red,
                marginRight: 10,
                marginLeft: 12,
              }}
            />
            <p style={{ margin: 5 }}>{freemium.text}</p>
          </div>
        );
      }
    );
  };

  const getFreeBox = () => {
    return (
      <>
        <p style={Styles.h1Text}>Free</p>
        <p>No Fees, Just Benefits</p>
        <p style={Styles.h1Text}>₹0/Month</p>
        <button onClick={redirectToLoginSignup} style={styles.yellowButton}>
          Claim Now!
        </button>
        <div style={{ ...Styles.bottomBorderLine, ...styles.divider }}></div>
        <div style={{ textAlign: 'start', padding: '0 24px' }}>
          {getAdvantagesList(freemiums)}
        </div>
        <div style={styles.claimBox}>
          <p style={{ padding: 0, textAlign: 'center', fontSize: '15px' }}>
            Claim Your Free Trial Today.
          </p>
          <button
            onClick={redirectToLoginSignup}
            style={{
              ...styles.yellowButton,
              padding: '0',
              width: '110px',
              fontSize: '15px',
              height: '50px',
            }}
          >
            Claim Now!
          </button>
        </div>
      </>
    );
  };

  const getPremiumBox = () => {
    return (
      <>
        <p style={Styles.h1Text}>Premium</p>
        <p> {isMonthly ? 'Your Ideal Plan' : 'Save 40% on Yearly'} </p>
        <p style={Styles.h1Text}>{isMonthly ? '₹999/Month' : '₹599/month'}</p>
        {/* <p>Your Ideal Plan</p>
        <p style={Styles.h1Text}>{isMonthly ? '₹999/Month' : '₹11988/Year'}</p> */}
        <p>(excl. 18% GST)</p>
        <div style={{ ...Styles.bottomBorderLine, ...styles.divider }}></div>
        <div style={{ textAlign: 'start', padding: '0 24px' }}>
          {getAdvantagesList(premiumsAdvantage)}
        </div>
        <button onClick={redirectToLoginSignup} style={styles.yellowButton}>
          Buy Now!
        </button>
      </>
    );
  };

  const getStrategyPlans = () => {
    return (
      <div style={styles.expandingContainerStrategy}>
        <div style={styles.buyStrategyPlans}>
          <div>
            <h2 style={styles.strategyText}>
              Pay as per your Investment Capital
            </h2>
            <p>It's free for first Lot</p>
          </div>
          <div style={styles.rangeDiv}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column' as const,
                justifyContent: 'center' as const,
              }}
            >
              <div
                style={{
                  margin: 0,
                  backgroundColor: '#2747DD',
                  color: 'white',
                  padding: '0px 8px',
                  borderRadius: '6px',
                  width: 'fit-content',
                  height: 'fit-content',
                  marginBottom: '-10px',
                  marginLeft: '10px',
                }}
              >
                {sliderValue >= 1 ? (
                  // <span>&#8377; {finalAmtWithGST}/month</span>
                  <span>
                    {' '}
                    {sliderValue} {sliderValue > 1 ? 'lots' : 'lot'}{' '}
                  </span>
                ) : (
                  'Free'
                )}
              </div>
              <input
                type='range'
                min='1'
                max='100'
                value={sliderValue}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={styles.rangeDivText}>
              <h2 style={styles.strategyText}>Total</h2>
              <h2 style={styles.strategyText}>
                {/* &#8377;{finalAmtWithGST}/month */}
                {finalAmtWithGST > 0 ? (
                  <span className='flex flex-center'>
                    {<CurrencyRupeeIcon />}
                    {finalAmtWithoutGST}/month + GST
                  </span>
                ) : (
                  'Free'
                )}
              </h2>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column' as const,
              justifyContent: 'center' as const,
              alignItems: 'center' as const,
            }}
          >
            <button onClick={redirectToLoginSignup} style={styles.yellowButton}>
              Buy Now!
            </button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column' as const,
                justifyContent: 'center' as const,
                alignItems: 'center' as const,
                marginBlock: '24px',
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                Freemium: 1 strategy, 1 lot. Pricing based on all AI strategies'
                CAGR
              </p>
              {/* <p
                style={{
                  margin: 0,
                }}
              >
                Pricing based on all AI Strategies' CAGR (Monthly Refresh)
              </p> */}
            </div>
          </div>
        </div>

        {/* 2nd div  */}
        <div style={styles.allPlans}>
          <h2
            style={{
              ...styles.strategyText,
              marginLeft: '14px',
            }}
          >
            All Plans Include
          </h2>
          {strategyPlans.map((plan, index) => {
            return (
              <div style={styles.advatages} key={index}>
                <CheckCircle
                  style={{
                    color: plan.advantage
                      ? Theme.colors.blueSolid
                      : Theme.colors.red,
                    marginRight: 10,
                    marginLeft: 12,
                  }}
                />
                <p style={{ margin: 5 }}>{plan.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getActualPlans = () => {
    return (
      <>
        {isBacktest ? (
          <div style={styles.expandingContainer}>
            <div style={styles.eachPlanBox}>{getFreeBox()}</div>
            <div
              style={{
                ...styles.eachPlanBox,
                backgroundColor: Theme.colors.blueTainted15,
              }}
            >
              {getPremiumBox()}
            </div>
          </div>
        ) : (
          getStrategyPlans()
        )}
      </>
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetchUserPlanPricing();
        const { strategy, backtesting } = userData.user_plan_details;
        // setUserDetails(userData);
        // setStrategyPlanDetails(strategy);
        // setBacktestingPlanDetails(backtesting);
        if (
          userData?.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
        ) {
          setUserPnL(
            userData.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
          );
        } else {
          setUserPnL(0);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStrategyListData = async () => {
      const data: any = await getStrategyList();
      setAllAIStrategiesdata(data);
    };

    fetchUserData();
    fetchStrategyListData();
  }, [isSliderValueChanged]);

  useEffect(() => {
    let finalAmount =
      ((averageMinCapitalRequired * (averageCAGR / 100) * (sliderValue - 1)) /
        12) *
      0.106;
    setFinalAmtWithoutGST(Math.round(finalAmount));
    let gstAmt = (finalAmount * 18) / 100;
    // console.log(totalCAGR, averageCAGR, finalAmount, gstAmt);
    let finalAmountWithGST = finalAmount + gstAmt;
    setFinalAmtWithGST(Math.round(finalAmountWithGST));
  }, [AllAIStrategiesdata, sliderValue, userPnL]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value);
    if (inputValue >= sliderState) {
      setSliderValue(inputValue);
    }
  };

  return (
    <>
      <div>
        <p style={Styles.h1Text}>Choose your pricing plans</p>
        <p style={styles.descText}>Affordable plans, Incredible Gains</p>
        <div style={Styles.bottomBorderLine}></div>
      </div>
      {getPricingPlansSwitcher()}
      {getPayFrequencySwitcher()}
      {getActualPlans()}
    </>
  );
};

const styles = {
  claimBox: {
    display: 'flex',
    flexDirection: 'row' as const,
    backgroundColor: Theme.colors.blueTainted,
    color: Theme.colors.white,
    borderRadius: Theme.borderRadius,
  },
  advatages: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    margin: '10px 0',
  },
  divider: {
    width: '80%',
    backgroundColor: Theme.colors.grey70,
    left: '10%',
    height: 2,
  },
  yellowButton: {
    backgroundColor: Theme.colors.yellow,
    borderRadius: Theme.borderRadius,
    color: Theme.colors.black,
    padding: '10px 24px',
    margin: 10,
  },
  eachPlanBox: {
    borderRadius: Theme.borderRadius,
    backgroundColor: Theme.colors.white,
    width: 320,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: 10,
    textAlign: 'center' as const,
    margin: 10,
  },
  expandingContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'top' as const,
  },
  freqText: {
    margin: 24,
    fontSize: Theme.fontSizes.h3,
  },
  frequencyBox: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    margin: 'auto 24px 0 24px',
  },
  descText: {
    textAlign: 'center' as const,
  },
  selectedButton: {
    backgroundColor: Theme.colors.white,
    color: Theme.colors.blueSolid,
    margin: 0,
  },
  buttonBox: {
    margin: 'auto 24px 0 24px',
    backgroundColor: Theme.colors.blueSolid,
    borderRadius: Theme.borderRadius,
    padding: 0,
  },
  hintText: {
    textAlign: 'center' as const,
    backgroundColor: Theme.colors.white,
    padding: 10,
    borderRadius: 10,
    border: '1px dashed ' + Theme.colors.black70,
    fontSize: Theme.fontSizes.h6,
    maxWidth: 126,
  },
  buttonSwitcher: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
  },
  expandingContainerStrategy: {
    display: 'flex',
    flexDirection: 'row' as const,
    margin: '24px',
    padding: '24px',
  },
  buyStrategyPlans: {
    display: 'flex',
    flex: 0.6,
    flexDirection: 'column' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    borderRadius: Theme.borderRadius,
    background: 'linear-gradient(166.04deg, #9FB0FF 4.52%, #D6D9F5 92.5%)',
    padding: '84px',
  },
  allPlans: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column' as const,
    backgroundColor: Theme.colors.yellow,
    borderRadius: Theme.borderRadius,
    padding: '84px 34px',
  },
  strategyText: {
    fontWeight: '500',
    // fontSize:"20px"
  },
  rangeDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column' as const,
    justifyContent: 'space-between' as const,
  },
  rangeDivText: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'center' as const,
  },
  arrowRight: {
    height: '46px',
    margin: '43px 10px 0 -20px',
  },
  arrowLeft: {
    height: '46px',
    margin: '43px -20px 0 10px',
  },
};

export default PricingPlans;
