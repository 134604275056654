import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Theme } from '../../Utils/Constants';
import { AuthModule } from '../../API';
import { useNavigate } from 'react-router-dom';
import HeaderDropDown from './HeaderDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TimelineIcon from '@mui/icons-material/Timeline';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowSize from '../Hooks/useWindowSize';
import Loader from '../Dialogs/Loader';
import useAppStore from '../../store/app.store';
import { fetchAverageCAGRAndCapital } from '../../API/ProfileAPI';
import { decodeJwtToken } from '../../API/DecodeJWTFunction';
import { BroadcastChannel } from 'broadcast-channel';
import Toast from '../../Utils/Toast';

export enum CurrentPage {
  BackTesting = 'BackTesting',
  AI_Strategies = 'AI_Strategies',
  Market_News = 'Market_News',
  Learn = 'Learn',
  Pricing = 'Pricing',
  Leaderboard = 'Leaderboard',
  None = 'None',
}

interface HeaderProps {
  currentPage: CurrentPage;
}

function Header({ currentPage }: HeaderProps) {
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenResponsive, setIsDropdownOpenResponsive] =
    useState(false);

  const { isAuthenticated } = useAppStore();
  const { isLoading } = useAppStore();
  const { profileName } = useAppStore();
  const { setIsAuthenticated } = useAppStore();
  const { setIsLoading } = useAppStore();
  const { setProfileName } = useAppStore();
  const { profileImage } = useAppStore();
  const { isSignUpClickedFromHeader } = useAppStore();
  const { setIsSignUpClickedFromHeader } = useAppStore();
  const {
    setProfileImage,
    setUserLevel,
    setSelectedSectionIndex,
    setIsPricingClicked,
    setIsLogoClicked,
    setStarRating,
    setUserBacktestData,
    setTotalPnlValue,
    setMonthlyPnlValue,
    setAverageMinCapitalRequired,
    setAverageCAGR,
    averageCAGR,
    averageMinCapitalRequired,
  } = useAppStore();

  const windowSize = useWindowSize();
  const wsNtf = useRef<WebSocket | null>(null);
  var userId: string = "";
  const decodedToken = decodeJwtToken();

  const notificationChannel = new BroadcastChannel('notification');

  userId = String(decodedToken?.user_id ?? "");

  const signOut = useCallback(() => {
    AuthModule.getInstance().performLogout();
    navigate('/login', { replace: true });
  }, [navigate]);

  /*

if (
          userData?.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
        ) {
          setUserPnL(
            userData.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
          );
  */

  useMemo(() => {
    AuthModule.getInstance()
      .getAboutMe()
      .then((res) => {
        // console.log(res);
        setProfileName(res.name);
        setProfileImage(res.user_image);
        setUserLevel(res.user_level);
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        setStarRating(res.star_rating);
        setUserBacktestData(res.runbacktest_count.total_backtesting_count);
        setIsLoading(false);
        if (res.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl) {
          setTotalPnlValue(
            res.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
          );
        }
        if (res.user_pnl_data?.data?.user_pnl_ranking_view[0]?.monthly_pnl) {
          setMonthlyPnlValue(
            res.user_pnl_data?.data?.user_pnl_ranking_view[0]?.monthly_pnl
          );
        }
        return res;
      })
      .catch((err) => {
        console.error('error from header', err);
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchAverageData = async () => {
      try {
        const data = await fetchAverageCAGRAndCapital();
        // console.log(data);
        setAverageCAGR(data.average_cagr);
        setAverageMinCapitalRequired(data.average_minimum_required_capital);
      } catch (error) {
        console.error(error);
      }
    };
   // fetchAverageData();
  }, []);

  const getButtonType = () => {
    switch (isAuthenticated && windowSize > 1000) {
      case true:
        return loggedInDropDownMenu();
      case false:
        return loggedOutSignUpButton();
      default:
        return <Loader />;
    }
  };

  function toggleDropdown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setDropdownVisible(!isDropdownVisible);
  }

  function getStylesForPage(page: CurrentPage) {
    if (page === currentPage) {
      return {
        ...styles.centerTab,
        ...styles.highlightTab,
      };
    } else {
      return styles.centerTab;
    }
  }

  interface ButtonProps {
    responsive?: boolean;
  }

  async function getLiveNotification() {

    const url = `${process.env.REACT_APP_LIVE_NOTIFICATIONS}?user_id=${userId}`;

    try {
      wsNtf.current = new WebSocket(url);
      wsNtf.current.onopen = () => {
        if (wsNtf.current && wsNtf.current.readyState === WebSocket.OPEN) {
          console.log("Web socket connected successfully for notification");
        } else {
          console.log("WebSocket is not open or initialized properly for notification");
        }
      };

      wsNtf.current.onmessage = (e) => {

      
        const data = JSON.parse(e.data);
        notificationChannel.postMessage(data?.Message);
        
        // toast.success(data?.payloads?.data?.Message, {
        //   autoClose: 3000,
        // });

      };

      wsNtf.current.onerror = (e) => {
        console.log("Some error occured", e);
      };
      wsNtf.current.onclose = (e) => {
        console.log(e.code, e.reason);
        // setTimeout(() => {
        //   getLiveNotification();
        // }, 1000);
      };
    } catch (error) {
      console.log('error', error);

    }
  }

  useEffect(() => {
    getLiveNotification();
  }, []);

  const loggedInDropDownMenu = (props?: ButtonProps) => {
    return (
      <div
        style={
          props?.responsive
            ? styles.rightContainerResponsiveStyle
            : styles.rightContainerStyle
        }
        onClick={(e) => toggleDropdown(e)}
      >
        <span
          style={
            props?.responsive
              ? windowSize <= 380
                ? styles.profileNameSmallResponsiveStyle
                : styles.profileNameResponsiveStyle
              : windowSize <= 1160
                ? { ...styles.profileNameStyle, ...{ fontSize: '11px' } }
                : styles.profileNameStyle
          }
        >
          Hi,{' '}
          {profileName && profileName.length > 7
            ? `${profileName.substring(0, 7)}...`
            : profileName}
        </span>
        {isDropdownVisible ? (
          <KeyboardArrowUpIcon
            style={
              windowSize <= 380
                ? styles.buttonIconSmallResponsive
                : styles.buttonIcon
            }
          />
        ) : (
          <KeyboardArrowDownIcon
            style={
              windowSize <= 380
                ? styles.buttonIconSmallResponsive
                : styles.buttonIcon
            }
          />
        )}
        {isDropdownVisible && (
          <HeaderDropDown
            setDropdownVisible={setDropdownVisible}
            onSignOut={signOut}
          />
        )}
      </div>
    );
  };

  const loggedOutSignUpButton = (props?: ButtonProps) => {
    return (
      <div
        style={
          props?.responsive
            ? styles.rightContainerResponsiveStyle
            : styles.rightContainerStyle
        }
        onClick={() => {
          navigate('/login', { replace: true });
          setIsSignUpClickedFromHeader(true);
        }}
      >
        <span
          style={
            props?.responsive
              ? styles.profileNameResponsiveStyle
              : styles.profileNameStyle
          }
        >
          Sign Up Free
        </span>
      </div>
    );
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const handleDropdownResponsive = () => {
    setIsDropdownOpenResponsive(!isDropdownOpenResponsive);
    console.log(isDropdownOpenResponsive);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleLogoClick = () => {
    setIsLogoClicked(true);
    navigate('/home');
    setIsLogoClicked(false);
  };

  const handleLearnClick = () => {
    window.open(
      'https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ',
      '_blank'
    );
    // Or you can use navigate if you're navigating within your Gatsby app
    // navigate('https://www.youtube.com/your-youtube-video-link');
  };

  // useEffect(() => {
  //   if (isDropdownVisible) {
  //     const elements = document.querySelectorAll('*');
  //     elements.forEach((element) => {
  //       const el = element as HTMLElement;
  //       if (!el.classList.contains('dropdownStyle')) {
  //         el.style.overflow = 'hidden';
  //       }
  //     });
  //   }
  //   else {
  //     const elements = document.querySelectorAll('*');
  //     elements.forEach((element) => {
  //       const el = element as HTMLElement;
  //       el.style.overflow = 'scroll';
  //     });
  //   }
  // }, [isDropdownVisible]);

  // useEffect(() => {
  //   const app = document.querySelector('.App');
  //   if (app) {
  //     const secondChild = app.children[1] as HTMLElement | null;

  //     if (secondChild) {
  //       if (isDropdownVisible) {
  //         secondChild.style.height = 'calc(100vh - 70px)';
  //         secondChild.style.overflow = 'hidden';
  //       } else {
  //         secondChild.style.height = 'auto';
  //         secondChild.style.overflow = 'scroll';
  //       }
  //     }
  //   }
  // }, [isDropdownVisible]);

  // useEffect(() => {
  //   if (isDropdownVisible) {
  //     const elements = document.querySelectorAll("*");
  //     elements.forEach((element) => {
  //       const el = element as HTMLElement;
  //       if (!el.classList.contains("dropdownStyle")) {
  //         // Save the current overflow value
  //         el.dataset.prevOverflow = el.style.overflow;
  //         el.style.overflow = "hidden";
  //       }
  //     });
  //   } else {
  //     const elements = document.querySelectorAll("*");
  //     elements.forEach((element) => {
  //       const el = element as HTMLElement;
  //       el.style.overflow = "initial";
  //     });
  //   }
  // }, [isDropdownVisible]);

  function AIStrategiesIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        fill='none'
        viewBox='0 0 18 18'
      >
        <path
          fill='#fff'
          d='M15.75 6c-1.088 0-1.695 1.08-1.447 1.883l-2.663 2.67a1.528 1.528 0 00-.78 0L8.948 8.64c.255-.803-.353-1.89-1.448-1.89-1.088 0-1.702 1.08-1.447 1.89l-3.42 3.412C1.83 11.806.75 12.412.75 13.5c0 .825.675 1.5 1.5 1.5 1.087 0 1.695-1.08 1.447-1.883l3.413-3.42c.225.068.555.068.78 0l1.913 1.913c-.256.803.352 1.89 1.447 1.89 1.088 0 1.703-1.08 1.447-1.89l2.67-2.662c.803.247 1.883-.36 1.883-1.448 0-.825-.675-1.5-1.5-1.5z'
        ></path>
        <path
          fill='#fff'
          d='M11.25 6.75l.705-1.553L13.5 4.5l-1.545-.697-.705-1.553-.69 1.553L9 4.5l1.56.697.69 1.553zM2.625 8.25L3 6.75l1.5-.375L3 6l-.375-1.5L2.25 6l-1.5.375 1.5.375.375 1.5z'
        ></path>
      </svg>
    );
  }

  function BacktestingIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='18'
        fill='none'
        viewBox='0 0 16 18'
      >
        <path
          fill='#fff'
          d='M14.222 1.8h-3.715C10.133.756 9.156 0 8 0 6.844 0 5.867.756 5.493 1.8H1.778C.8 1.8 0 2.61 0 3.6v12.6c0 .99.8 1.8 1.778 1.8h12.444C15.2 18 16 17.19 16 16.2V3.6c0-.99-.8-1.8-1.778-1.8zM8 1.8c.489 0 .889.405.889.9s-.4.9-.889.9a.897.897 0 01-.889-.9c0-.495.4-.9.889-.9zm1.778 12.6H3.556v-1.8h6.222v1.8zm2.666-3.6H3.556V9h8.888v1.8zm0-3.6H3.556V5.4h8.888v1.8z'
        ></path>
      </svg>
    );
  }

  function MarketNewsIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        fill='none'
        viewBox='0 0 14 14'
      >
        <path
          fill='#fff'
          d='M12.444 0H1.556C.692 0 0 .7 0 1.556v10.888C0 13.3.692 14 1.556 14h10.888C13.3 14 14 13.3 14 12.444V1.556C14 .7 13.308 0 12.444 0zm0 12.444H1.556V3.111h10.888v9.333zM10.89 7H3.11V5.444h7.778V7zm-3.111 3.111H3.11V8.556h4.667v1.555z'
        ></path>
      </svg>
    );
  }

  function LearnIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='17'
        fill='none'
        viewBox='0 0 17 17'
      >
        <path
          fill='#fff'
          d='M13.91.62l-3.864 3.903v8.585l3.863-3.512V.62zM0 4.523v11.434c0 .195.193.39.386.39.078 0 .116-.04.194-.04 1.043-.506 2.55-.858 3.67-.858 1.507 0 3.13.313 4.25 1.171V4.523c-1.12-.859-2.743-1.171-4.25-1.171-1.507 0-3.13.312-4.25 1.17zm17 10.536V4.523c-.464-.352-.966-.586-1.546-.78v10.536c-.85-.274-1.777-.39-2.704-.39-1.314 0-3.207.507-4.25 1.17v1.561c1.043-.663 2.936-1.17 4.25-1.17 1.275 0 2.589.234 3.67.819.078.039.116.039.194.039.193 0 .386-.195.386-.39v-.859z'
        ></path>
      </svg>
    );
  }

  // useEffect(() => {
  //   const htmlElement = document.documentElement;
  //   if (isOpen) {
  //     htmlElement.style.overflow = "hidden";
  //   } else {
  //     htmlElement.style.overflow = "auto";
  //   }
  //   return () => {
  //     htmlElement.style.overflow = "auto";
  //   };
  // }, [isOpen]);

  function CrossIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='19'
        fill='none'
        viewBox='0 0 18 19'
        style={{ marginLeft: '6px', marginRight: '6px' }}
      >
        <path
          fill='#000'
          d='M18 2.193L16.187.38 9 7.567 1.813.38 0 2.193 7.187 9.38 0 16.567l1.813 1.813L9 11.193l7.187 7.187L18 16.567 10.813 9.38 18 2.193z'
        ></path>
      </svg>
    );
  }

  return (
    <><header
      className='relative'
      style={windowSize <= 1000
        ? { ...styles.headerStyle, flexDirection: 'column' }
        : styles.headerStyle}
    >
      <div
        style={windowSize <= 1000
          ? styles.leftContainerResponsiveStyle
          : styles.leftContainerStyle}
      >
        <div style={styles.logoContainerStyle} onClick={handleLogoClick}>
          {windowSize <= 1000 ? (
            <div onClick={toggleMenu} style={styles.menuIconContainer}>
              {!isOpen ? <MenuIcon style={styles.menuIcon} /> : <CrossIcon />}
            </div>
          ) : null}
          <img
            src='/images/logo.svg'
            alt='logo'
            style={{
              ...(windowSize <= 1000 && windowSize > 380
                ? styles.profileImageResponsiveStyle
                : windowSize <= 380
                  ? styles.profileImageSmallResponsiveStyle
                  : styles.profileImageStyle),
              cursor: 'pointer', // this line to change cursor to pointer
            }} />
        </div>
        {isAuthenticated &&
          windowSize <= 1000 &&
          loggedInDropDownMenu({ responsive: true })}
        {!isAuthenticated &&
          windowSize <= 1000 &&
          loggedOutSignUpButton({ responsive: true })}
      </div>
      {(windowSize > 1000 || isOpen) && (
        <div
          className='border-box headerResponsive'
          style={windowSize <= 1000
            ? { ...styles.centerContainer, ...styles.responsiveNavbar }
            : styles.centerContainer}
        >
          <div
            style={windowSize <= 1160 && windowSize > 1000
              ? {
                ...getStylesForPage(CurrentPage.AI_Strategies),
                ...{
                  fontSize: '11px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                },
              }
              : windowSize <= 1000
                ? {
                  ...getStylesForPage(CurrentPage.AI_Strategies),
                  ...{
                    whiteSpace: 'nowrap',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                    gap: '14px',
                  },
                }
                : { ...getStylesForPage(CurrentPage.AI_Strategies) }}
            onMouseEnter={() => {
              windowSize > 1000 && handleDropdownOpen();
            }}
            onMouseLeave={() => {
              windowSize > 1000 && handleDropdownClose();
            }}
            onClick={() => {
              windowSize <= 1000 && handleDropdownResponsive();
            }}
          >
            {windowSize <= 1000 && <AIStrategiesIcon />}
            <div
              style={isAuthenticated
                ? { position: 'relative', marginRight: 10 }
                : { position: 'relative' }}
            >
              <span
                onClick={() => {
                  navigate('/strategy');
                }}
              >
                AI Strategies{' '}
              </span>

              {isAuthenticated && (
                <>
                  <span>
                    {isDropdownOpen || isDropdownOpenResponsive === true ? (
                      <KeyboardArrowUpIcon
                        style={windowSize > 1160
                          ? {
                            position: 'absolute',
                            top: 0,
                            right: -25 + 'px',
                          }
                          : {
                            position: 'absolute',
                            top: -3,
                            right: -25 + 'px',
                          }} />
                    ) : (
                      <KeyboardArrowDownIcon
                        style={windowSize > 1160
                          ? {
                            position: 'absolute',
                            top: 0,
                            right: -25 + 'px',
                          }
                          : {
                            position: 'absolute',
                            top: -3,
                            right: -25 + 'px',
                          }} />
                    )}
                  </span>
                  {isDropdownOpen && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1000,
                        background: 'transparent',
                        display: 'flex',
                        width: 300,
                        paddingTop: 25,
                        fontWeight: 'light',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: '#FFFFFF',
                          width: 'auto',
                          paddingTop: '14px',
                          paddingBottom: '12px',
                          paddingLeft: '23px',
                          paddingRight: '28px',
                          borderRadius: '3px',
                          gap: 16,
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '21px',
                          letterSpacing: '0.03em',
                          textAlign: 'left',
                          color: '#000000',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <div
                          onClick={() => {
                            navigate('/strategy');
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <StackedLineChartIcon
                            style={{ color: '#000000B2' }} />{' '}
                          &nbsp;
                          <div style={{ textDecoration: 'none' }}>Explore</div>
                        </div>
                        <div
                          onClick={() => {
                            navigate('/live-today');
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <KeyboardDoubleArrowRightIcon /> &nbsp;
                          <div style={{ textDecoration: 'none' }}>
                            Live today
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setSelectedSectionIndex(4);

                            navigate('/strategypnl', { replace: false });
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <TimelineIcon style={{ color: '#000000B2' }} />
                          &nbsp;
                          <div style={{ textDecoration: 'none' }}>
                            Strategy P&L
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {isDropdownOpenResponsive && (
            <>
              <div
                onClick={() => {
                  navigate('/strategy');
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  paddingLeft: '30px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '11px',
                    lineHeight: '16px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                >
                  Explore
                </div>
              </div>
              <div
                onClick={() => {
                  navigate('/live-today');
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  paddingLeft: '30px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '11px',
                    lineHeight: '16px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                >
                  Live today
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSectionIndex(4);

                  navigate('/strategypnl', { replace: false });
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  paddingLeft: '30px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '11px',
                    lineHeight: '16px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                >
                  Strategy P&L
                </div>
              </div>
            </>
          )}
          <div
            style={windowSize <= 1160 && windowSize > 1000
              ? {
                ...getStylesForPage(CurrentPage.BackTesting),
                ...{ fontSize: '11px', whiteSpace: 'nowrap' },
              }
              : windowSize <= 1000
                ? {
                  ...getStylesForPage(CurrentPage.BackTesting),
                  ...{
                    whiteSpace: 'nowrap',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px',
                    gap: '14px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                  },
                }
                : { ...getStylesForPage(CurrentPage.BackTesting) }}
            onClick={() => {
              navigate('/backtesting');
            }}
          >
            {windowSize <= 1000 && <BacktestingIcon />}
            Backtesting
          </div>
          <div
            style={windowSize <= 1160 && windowSize > 1000
              ? {
                ...getStylesForPage(CurrentPage.Market_News),
                ...{ fontSize: '11px', whiteSpace: 'nowrap' },
              }
              : windowSize <= 1000
                ? {
                  ...getStylesForPage(CurrentPage.Market_News),
                  ...{
                    whiteSpace: 'nowrap',
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '14px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                  },
                }
                : { ...getStylesForPage(CurrentPage.Market_News) }}
            onClick={() => {
              navigate('/market-news');
            }}
          >
            {windowSize <= 1000 && <MarketNewsIcon />}
            Market Feed
          </div>
          <div
            style={windowSize <= 1160 && windowSize > 1000
              ? {
                // ...getStylesForPage(CurrentPage.Learn),
                ...{ fontSize: '11px', whiteSpace: 'nowrap' },
              }
              : windowSize <= 1000
                ? {
                  // ...getStylesForPage(CurrentPage.Learn),
                  ...{
                    whiteSpace: 'nowrap',
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '14px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.06em',
                    color: '#FFFFFF',
                  },
                }
                : { ...styles.centerTab }}
            onClick={handleLearnClick}
          >
            {windowSize <= 1000 && <LearnIcon />}
            Learn
          </div>
          {/* <div
              style={
                windowSize <= 1160 && windowSize > 1000
                  ? {
                      ...getStylesForPage(CurrentPage.Pricing),
                      ...{ fontSize: '11px', whiteSpace: 'nowrap' },
                    }
                  : windowSize <= 1000
                  ? {
                      ...getStylesForPage(CurrentPage.Pricing),
                      ...{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'transparent',
                        border: 'none',
                      },
                    }
                  : { ...getStylesForPage(CurrentPage.Pricing) }
              }
              onClick={() => {
                if (isAuthenticated) {
                  setSelectedSectionIndex(3);
                  navigate('/profile/pricing');
                } else {
                  setIsPricingClicked(true);
                  navigate('/home');
                }
              }}
            >
              Pricing
            </div> */}
          {/* <div
              style={
                windowSize <= 1160 && windowSize > 1000
                  ? {
                      ...getStylesForPage(CurrentPage.Leaderboard),
                      ...{ fontSize: '11px', whiteSpace: 'nowrap' },
                    }
                  : windowSize <= 1000
                  ? {
                      ...getStylesForPage(CurrentPage.Leaderboard),
                      ...{
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'transparent',
                        border: 'none',
                      },
                    }
                  : { ...getStylesForPage(CurrentPage.Leaderboard) }
              }
              onClick={() => {
                navigate('/leaderboard');
              }}
            >
              Leaderboard
            </div> */}
        </div>
      )}
      {windowSize > 1000 && getButtonType()}
    </header></>
  );
}

const styles = {
  buttonIcon: {
    color: Theme.colors.white,
    fontSize: Theme.fontSizes.h2,
    margin: 'auto',
  },
  buttonIconSmallResponsive: {
    color: Theme.colors.white,
    fontSize: 16,
    margin: 'auto',
  },
  menuIconContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    color: Theme.colors.black,
    fontSize: Theme.fontSizes.h1,
    margin: 'auto',
  },
  headerStyle: {
    backgroundColor: Theme.colors.white,
    color: '#fff',
    display: 'flex',
    borderBottom: `2px solid ${Theme.colors.whiteGrey}`,
    justifyContent: 'space-between',
  },
  centerContainer: {
    flex: 1,
    paddingLeft: Theme.gapSmall,
    marginTop: Theme.gapTiny,
    color: Theme.colors.black70,
    display: 'flex' as 'flex',
    height: '100%',
    alignItems: 'center' as 'center',
    fontSize: Theme.fontSizes.h4,
    flexDirection: 'row' as 'row',
  },
  leftContainerResponsiveStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: Theme.responsiveNavBarHorizondalGapLeft,
    paddingRight: Theme.responsiveNavBarHorizondalGapRight,
  },
  leftContainerStyle: {
    textAlign: 'center' as 'center',
    margin: 'auto',
    width: Theme.navBarWidth,
  },
  logoContainerStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    gap: '15px',
    justifyContent: 'center',
  },
  centerTab: {
    display: 'block',
    cursor: 'pointer',
    fontWeight: Theme.fontWeight.semiBold,
    padding: Theme.gapSmall,
    paddingTop: Theme.gapTiny,
  },
  highlightTab: {
    backgroundColor: Theme.colors.yellow,
    borderTop: `1px solid ${Theme.colors.blueSolid}`,
    borderLeft: `1px solid ${Theme.colors.blueSolid}`,
    borderRight: `1px solid ${Theme.colors.blueSolid}`,
    color: Theme.colors.blueSolid,
  },
  rightContainerResponsiveStyle: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    cursor: 'pointer',
    backgroundColor: Theme.colors.blueSolid,
    borderRadius: Theme.borderRadius,
    padding: '6px 10px',
  },
  rightContainerStyle: {
    textAlign: 'center' as 'center',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    cursor: 'pointer',
    backgroundColor: Theme.colors.blueSolid,
    borderRadius: Theme.borderRadius,
    padding: Theme.gapTiny,
    margin: `auto ${Theme.gapSmall}`,
  },
  profileImageSmallResponsiveStyle: {
    height: '20px',
    paddingTop: 22,
    paddingBottom: 22,
  },
  profileImageResponsiveStyle: {
    height: '28px',
    paddingTop: 18,
    paddingBottom: 18,
  },
  profileImageStyle: {
    width: Theme.logoWidthNavBar,
    paddingLeft: Theme.logoWidthNavBarGap,
    paddingRight: Theme.logoWidthNavBarGap,
  },
  profileNameResponsiveStyle: {
    fontSize: Theme.fontSizes.h5,
    fontWeight: Theme.fontWeight.medium,
    color: Theme.colors.white,
  },
  profileNameSmallResponsiveStyle: {
    fontSize: 11,
    fontWeight: Theme.fontWeight.medium,
    color: Theme.colors.white,
  },
  profileNameStyle: {
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.white,
    fontWeight: Theme.fontWeight.medium,
    paddingRight: Theme.gapSmall,
    paddingLeft: Theme.gapSmall,
  },
  responsiveNavbar: {
    flexDirection: 'column' as 'column',
    position: 'absolute' as 'absolute',
    left: '0px',
    top: '64px',
    margin: 0,
    zIndex: 9999,
    height: 'calc(100vh - 64px)',
    backgroundColor: '#2747DD',
    width: '200px',
    alignItems: 'flex-start' as 'flex-start',
    paddingLeft: '12px',
    paddingTop: '28px',
    gap: '19px',
  },
};

export default Header;
