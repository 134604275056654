import { decodeJwtToken } from '../../../API/DecodeJWTFunction';

interface IGetMarketNews {
  after_cursor: string;
  before_curose: string;
}

const decodedToken = decodeJwtToken();
const userID = String(decodedToken?.user_id) || '';

interface UserInteractApiResponse {
  response_message: string;
  response_data: any;
}

const getMarketNews = async (payload: IGetMarketNews) => {
  const apiKey = "HJ25k8zmuY7Eyu8FBLFb6584EkKu80UW4MeabtRq"
  console.log('payload', payload);
  const accessToken = localStorage.getItem('accessToken');

  const requestBody = userID
    ? { ...payload, user_id: Number(userID) }
    : { ...payload };
  // const requestBody = userID ? { ...payload, accessToken } : { ...payload };

  // console.log(requestBody);

  return new Promise((resolve, reject) => {
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) + 'market-news/list';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
      // body: JSON.stringify({
      //   ...payload,
      // }),
      body: JSON.stringify(requestBody),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          resolve(data);
        } else {
          reject('Data not found.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  });
};

export const MarketNewsService = {
  getMarketNews,
};

// like dislike upvote downvote share api call
export async function UserInteractions(
  reactionType: string,
  feedID: number
): Promise<UserInteractApiResponse | undefined> {
  const accessToken = localStorage.getItem('accessToken');
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userID: userID,
      feedID: feedID,
      reactionType: reactionType,
    }),
  };

  console.log(userID, feedID, reactionType);
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) +
    'market-news/user-interactions';
  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return {
      response_message: data.response_message,
      response_data: data.response_data,
    };
  } catch (error) {
    console.error('Error:', error);
    return undefined;
  }
}

// search bar api call
async function fetchSearchQueryNews(searchString: string): Promise<any[]> {
  const requestBody = (userID !== "undefined") ? { user_id: Number(userID) } : {};
  // console.log(userID);
  
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'market-news/search';
  // console.log(apiUrl, requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
      body: JSON.stringify({
        search_string: searchString,
        user_id: requestBody.user_id,
      }),
    });

    // console.log(response);

    // if (!response.ok) {
    //   throw new Error('Failed to fetch data');
    // }

    const data = await response.json();
    // console.log(data);

    return data.response_data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export default fetchSearchQueryNews;
