import React, { useEffect, useMemo, useState } from 'react';
import { AuthModule } from '../../API';
import { useNavigate } from 'react-router-dom';
import { Theme, ThemeTypes } from '../../Utils/Constants';
import AuthSetupErrors, { LandingPagesEnums } from './AuthSetupErrors';
import useAppStore from '../../store/app.store';
import useWindowSize from '../Hooks/useWindowSize';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  UpdatePasswordResponse,
  getPasswordResetOTP,
  updateUserPassword,
} from '../../API/Auth';
import eyeClose from '../../assets/homepageimages/eyeclosed.png';
import eyeOpen from '../../assets/homepageimages/eyeopen.png';
import Toast from '../../Utils/Toast';

const AuthSetupPage: React.FC = () => {
  const windowSize = useWindowSize();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPageInt] = useState(LandingPagesEnums.LOGIN);
  const [showCountdown, setShowCountdown] = useState(false);
  const { isLogoClicked, setIsLogoClicked } = useAppStore();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showForgotRePassword, setShowForgotRePassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const { isSignUpClickedFromHeader, setIsSignUpClickedFromHeader } =
    useAppStore();
  const { isStartBacktestClicked, setIsStartBacktestClicked } = useAppStore();
  const { isSubscribeNowClicked, setIsSubscribeNowClicked } = useAppStore();
  const { setIsExplorePlanClicked, isExplorePlanClicked } = useAppStore();
  const { setSelectedSectionIndex } = useAppStore();
  const { setSubscribeNowURL, subscribeNowURL } = useAppStore();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleShowRePassword = () => {
    setShowRePassword((prevState) => !prevState);
  };
  const toggleForgotShowPassword = () => {
    setShowForgotPassword((prevState) => !prevState);
  };
  const toggleForgotShowRePassword = () => {
    setShowForgotRePassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (isSignUpClickedFromHeader) {
      setCurrentPage(LandingPagesEnums.SIGNUP);
      setIsSignUpClickedFromHeader(false);
    }
  }, []);

  useMemo(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        if (!isAuthenticated) {
          if (isLogoClicked) {
            setIsLogoClicked(false);
            navigate('/home', { replace: true });
          } else {
            navigate('/login', { replace: true });
          }
        }
        // if (!isAuthenticated && isLogoClicked ) {
        //   navigate("/home", { replace: true });
        //   setIsLogoClicked(false);
        // }
      })
      .catch((error) => {
        console.error('error from login page', error);
      });
  }, [navigate, isLogoClicked]);

  async function sendResetValidationOTP(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    if (password !== rePassword) {
      Toast.error('Passwords do not match. Please try again.');
      //setErrorMessage('PASSWORD_MISMATCH');
      return;
    }
    // await AuthModule.getInstance()
    //   .completeSignUp(emailId, otp, password, phoneNumber)
    //   .then((data) => {
    //     console.log('Signup successful', data);
    //     navigate('/strategy', { replace: true });
    //   })
    //   .catch((error) => {
    //     setErrorMessage(error);
    //   });
    if (otp.length !== 6) {
      console.log(otp);
      Toast.info('Please enter a 6-digit OTP.');
      return;
    }

    try {
      const response: UpdatePasswordResponse = await updateUserPassword(
        emailId.toLowerCase(),
        password,
        rePassword,
        otp
      );
      console.log(response);

      console.log('Status:', response.Status);
      console.log('Message:', response.Message);
      if (response.Status === 400) {
        Toast.info(response.Message[0]);
        //setCurrentPage(LandingPagesEnums.LOGIN);
      }

      if (response.Status === 200) {
        console.log('Message:', response.Message);
        Toast.success(response.Message);
        setCurrentPage(LandingPagesEnums.LOGIN);
      } else {
        Toast.info(response.Message);
        console.log('i am Message:', response.Message);
      }
      // navigate('/strategy', { replace: true });
    } catch (error: any) {
      console.error('Error:', error);
      Toast.error(error);
    } finally {
      setPassword('');
      setRePassword('');
      setOtp('');
    }
  }

  async function resendPassword() {
    await AuthModule.getInstance()
      .sendOTP(emailId.toLowerCase(), password, phoneNumber, name, false, true)
      .then((data) => {
        console.log('OTP sent successfully', data);
        setCurrentPage(LandingPagesEnums.OTP_VALIDATION);
      })
      .catch((error) => {
        console.log('error from login page', error);
        setErrorMessage(error);
      });
  }

  async function validateOTP(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await AuthModule.getInstance()
      .completeSignUp(emailId.toLowerCase(), otp, null, phoneNumber)
      .then((data) => {
        console.log('Signup successful', data);
        // Call the login function after successful signup
        login(emailId, password);
        Toast.success('Signup successful');
        setTimeout(() => {
          navigate('/strategy', { replace: true });
        }, 1000);
      })
      .catch((error) => {
        setErrorMessage(error);
        Toast.error(error);
      });
  }

  async function sendValidationOTP(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (password !== rePassword) {
      Toast.error('Passwords do not match. Please try again.');
      //setErrorMessage('PASSWORD_MISMATCH');
      return;
    }
    await AuthModule.getInstance()
      .sendOTP(emailId.toLowerCase(), password, phoneNumber, name)
      .then((data) => {
        console.log('OTP sent successfully', data);
        Toast.success('OTP sent successfully');
        setCurrentPage(LandingPagesEnums.OTP_VALIDATION);
      })
      .catch((error) => {
        console.log('error from login page', error);
        if (
          error?.errors[0] ==
          'email: custom user with this email already exists.'
        ) {
          Toast.error('User with this email already exists.');
        }
        if (
          error?.errors[0] ==
          'phone_number: custom user with this phone number already exists.'
        ) {
          Toast.error('User with this phone number already exists.');
        }
        setErrorMessage(error);
      });
  }

  async function sendForgotPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // await AuthModule.getInstance()
    //   .sendOTP(emailId, password, phoneNumber, name, true)
    //   .then((data) => {
    //     console.log('OTP sent successfully', data);
    //     setCurrentPage(LandingPagesEnums.PASSWORD_RESET);
    //   })
    //   .catch((error) => {
    //     console.log('error from login page', error);
    //     setErrorMessage(error);
    //   });
    console.log(emailId);
    const { Message } = await getPasswordResetOTP(emailId.toLowerCase());
    Toast.success(Message);

    setCurrentPage(LandingPagesEnums.PASSWORD_RESET);
  }
  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
    setErrorMessage(''); // Clear password error message when password changes
  }
  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await AuthModule.getInstance()
      .performLogin(emailId.toLowerCase(), password)
      .then((data) => {
        if (isStartBacktestClicked) {
          setIsStartBacktestClicked(false);
          navigate('/backtesting', { replace: true });
        } else if (isSubscribeNowClicked) {
          setIsSubscribeNowClicked(false);
          navigate(subscribeNowURL, { replace: true });
          setSubscribeNowURL('');
        } else if (isExplorePlanClicked) {
          setIsExplorePlanClicked(false);
          setSelectedSectionIndex(3);
          navigate('/pricing', { replace: true });
        } else {
          navigate('/strategy', { replace: true });
        }
      })
      .catch((error) => {
        if (
          error.detail.includes(
            'No active account found with the given credentials'
          )
        ) {
          Toast.error('No active account found with the given credentials');
          //setErrorMessage('CREDENTIALS_INVALID');
        } else if (error.detail.includes('Password not matched')) {
          Toast.error('Incorrect Password!');
        } else if (error === 'NO_ACTIVE_ACCOUNT') {
          setErrorMessage('NO_ACTIVE_ACCOUNT');
        } else if (error === 'Login failed.') {
          // setErrorMessage('Invalid credentials. Please try again.');
        }
      });
  };

  function setCurrentPage(page: string) {
    setCurrentPageInt(page);
    setErrorMessage('');
    if (page === LandingPagesEnums.SIGNUP) {
      setPassword('');
    } else if (page === LandingPagesEnums.OTP_VALIDATION) {
      setErrorMessage('OTP_VALIDATION_COUNTDOWN');
      setShowCountdown(true);
    }
  }

  function renderSignUp() {
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 999,
        }}
      >
        <img
          src='/images/chart.png'
          alt='chart'
          style={
            windowSize <= 800
              ? {
                  position: 'absolute',
                  top: '30%',
                  left: '-5%',
                  width: '110%',
                  zIndex: -1,
                }
              : {
                  position: 'absolute',
                  top: '12%',
                  left: '-30%',
                  right: '0%',
                  bottom: '0%',
                  width: '160%',
                  zIndex: -1,
                }
          }
        />
        {windowSize <= 800 && (
          <img
            src='/images/reverseCircleGrp.png'
            alt='circle'
            style={{
              position: 'absolute',
              bottom: '-195px',
              right: '0px',
            }}
          />
        )}
        <div style={styles.headerButtons}>
          <button
            style={styles.headerButtonUnselected}
            onClick={() => setCurrentPage(LandingPagesEnums.LOGIN)}
          >
            Login
          </button>
          <button style={styles.headerButton}>SignUp</button>
        </div>
        <form
          onSubmit={sendValidationOTP}
          style={{
            backgroundColor: Theme.colors.blueSolid10,
            backdropFilter: 'blur(20px)',
            margin: '0px',
          }}
        >
          <div className='container-flex-column flex flex-center'>
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='email'
              placeholder='hi@moneyy.ai'
              value={emailId}
              onChange={(event) => setEmailId(event.target.value)}
              required
            />
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='text'
              placeholder='994949xxxx'
              value={phoneNumber}
              maxLength={10}
              pattern='\d{10}'
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
            />
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='text'
              placeholder='Name'
              autoComplete='name'
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <div
              style={{
                ...styles.inputCont,
                position: 'relative',
                width: '100%',
              }}
            >
              <input
                style={
                  windowSize <= 420
                    ? styles.inputElementResponsive
                    : styles.inputElement
                }
                // type='password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='new-password'
                placeholder='Password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
              <div
                style={{
                  // marginLeft: '-28px',
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: '999',
                  right: `${windowSize <= 420 ? '10%' : '5%'} `,
                  top: '42%',
                }}
              >
                <span className='flex flex-center' style={{}}>
                  {showPassword ? (
                    // <VisibilityOffOutlinedIcon onClick={toggleShowPassword} />
                    <img src={eyeOpen} alt='' onClick={toggleShowPassword} />
                  ) : (
                    <img src={eyeClose} alt='' onClick={toggleShowPassword} />
                    // <RemoveRedEyeOutlinedIcon onClick={toggleShowPassword} />
                  )}
                </span>
              </div>
            </div>
            <div
              style={{
                ...styles.inputCont,
                position: 'relative',
                width: '100%',
              }}
              className='flex flex-center'
            >
              <input
                style={
                  windowSize <= 420
                    ? styles.inputElementResponsive
                    : styles.inputElement
                }
                // type='password'
                type={showRePassword ? 'text' : 'password'}
                placeholder='Re-enter password'
                autoComplete='new-password'
                value={rePassword}
                onChange={(event) => setRePassword(event.target.value)}
                required
              />
              <div
                className='flex flex-center'
                style={{
                  // marginLeft: '-28px',
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: '999',
                  right: `${windowSize <= 420 ? '10%' : '5%'} `,
                  top: '42%',
                }}
              >
                <span
                  className='flex flex-center'
                  style={
                    {
                      // marginLeft: '-6px',
                      // paddingRight: '10px',
                    }
                  }
                >
                  {showRePassword ? (
                    // <VisibilityOffOutlinedIcon onClick={toggleShowRePassword} />
                    <img src={eyeOpen} alt='' onClick={toggleShowRePassword} />
                  ) : (
                    // <RemoveRedEyeOutlinedIcon onClick={toggleShowRePassword} />
                    <img src={eyeClose} alt='' onClick={toggleShowRePassword} />
                  )}
                </span>
              </div>
            </div>
          </div>
          {/* <div
            style={styles.forgotPassword}
            onClick={() => setCurrentPage(LandingPagesEnums.PASSWORD_RESET)}
          >
            Forgot Password
          </div> */}
          {renderLoginErrorMessage()}
          <div style={styles.loginButtonBox}>
            <button
              type='submit'
              style={{ ...ThemeTypes.yellowButton, margin: '0 auto' }}
            >
              Signup
            </button>
          </div>
        </form>
      </div>
    );
  }

  function renderGoBackToLoginLink() {
    return (
      <div style={styles.warningLine}>
        Have an account already?
        <p
          style={styles.linkLine}
          onClick={() => setCurrentPage(LandingPagesEnums.LOGIN)}
        >
          {' '}
          Login!
        </p>
      </div>
    );
  }

  function renderOtpValidation(): React.ReactNode {
    return (
      <div className='flex flex-center flex-col'>
        <p style={styles.pageHeader}>Activate</p>
        {renderGoBackToLoginLink()}
        <form onSubmit={validateOTP}>
          <div className='container-flex-column'>
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              value={phoneNumber}
              disabled={true}
            />
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              value={emailId}
              disabled={true}
            />
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='number'
              placeholder='OTP'
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
              required
            />
          </div>
          {renderLoginErrorMessage()}
          <button type='submit'>Validate</button>
        </form>
      </div>
    );
  }

  function renderLogin() {
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 999,
        }}
      >
        <img
          src='/images/chart.png'
          alt='chart'
          style={
            windowSize <= 800
              ? {
                  position: 'absolute',
                  top: '30%',
                  left: '-5%',
                  width: '110%',
                  zIndex: -1,
                }
              : {
                  position: 'absolute',
                  top: 'auto',
                  left: '-30%',
                  right: '0%',
                  bottom: '0%',
                  width: '160%',
                  zIndex: -1,
                }
          }
        />
        {windowSize <= 800 && (
          <img
            src='/images/reverseCircleGrp.png'
            alt='circle'
            style={{
              position: 'absolute',
              bottom: '-195px',
              right: '0px',
            }}
          />
        )}
        <div style={styles.headerButtons}>
          <button style={styles.headerButton}>Login</button>
          <button
            style={styles.headerButtonUnselected}
            onClick={() => setCurrentPage(LandingPagesEnums.SIGNUP)}
          >
            SignUp
          </button>
        </div>
        <form
          onSubmit={handleLoginSubmit}
          style={{
            backgroundColor: Theme.colors.blueSolid10,
            backdropFilter: 'blur(20px)',
            margin: '0px',
          }}
        >
          <div style={styles.inputCont}>
            <input
              type='email'
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              placeholder='hi@moneyy.ai'
              autoComplete='username'
              value={emailId}
              onChange={(event) => setEmailId(event.target.value)}
              required
            />
          </div>
          <div style={{ ...styles.inputCont, position: 'relative' }}>
            <input
              // type='password'
              type={showPassword ? 'text' : 'password'}
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              placeholder='Password'
              autoComplete='current-password'
              value={password}
              required
              onChange={handlePasswordChange}
            />
            <div
              // className='flex flex-center'
              style={{
                // marginLeft: '-29px',
                cursor: 'pointer',
                position: 'absolute',
                zIndex: '999',
                right: `${windowSize <= 420 ? '10%' : '5%'} `,
                top: '42%',
              }}
            >
              <span
                className='flex flex-center'
                style={
                  {
                    // marginLeft: '-7px',
                    // paddingRight: '10px',
                  }
                }
              >
                {showPassword ? (
                  // <VisibilityOffOutlinedIcon onClick={toggleShowPassword} />
                  <img src={eyeOpen} alt='' onClick={toggleShowPassword} />
                ) : (
                  // <RemoveRedEyeOutlinedIcon onClick={toggleShowPassword} />
                  <img src={eyeClose} alt='' onClick={toggleShowPassword} />
                )}
              </span>
            </div>
          </div>
          {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
          <div
            style={styles.forgotPassword}
            // onClick={() => setCurrentPage(LandingPagesEnums.PASSWORD_RESET)}
            onClick={() => setCurrentPage(LandingPagesEnums.FORGOT_PASSWORD)}
          >
            Forgot Password ?
          </div>
          {renderLoginErrorMessage()}
          <div style={styles.loginButtonBox}>
            <button
              type='submit'
              style={{ ...ThemeTypes.yellowButton, margin: '0 auto' }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    );
  }

  function renderPasswordResetValidate() {
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 999,
        }}
        className='flex flex-center flex-col'
      >
        <p style={styles.pageHeader}>Password Reset</p>
        {renderGoBackToLoginLink()}
        <form
          style={{}}
          className='flex flex-center flex-col'
          onSubmit={sendResetValidationOTP}
        >
          <div className='container-flex-column'>
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='email'
              placeholder='hi@moneyy.ai'
              value={emailId}
              onChange={(event) => setEmailId(event.target.value)}
              disabled={true}
            />
            <input
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              type='number'
              placeholder='OTP'
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
              required
            />
            <div style={{ position: 'relative' }} className='flex flex-center'>
              <input
                style={
                  windowSize <= 420
                    ? styles.inputElementResponsive
                    : styles.inputElement
                }
                // type='password'
                type={showForgotPassword ? 'text' : 'password'}
                autoComplete='new-password'
                placeholder='Password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
              <div
                className='flex flex-center'
                style={{
                  // marginLeft: '-28px',
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: '999',
                  right: `${windowSize <= 420 ? '10%' : '5%'} `,
                  top: '42%',
                }}
              >
                <span
                  className='flex flex-center'
                  style={
                    {
                      // marginLeft: '-6px',
                      // paddingRight: '10px',
                    }
                  }
                >
                  {showForgotPassword ? (
                    // <VisibilityOffOutlinedIcon
                    //   onClick={toggleForgotShowPassword}
                    // />
                    <img
                      src={eyeOpen}
                      alt=''
                      onClick={toggleForgotShowPassword}
                    />
                  ) : (
                    // <RemoveRedEyeOutlinedIcon
                    //   onClick={toggleForgotShowPassword}
                    // />
                    <img
                      src={eyeClose}
                      alt=''
                      onClick={toggleForgotShowPassword}
                    />
                  )}
                </span>
              </div>
            </div>
            <div
              style={{ ...styles.inputCont, position: 'relative' }}
              className='flex flex-center'
            >
              <input
                style={
                  windowSize <= 420
                    ? styles.inputElementResponsive
                    : styles.inputElement
                }
                // type='password'
                type={showForgotRePassword ? 'text' : 'password'}
                placeholder='Re-enter password'
                autoComplete='new-password'
                value={rePassword}
                onChange={(event) => setRePassword(event.target.value)}
                required
              />

              <div
                className='flex flex-center'
                style={{
                  // marginLeft: '-28px',
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: '999',
                  right: `${windowSize <= 420 ? '10%' : '5%'} `,
                  top: '42%',
                }}
              >
                <span
                  className='flex flex-center'
                  style={
                    {
                      // marginLeft: '-6px',
                      // paddingRight: '10px',
                    }
                  }
                >
                  {showForgotRePassword ? (
                    // <VisibilityOffOutlinedIcon
                    //   onClick={toggleForgotShowRePassword}
                    // />
                    <img
                      src={eyeOpen}
                      alt=''
                      onClick={toggleForgotShowRePassword}
                    />
                  ) : (
                    // <RemoveRedEyeOutlinedIcon
                    //   onClick={toggleForgotShowRePassword}
                    // />
                    <img
                      src={eyeClose}
                      alt=''
                      onClick={toggleForgotShowRePassword}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          {renderLoginErrorMessage()}
          <button type='submit'>Reset</button>
        </form>
      </div>
    );
  }

  function renderPasswordReset() {
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 999,
        }}
        className='flex flex-center flex-col'
      >
        <p style={styles.pageHeader}>Password Reset</p>
        <div className='flex flex-center'>{renderGoBackToLoginLink()}</div>
        <form onSubmit={sendForgotPassword}>
          <div style={styles.inputCont}>
            <input
              type='email'
              style={
                windowSize <= 420
                  ? styles.inputElementResponsive
                  : styles.inputElement
              }
              placeholder='hi@moneyy.ai'
              autoComplete='username'
              value={emailId}
              onChange={(event) => setEmailId(event.target.value)}
              required
            />
          </div>
          {renderLoginErrorMessage()}
          <button type='submit'>Send OTP</button>
        </form>
      </div>
    );
  }

  function renderLoginErrorMessage() {
    return (
      <AuthSetupErrors
        errorMessage={errorMessage}
        setCurrentPage={setCurrentPage}
        resendPassword={resendPassword}
        setErrorMessage={setErrorMessage}
        setShowCountdown={setShowCountdown}
      />
    );
  }

  function getAuthSetupPage() {
    switch (currentPage) {
      case LandingPagesEnums.LOGIN:
        return renderLogin();
      case LandingPagesEnums.SIGNUP:
        return renderSignUp();
      case LandingPagesEnums.OTP_VALIDATION:
        return renderOtpValidation();
      case LandingPagesEnums.FORGOT_PASSWORD:
        return renderPasswordReset();
      case LandingPagesEnums.PASSWORD_RESET:
        return renderPasswordResetValidate();
      default:
        return renderLogin();
    }
  }

  return (
    <>
      {getAuthSetupPage()}
    </>
  );
};

const styles = {
  headerButton: {
    ...ThemeTypes.yellowButton,
    borderRadius: 0,
    margin: 0,
    backgroundColor: Theme.colors.yellow,
    color: Theme.colors.black,
    border: '1px solid ' + Theme.colors.yellow,
    paddingLeft: Theme.gapLarge,
    paddingRight: Theme.gapLarge,
  },
  headerButtonUnselected: {
    ...ThemeTypes.yellowButton,
    borderRadius: 0,
    paddingLeft: Theme.gapLarge,
    paddingRight: Theme.gapLarge,
    margin: 0,
    border: '1px solid ' + Theme.colors.yellow,
    backgroundColor: Theme.colors.blueSolid,
    color: Theme.colors.yellow,
  },
  headerButtons: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'center',
    marginBottom: Theme.gapLarge,
  },
  loginButtonBox: {
    marginTop: Theme.gapLarge,
  },
  pageHeader: {
    fontSize: Theme.fontSizes.h2,
    margin: Theme.gapSmall,
    color: Theme.colors.white,
  },
  landingPage: {
    backgroundColor: Theme.colors.blueSolid,
  },
  inputElement: {
    minWidth: '280px',
    minHeight: '28px',
  },
  inputElementResponsive: {
    minWidth: '150px',
    minHeight: '28px',
  },
  warningLine: {
    color: Theme.greyColor,
    fontSize: Theme.fontSizes.h6,
    // marginBottom: Theme.gapSmall,
  },
  loginBox: {
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.borderRadius,
    margin: Theme.gapSmall,
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
    flex: '1',
    justifyContent: 'center',
  },
  bannerImage: {
    borderRadius: Theme.borderRadius,
    margin: Theme.gapSmall,
    flex: '1',
    width: 'auto',
  },
  imageElement: {
    width: '100%',
    minWidth: '280px',
  },
  landingText: {
    color: Theme.colors.white,
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flex: 'row',
  },
  linkLine: {
    color: Theme.colors.whitishGrey,
    display: 'inline',
    cursor: 'pointer',
  },
  inputCont: {
    display: 'flex',
    justifyContent: 'center' as const,
  },
  errorMessage: {
    color: Theme.colors.red,
    fontSize: Theme.fontSizes.h5,
    margin: '5px 15px 10px 15px',
  },
  forgotPassword: {
    color: Theme.colors.whitishGrey,
    fontSize: Theme.fontSizes.h5,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end' as const,
    cursor: 'pointer',
  },
};

export default AuthSetupPage;
async function login(emailId: any, password: any) {
  // Call the login function after successful signup
  await AuthModule.getInstance().performLogin(emailId.toLowerCase(), password);
  AuthModule.getInstance().getAboutMe();
  //window.location.reload();
}
