import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Styles, Theme, ThemeTypes } from '../../Utils/Constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
interface PopupProps {
  strategyName: string;
}

export const CongratsPopup: React.FC<PopupProps> = ({ strategyName }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className='subscribe-popup-card'
      style={{
        display: isVisible ? 'block' : 'none',
      }}
    >
      <div
        className='flex-center '
        style={{
          position: 'fixed',
          minWidth: '500px',
          top: '40%',
          left: '30%',
          // padding: '10px',
          // position: 'fixed',
          backgroundColor: '#fff',
          // zIndex: 999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CheckCircleIcon
            style={{ fontSize: 60 }}
            className='check-circle-icon'
          />
          <span style={{ fontWeight: Theme.fontWeight.medium }}>
            Congratulations for subscribing to "{strategyName}"
          </span>
          <p>Now you can manage your Portfolio here</p>

          <button
            onClick={() => {
              navigate('/live-today');
            }}
            style={ThemeTypes.yellowButton}
          >
            {' '}
            Manage Portfolio
          </button>
        </div>
      </div>
    </div>
  );
};

// export const congratsMessagePopup = (strategyName: string) => {
//   const popupElement = document.createElement('div');
//   document.body.appendChild(popupElement);

//   const closePopup = () => {
//     ReactDOM.unmountComponentAtNode(popupElement);
//     popupElement.remove();
//   };

//   ReactDOM.render(<CongratsPopup strategyName={strategyName} />, popupElement);

//   setTimeout(() => {
//     closePopup();
//   }, 5000);
// };
