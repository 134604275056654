import { Article, Assignment, AutoGraph } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Styles, Theme } from '../../Utils/Constants';
import { fetchHomePageData } from '../../API/HomePageAPI';
import useWindowSize from '../Hooks/useWindowSize';

const WebsiteOptions: React.FC = () => {
  const [metricsData, setMetricsData] = useState<any>({});

  const windowSize = useWindowSize();

  const fetchHomePageData = async () => {
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) + 'home-page/details';
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        setMetricsData(responseData.response_data);
      } else {
        throw new Error(responseData.response_message);
      }
    } catch (error: any) {
      throw new Error(`Error: ${error?.message}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchHomePageData();
        if (data != undefined) setMetricsData(data);
      } catch (error: any) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const options = [
    {
      title: 'AI Strategies',
      description:
        'Subscribe to AI strategy, Integrate broker, Monitor returns. No intervention or coding required',
      image: <AutoGraph style={styles.icons} />,
    },
    {
      title: 'Backtesting',
      description:
        'Backtest & Optimize your strategy over 6 years of historical data across multiple instruments before going live.',
      image: <Assignment style={styles.icons} />,
    },
    {
      title: 'Market Feed',
      description:
        'Stay informed and take action with our real-time Market Feed.',
      image: <Article style={styles.icons} />,
    },
  ];

  // const pageMetrics = [
  //   {
  //     title: "1 Cr.+",
  //     description: "Money Managed",
  //   },
  //   {
  //     title: "100K+",
  //     description: "Live Trades Taken",
  //   },
  //   {
  //     title: "5",
  //     description: "Strategies",
  //   },
  //   {
  //     title: "1000+",
  //     description: "Moneyy.AI Users",
  //   },
  //   {
  //     title: "10+",
  //     description: "Brokers",
  //   },
  //   {
  //     title: "+16.78%",
  //     description: "AI Strategies CAGR",
  //   },
  // ];

  const formatMoneyManaged = (value: number) => {
    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(1)} Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(1)} L`;
    } else {
      return value?.toString();
    }
  };

  const pageMetrics = [
    {
      title: formatMoneyManaged(metricsData.total_capital_deployed) + '+' || 0,
      description: 'Capital Deployed',
    },
    {
      title: formatMoneyManaged(metricsData?.['total_backtest']) + '+' || 0,
      description: 'No. of Backtests',
    },
    {
      title: metricsData?.['no_of_strategies'] || 0,
      description: ' AI Strategies',
    },
    {
      title: formatMoneyManaged(metricsData?.['total_ai_users']) + '+' || 0,
      description: 'Moneyy.AI Users',
    },
    {
      title: metricsData?.['total_broker'] + '+' || 0,
      description: 'Brokers',
    },
    {
      title: '+' + metricsData?.['ai_strategies_cagr'] + '%' || 0,
      description: 'AI Strategies CAGR',
    },
  ];

  const getPageOptions = () => {
    return options.map((option, index) => {
      return (
        <div key={index} style={styles.optionBox}>
          <div>{option.image}</div>
          <div>
            <p
              style={
                windowSize <= 1000
                  ? styles.optionTitleResponsive
                  : styles.optionTitle
              }
            >
              {option.title}
            </p>
            <p
              style={
                windowSize <= 1000
                  ? styles.optionTextResponsive
                  : styles.optionText
              }
            >
              {option.description}
            </p>
          </div>
        </div>
      );
    });
  };

  const getPageMetrics = () => {
    return pageMetrics.map((metric, index) => {
      return (
        <div key={index} style={styles.metricsContainer}>
          <div style={styles.metricsInner}>
            <p
              style={
                windowSize <= 1000
                  ? styles.metricTitleResponsive
                  : styles.metricTitle
              }
            >
              {metric.title}
            </p>
            <p
              style={
                windowSize <= 1000 ? styles.metricDescriptionResponsive : {}
              }
            >
              {metric.description}
            </p>
          </div>
        </div>
      );
    });
  };

  const getLandingPageTitle = () => {
    return (
      <>
        <p style={windowSize <= 1000 ? styles.h1TextResponsive : styles.h1Text}>
          Unlock the potential of Moneyy.ai for...
        </p>
        {/* <p
          style={
            windowSize <= 1000 ? styles.descTextResponsive : styles.descText
          }
        >
          Smart Trading Starts with Moneyy.ai
        </p> */}
      </>
    );
  };

  return (
    <>
      <div>{getLandingPageTitle()}</div>
      {/* <div style={styles.dividerLine}></div> */}
      <div
        style={
          windowSize <= 800
            ? styles.flexContainerResponsive
            : styles.flexContainer
        }
      >
        <div style={styles.boxesContainer}>{getPageOptions()}</div>
        <div style={styles.boxesContainer}>
          <div
            style={windowSize <= 1000 ? styles.columnGrid : styles.columnFlex}
            className='no-wrap'
          >
            {getPageMetrics()}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  icons: {
    color: Theme.colors.blueSolid,
    marginRight: '32px',
    fontSize: '32px',
  },
  metricsInner: {
    textAlign: 'center' as const,
    margin: '24px 0px',
  },
  metricTitle: {
    color: Theme.colors.blueSolid,
    fontSize: '40px',
    padding: '0px',
    fontWeight: Theme.fontWeight.semiBold,
    margin: '0px',
  },
  metricTitleResponsive: {
    color: Theme.colors.blueSolid,
    padding: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '42px',
    letterSpacing: '0.05em',
    margin: '0',
    marginBottom: '14px',
  },
  metricDescriptionResponsive: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.05em',
    color: '#000000',
  },
  h1Text: {
    ...Styles.h1Text,
    marginTop: '51px',
  },
  h1TextResponsive: {
    ...Styles.h1TextHomeResponsive,
    marginTop: '24px',
    padding: '0px 30px',
  },
  columnFlex: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'center' as const,
  },
  columnGrid: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center' as const,
    columnGap: '89px',
    rowGap: '30px',
    justifyContent: 'center' as const,
  },
  metricsContainer: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    minWidth: '40%',
    justifyContent: 'center' as const,
  },
  optionTitle: {
    padding: '0px',
    margin: '0px',
    fontSize: Theme.fontSizes.h2,
    fontWeight: Theme.fontWeight.semiBold,
  },
  optionTitleResponsive: {
    padding: '0px',
    margin: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.05em',
    color: '#000000',
  },
  optionText: {
    padding: '0px',
    margin: '0px',
    fontSize: Theme.fontSizes.h5,
  },
  optionTextResponsive: {
    padding: '0px',
    margin: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.07em',
    color: '#000000',
  },
  descText: {
    ...Styles.h4Text,
    textAlign: 'center' as const,
  },
  descTextResponsive: {
    ...Styles.h4TextHomeResponsive,
    textAlign: 'center' as const,
  },
  flexContainer: {
    display: 'flex' as const,
  },
  flexContainerResponsive: {
    display: 'flex' as const,
    flexDirection: 'column' as 'column',
  },
  dividerLine: {
    ...Styles.bottomBorderLine,
  },
  boxesContainer: {
    padding: '20px',
    flex: 1,
    margin: 'auto',
  },
  optionBox: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    alignItems: 'top' as const,
    padding: '32px',
    border: '4px solid ' + Theme.colors.blueSolid10,
    borderRadius: '10px',
    margin: '32px 24px',
  },
  container: {},
};

export default WebsiteOptions;
