import React, { useState, useEffect, useRef } from 'react';
import './aistrategies.css';
import { StrategyCard } from './StrategyCard';
import SearchIcon from '@mui/icons-material/Search';
import getStrategyList from './AIstrategiesdata';
import Header, { CurrentPage } from '../Header/Header';
import AIStrategiesFilter from './AIStrategiesFilter';
import Footer from '../Footer';
import { Link, useNavigate } from 'react-router-dom';
import useWindowSize from '../Hooks/useWindowSize';
import { West } from '@mui/icons-material';
import FilterIcon from '../Vector Components/FilterIcon';
import Loader from '../Dialogs/Loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useOutSideClick from '../../hooks/useOutSide';
import AIStrategiesFilterScreen from './AIStrategiesFilterScreen';
import bgThreeCircle from '../../assets/images/bg-three-circle.svg';
import bgThreeCircleResponsive from '../../assets/images/bg-three-circle-responsive.svg';

const AIStrategiesPage: React.FC = () => {
  const [tab, setTab] = useState('All');
  const [sortingOption, setSortingOption] = useState('minimum_investment_capital'); // State to hold sorting option// State to hold selected option
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(CurrentPage.AI_Strategies);
  const [AIStrategiesdata, setAIStrategiesdata] = useState<any>([]);
  const [AllAIStrategiesdata, setAllAIStrategiesdata] = useState<any>([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [filtersScreen, setFiltersScreen] = useState(false);
  // let [filteredStrategies, setFilteredStrategies] = useState<any>([]);


  const containerRef = useRef(null);

  const fetchData = async () => {
    const data: any = await getStrategyList();
    setAllAIStrategiesdata(data);
    setAIStrategiesdata(data);
    // setIsLoading(false);
  };



  useEffect(() => {
    fetchData();
    if (AIStrategiesdata.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  useEffect(() => { }, [searchQuery]);

  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const handleTabChange = (selectedTab: string) => {
    setTab(selectedTab);
  };

  // Function to handle search query change
  const handleSearchChange = (e: string) => {
    setSearchQuery(e);
  };

  // Function to handle sorting option change
  const handleSortingChange = (option: string) => {
    setSortingOption(option);
    setIsDropDownOpen(false);

  };

  const handleDropDown = () => {
    if (isDropDownOpen) {
      setIsDropDownOpen(false);
    } else {
      setIsDropDownOpen(true);
    }
  };

  // Sort strategies based on the selected option
  let sortedFilteredStrategies =
    AIStrategiesdata.length > 0
      ? [...AIStrategiesdata]
      : AllAIStrategiesdata ? [AllAIStrategiesdata] : [];

  if (sortingOption === 'capital_deployed') {
    AIStrategiesdata.sort(
      (a: { capital_deployed: string; }, b: { capital_deployed: string; }) =>
        parseFloat(b.capital_deployed.replace(/,/g, '')) -
        parseFloat(a.capital_deployed.replace(/,/g, ''))
    );
    // Reverse the order for descending display


    // sortedFilteredStrategies.reverse();
  } else if (sortingOption === 'cagr_pct') {
    AIStrategiesdata.sort(
      (a: { cagr_pct: string; }, b: { cagr_pct: string; }) => parseFloat(b.cagr_pct) - parseFloat(a.cagr_pct)
    );
    // Reverse the order for descending display
    // sortedFilteredStrategies.reverse();
  } else if (sortingOption === 'minimum_investment_capital') {
    AIStrategiesdata.sort(
      (a: { minimum_investment_capital: string; }, b: { minimum_investment_capital: string; }) => parseFloat(b.minimum_investment_capital.replace(/,/g, '')) -
        parseFloat(a.minimum_investment_capital.replace(/,/g, ''))
    );
    // Reverse the order for descending display
    AIStrategiesdata.reverse();
  }

  // Filtered strategies based on search query
  const filteredStrategies = sortedFilteredStrategies.filter(
    (strategy: any) => {
      // Concatenate all relevant fields into a single string
      const strategyContent = Object.values(strategy).join(' ').toLowerCase();
      // Check if the concatenated string contains the search query
      return strategyContent.includes(searchQuery.toLowerCase());
    }
  );

  const renderContent = () => {
    const container = document.querySelector(".container-responsive");
    if (container !== null) {
      (container as HTMLElement).style.height = "auto";
      (container as HTMLElement).style.overflow = "scroll";
    }

    if (tab === 'All') {
      if (
        sortedFilteredStrategies.length === 0 ||
        filteredStrategies.length === 0
      ) {
        return (
          <div
            style={windowSize <= 800 ? {
              padding: '40px 0px',
              textAlign: 'center',
            } : {
              padding: '15rem',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            No AIStrategies available based on applied sorting and searching
          </div>
        );
      } else {
        return (
          <StrategyCard
            AIStrategiesdata={AIStrategiesdata.filter((obj: { is_live: any; }) => obj.is_live)}
          />
        );
      }
    }
    return null;
  };

  useOutSideClick(containerRef, () => {
    if (isDropDownOpen) {
      setIsDropDownOpen(false);
    }
  });

  // if (isLoading) {
  //   return <Loader />;
  // }

  // console.log(AllAIStrategiesdata);

  function getImageStyle(windowSize: any) {
    switch (true) {
      case windowSize <= 1000:
        return {
          position: "absolute" as "absolute",
          top: "85px",
          right: "7px",
          zIndex: "1",
        };
      default:
        return {
          position: "absolute" as "absolute",
          top: "131px",
          right: "49.25px",
          zIndex: "1",
        };
    }
  }

  const imgStyle = getImageStyle(windowSize);

  return (
    <>
      <Header currentPage={currentPage} />
      {isLoading ? (
        <Loader />
      ) : (
        <><img src={windowSize > 1000 ? bgThreeCircle : bgThreeCircleResponsive} alt="bg" style={imgStyle} /><div className={windowSize > 900 ? 'container' : 'container-responsive'}>
          {windowSize > 1300 && (
            <AIStrategiesFilter
              setAIStrategiesdata={setAIStrategiesdata}
              AIStrategiesdata={AllAIStrategiesdata} />

          )}
          <div className='strategy-landing-page'>
            {windowSize > 800 ? (
              <div className='strategy-tabs'>
                <div
                  className='tab-option flex-center'
                  onClick={() => handleTabChange('All')}
                >
                  <span>All Strategies</span>
                </div>
                <div className='Popularity tab-option flex-center'>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: '300',
                      fontSize: '16px',
                      position: 'relative',
                    }}
                    ref={containerRef}
                  >
                    Sort by : &nbsp;{' '}
                    <div onClick={handleDropDown}>
                      <button
                        className='sortby-top-option'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          padding: '0',
                          backgroundColor: 'transparent',
                          color: 'black',
                        }}
                      >
                        {isDropDownOpen ? (
                          <>
                            Select
                            <KeyboardArrowUpIcon />
                          </>
                        ) : (
                          <>
                            {sortingOption === 'capital_deployed'
                              ? 'Capital Deployed'
                              : sortingOption === 'cagr_pct'
                                ? 'Earnings'
                                : sortingOption === 'minimum_investment_capital'
                                  ? 'Minimum Capital'
                                  : sortingOption}
                            <KeyboardArrowDownIcon />
                          </>
                        )}
                      </button>
                    </div>
                    {isDropDownOpen && (
                      <div className='sortby-options'>
                        <button
                          className='sort-option'
                          style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                          onClick={() => handleSortingChange('minimum_investment_capital')}
                        >
                          Minimum Capital
                        </button>
                        <button
                          className='sort-option'
                          style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                          onClick={() => handleSortingChange('capital_deployed')}
                        >
                          Capital Deployed
                        </button>
                        <button
                          className='sort-option'
                          style={{ color: 'rgba(0, 0, 0, 0.70)' }}
                          onClick={() => handleSortingChange('cagr_pct')}
                        >
                          Earnings
                        </button>
                      </div>
                    )}
                  </span>
                  <div></div>
                  {/* <select
              style={{
                border: 'none',
                //padding: '5px',
                backgroundColor: 'transparent',
                borderRadius: '5px',
                boxShadow: 'none',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '14px',
              }}
              value={sortingOption}
              onChange={(e) => handleSortingChange(e.target.value)}
            >
              <option value='popularity'>Popularity</option>
              <option value='capital_deployed'>Capital Deployed</option>
              <option value='cagr_pct'>Earnings</option>
            </select> */}
                </div>

                <div className='search-bar tab-option flex-center'>
                  <SearchIcon />
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Search any strategies'
                    onChange={(e) => handleSearchChange(e.target.value)} />
                </div>
              </div>
            ) : (
              <div className='strategy-tabs-responsive'>
                <div
                  className='tab-option-responsive-border relative'
                  onClick={() => handleTabChange('All')}
                >
                  <West
                    onClick={() => {
                      navigate('/backtesting');
                    }}
                    style={{ cursor: 'pointer', position: "absolute", left: 6 }} />
                  <span className='strategy-title-style-responsive flex-center '>
                    {' '}
                    All Strategies
                  </span>
                </div>
                <div className='search-bar tab-search-option-responsive'>
                  <SearchIcon />
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Search any strategies'
                    onChange={(e) => handleSearchChange(e.target.value)} />
                </div>
                <div className='popu-fil-responsive'>
                  <div className='tab-option-responsive'>
                    <span style={{ fontWeight: '300', fontSize: '16px' }}>
                      Sort by
                    </span>
                    <select
                      style={{
                        border: 'none',
                        //padding: '5px',
                        backgroundColor: 'transparent',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '14px',
                      }}
                      value={sortingOption}
                      onChange={(e) => handleSortingChange(e.target.value)}
                    >
                      <option value='popularity'>Minimum Capital</option>
                      <option value='capital_deployed'>Capital Deployed</option>
                      <option value='cagr_pct'>Earnings</option>
                    </select>
                  </div>
                  <span onClick={() => {
                    setFiltersScreen(true);
                  }} className='filter-span pointer'>
                    {' '}
                    <FilterIcon /> Filters
                  </span>
                </div>
              </div>
            )}
            <div
              className={windowSize <= 900 ? 'tab-content-responsive' : 'tab-content'}
            >
              {filtersScreen ? <AIStrategiesFilterScreen setFiltersScreen={setFiltersScreen}
                setAIStrategiesdata={setAIStrategiesdata}
                AIStrategiesdata={AllAIStrategiesdata} /> : renderContent()}
              {/* <Link
      to="/strategypage"
      style={
        windowSize <= 900
          ? { textDecoration: "none", width: 100 + "%" }
          : { textDecoration: "none" }
      }
    > */}
              {/* </Link> */}
            </div>
          </div>
          <Footer />
        </div></>
      )}
    </>
  );
};

export default AIStrategiesPage;
