import React, { useEffect, useState } from 'react';
import './TradingCalendar.css'; // Import CSS file for styling
import TestResultsParser from '../../../Utils/TestResultsParser';
import {
  BackTestAPIResponse,
  DailyBackTestResult,
} from '../../../API/API.interface';
import { formatNumber } from '../../../API/FormatNumber';
import { Theme } from '../../../Utils/Constants';
import useWindowSize from '../../Hooks/useWindowSize';
import BackTestResultsParser from '../../../Utils/BackTestResultParser';
interface Props {
  results: BackTestResultsParser;
  avgCapital: number;
}
interface DailyAlgoResults {
  [date: string]: number;
}

const BacktestTradingCalendar: React.FC<Props> = ({ results,avgCapital }) => {
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>({
    from: new Date(),
    to: new Date(),
  });
  const [selectedYear, setSelectedYear] = useState<number>(2022); // Initial selected year
  const [dailyAlgoResults, setDailyAlgoResults] = useState<DailyAlgoResults>(
    {}
  );
  const handleChangeDateRange = (from: Date, to: Date) => {
    setDateRange({ from, to });
  };
  // Extract dailyAlgoResults from props
  const AlgoResults = results.rawResults.dailyBackTestResults;
  const [capitalDeployed, setCapitalDeployed] = useState<number>(0);
  const [totalPnL, setTotalPnL] = useState<number>(0); // Total PnL
  const [totalPnLPercentage, setTotalPnLPercentage] = useState<number>(0); // Total PnL Percentage
  const [totalPnLColor, setTotalPnLColor] = useState<string>(''); // Total PnL color

  useEffect(() => {
    const pnlData = getPnLData(AlgoResults, selectedYear);
    setDailyAlgoResults(pnlData);

    // Calculate total capital deployed for the selected year
    // const selectedYearCapital = getCapitalDeployedAverage(AlgoResults, selectedYear);
    // setCapitalDeployed(selectedYearCapital);
    setCapitalDeployed(avgCapital);

    // Calculate total PnL for the selected year
    const selectedYearData = Object.values(pnlData);
    const selectedYearTotalPnL = selectedYearData.reduce(
      (acc: number, pnl: any) => acc + parseFloat(pnl),
      0
    );
    setTotalPnL(selectedYearTotalPnL);

    // Set total PnL color based on value
    setTotalPnLColor(selectedYearTotalPnL >= 0 ? '#2ACD1C' : '#F82929');
    // Calculate total PnL percentage for the selected year
    let selectedYearAveragePnLPercentage = "0";
    if (selectedYearTotalPnL !== 0 && capitalDeployed !== 0) {
      selectedYearAveragePnLPercentage = (
        (selectedYearTotalPnL / capitalDeployed) *
        100
      ).toFixed(1);
    }
    setTotalPnLPercentage(parseFloat(selectedYearAveragePnLPercentage));
  }, [AlgoResults, capitalDeployed, selectedYear]);

  // Generate an array of month names and days in each month
  const monthsInYear = Array.from({ length: 12 }, (_, index) =>
    new Date(selectedYear, index, 1).toLocaleDateString('en-US', {
      month: 'short',
    })
  );

  function getPnLData(AlgoResults: any, selectedYear: number) {
    // Filter PnL data for the selected year
    const pnlData: any = {};
    Object.keys(AlgoResults).forEach((date) => {
      const year = new Date(date).getFullYear();
      if (year === selectedYear) {
        pnlData[date] = AlgoResults[date].pnL.toFixed(3);
      }
    });
    return pnlData;
  }


  const getCapitalDeployedAverage = (AlgoResults: any, selectedYear: number) => {
    let totalCapital = 0;
    let recordCount = 0;

    Object.entries(AlgoResults).forEach(([date, data]: any) => {
      const year = new Date(date).getFullYear();
      if (year === selectedYear) {
        totalCapital += data.account_margin;
        recordCount++;
      }
    });

    // Calculate average only if there are records for the selected year
    const averageCapital = recordCount > 0 ? totalCapital / recordCount : 0;

    return averageCapital;
  };


  const datesInMonth = (monthIndex: number) =>
    Array.from(
      { length: new Date(selectedYear, monthIndex + 1, 0).getDate() },
      (_, index) => index + 1
    );

  // Function to get PnL color class based on PnL value
  const getPnLColorClass = (dateStr: keyof DailyAlgoResults) => {
    const pnlValue = dailyAlgoResults[dateStr] || 0; // Default to 0 if PnL data not available
    if (pnlValue > 0) return 'positive-pnl';
    else if (pnlValue < 0) return 'negative-pnl';
    else return 'zero-pnl';
  };

  // Handle year selection change
  // const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedYear(parseInt(e.target.value, 10));
  // };
  const handleYearChange = (year: number) => {
    setSelectedYear(year);
  };

  // Function to get days of the month starting from Sunday
  const daysInMonthStartingFromSunday = (monthIndex: number) => {
    const firstDayOfMonth = new Date(selectedYear, monthIndex, 1).getDay();
    const daysInMonth = new Date(selectedYear, monthIndex + 1, 0).getDate();
    const days = [];
    // Push empty placeholders for days before Sunday
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null);
    }
    // Push days starting from Sunday
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    return days;
  };

  const windowSize = useWindowSize();


  function StrategyPerformance(params: { name: string }) {
    return (
      <p style={windowSize <= 500 ? styles.metricsHeaderResponsive : styles.metricsHeader} className="relative">
        {params.name} <div className={windowSize <= 500 ? "small-dot-responsive" : "small-dot"}></div>
      </p>
    );
  }

  return (
    <div className='trading-calendar'>
      <StrategyPerformance name={'Yearly Performances'} />
      <div style={styles.dateFilterContainer}>
        <div style={styles.dateRange}>
          {/* <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              From
            </label>
            <input
              className='special-label'
              style={styles.input}
              type='date'
              value={dateRange.from.toISOString().split('T')[0]}
              onChange={(e) =>
                handleChangeDateRange(new Date(e.target.value), dateRange.to)
              }
            />
          </div>
          <div style={styles.fromDate}>
            <label style={styles.label} className='special-label'>
              To
            </label>
            <input
              className='special-label'
              style={styles.input}
              type='date'
              value={dateRange.to.toISOString().split('T')[0]}
              onChange={(e) =>
                handleChangeDateRange(dateRange.from, new Date(e.target.value))
              }
            />
          </div> */}
        </div>
        <div className='year-selector'>
          {/* <label>Select Year:</label>
        <select
          value={selectedYear}
          onChange={handleYearChange}
          style={styles.select}
        >
          <option style={styles.option} value={2024}>
            2024
          </option>
          <option value={2025}>2025</option>
        </select> */}
          <div style={styles.yearContainer}>
            {/* <button
            className={selectedYear === 2024 ? "button-selected": ""}
              style={{
                backgroundColor: '#F3F6FF',
                color: '#000000B2',
                border: selectedYear === 2024 ? '1px solid black' : 'none',
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16.5px',
                letterSpacing: '0.06em',
                textAlign: 'left',
                margin: '0px',
                padding: '4px 13px 3px 11px',
                borderRadius: '15px',
              }}
              onClick={() => handleYearChange(2024)}
            >
              2024
            </button> */}
            <button
            className={selectedYear === 2023 ? "button-selected": ""}
              style={{
                backgroundColor: '#F3F6FF',
                color: '#000000B2',
                border: selectedYear === 2023 ? '1px solid black' : 'none',
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16.5px',
                letterSpacing: '0.06em',
                textAlign: 'left',
                margin: '0px',
                padding: '4px 13px 3px 11px',
                borderRadius: '15px',
              }}
              onClick={() => handleYearChange(2023)}
            >
              2023
            </button>
            <button
            className={selectedYear === 2022 ? "button-selected": ""}
              style={{
                backgroundColor: '#F3F6FF',
                color: '#000000B2',
                border: selectedYear === 2022 ? '1px solid black' : 'none',
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16.5px',
                letterSpacing: '0.06em',
                textAlign: 'left',
                margin: '0px',
                padding: '4px 13px 3px 11px',
                borderRadius: '15px',
              }}
              onClick={() => handleYearChange(2022)}
            >
              2022
            </button>
            <button
            className={selectedYear === 2021 ? "button-selected": ""}
              style={{
                backgroundColor: '#F3F6FF',
                color: '#000000B2',
                border: selectedYear === 2021 ? '1px solid black' : 'none',
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16.5px',
                letterSpacing: '0.06em',
                textAlign: 'left',
                padding: '4px 13px 3px 11px',
                borderRadius: '15px',
                margin: '0px',
              }}
              onClick={() => handleYearChange(2021)}
            >
              2021
            </button>
            <button
            className={selectedYear === 2020 ? "button-selected": ""}
              style={{
                backgroundColor: '#F3F6FF',
                color: '#000000B2',
                border: selectedYear === 2020 ? '1px solid black' : 'none',
                fontFamily: 'Poppins',
                fontSize: '11px',
                fontWeight: 400,
                lineHeight: '16.5px',
                letterSpacing: '0.06em',
                textAlign: 'left',
                padding: '4px 13px 3px 11px',
                borderRadius: '15px',
                margin: '0px',
              }}
              onClick={() => handleYearChange(2020)}
            >
              2020
            </button>
          </div>
          {/*  */}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
        className='border-box'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: 'fit-content',
          }}
        >
          <div className='special-wrapper'>
            <div className='yearly-calendar'>
              <div className='yearly-calendar-week-indicator'>
                <div>M</div>
                <div>W</div>
                <div>F</div>
              </div>
              <div className='calendar-grid'>
                {monthsInYear.map((month, monthIndex) => (
                  <div key={month} className='month'>
                    {/* <h4>{month}</h4> */}
                    <p>{month}</p>
                    <div className='days'>
                      {daysInMonthStartingFromSunday(monthIndex).map((day) => {
                        if (day === null) {
                          return <div key={Math.random()} className='day-block empty' />;
                        } else {
                          const dateStr = `${selectedYear}-${(monthIndex + 1)
                            .toString()
                            .padStart(2, '0')}-${day.toString().padStart(2, '0')}` as keyof DailyAlgoResults;
                          const pnlColorClass = getPnLColorClass(dateStr);
                          return (
                            <div
                              key={day}
                              className={`day-block ${pnlColorClass}`}
                              title={`PnL: ${dailyAlgoResults[dateStr] || 0}`}
                            >
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={styles.totalPNL} className='total-pnl'>
            <div style={styles.infoContainer}>
              Capital
              <span style={styles.infoSpan}>
                {/* <span>{CapitalDeployed}</span> */}
                <span> {formatNumber(capitalDeployed.toString())}</span>
              </span>{' '}
              {/* Add capital deployed value */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '31px' }}>
              Total P&L{' '}
              <span style={styles.infoSpan}>
                <span style={{ color: totalPnLColor }}>
                  {formatNumber(String(totalPnL))}
                </span>
              </span>{' '}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '31px' }}>
              Total P&L %{' '}
              <span style={styles.infoSpan}>
                {' '}
                <span style={{ color: totalPnLColor }}>
                  {totalPnLPercentage.toFixed(1)}%
                </span>
              </span>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    margin: 1,
    padding: '0 0 0 0',
    paddingBottom: '9px',
    width: 'fit-content',
    fontWeight: Theme.fontWeight.semiBold,
    borderBottom: '2px solid ' + Theme.colors.whiteGrey70,
    marginTop: '20px',
  },
  metricsHeaderResponsive: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: "0.63px solid " + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: "9.27px"
  },
  totalPNL: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '31px',
    padding: '31px 0 0 0',
    alignItems: 'center',
    marginLeft: '37px',
    width: 'fit-content',
  },
  dateFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: '10px',
  },

  yearContainer: {
    display: 'flex',
    border: '1px solid #B2B2B2B2',
    // maxWidth: '182px',
    borderRadius: '30px',
    marginRight: '20px',
    padding: '12px 18px',
    gap: '14px',
    marginTop: '16px',
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '270px',
    margin: '33px 0 50px 20px',
    gap: '10px',
  },
  fromDate: {
    display: 'flex',
    flexDirection: 'column' as const,
    maxWidth: '110px' as const,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
  },

  label: {
    position: 'absolute' as const,
    top: '-5px',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    left: '30%',
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  input: {
    width: '100%',
    textDecoration: 'none',
    border: 'solid 0.5px #B2B2B2B2',
    height: '30px',
  },
  select: {
    display: 'inline-block',
  },
  option: {
    display: 'inline-block',
    marginRight: '10px',
  },
  infoContainer: {
    display: 'flex',
    gap: 13,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoSpan: {
    backgroundColor: '#F3F6FF',
    padding: '5px',
    fontWeight: '700',
    borderRadius: '6px',
    marginLeft: '10px',
  },
};

export default BacktestTradingCalendar;
