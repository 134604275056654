import {
  BackTestAPIResponse,
  DailyBackTestResult,
  IResultsAPILegs,
  IResultsSummary,
} from '../API/API.interface';

export default class TestResultsParser {
  public rawResults: BackTestAPIResponse;
  public resultsLength: number;

  public constructor(data: BackTestAPIResponse) {
    this.rawResults = data;
    this.resultsLength = this.rawResults.response_data?.dailyAlgoResults
      ? Object.keys(this.rawResults.response_data?.dailyAlgoResults).length
      : 0;
  }

  public getDailyViewKeys(from: number, to: number): string[] {
    return Object.keys(this.rawResults.response_data?.dailyAlgoResults).slice(
      from,
      to
    );
  }

  public getPageCountsToShow(countPerPage: number): number {
    return Math.floor(this.resultsLength / countPerPage + 1);
  }

  public getStrategyLegs(): IResultsAPILegs[] {
    return this.rawResults.response_data.algoAPIRequest.strategies.sort(
      (a, b) => a.sequence - b.sequence
    );
  }

  public getMargins(): number {
    return this.rawResults.response_data.resultsSummary.estimatedMargin;
  }

  public getMonthlyView(): { [key: string]: { [key: string]: number } } {
    return this.rawResults.response_data.monthAlgoResults;
  }

  public getWeekDayView(): { [key: string]: { [key: string]: number } } {
    return this.rawResults.response_data.dayAlgoResults;
  }

  public getResultsSummary(): IResultsSummary {
    return this.rawResults.response_data.resultsSummary;
  }

  public getResultsSummaryPct(): IResultsSummary {
    return this.rawResults.response_data.resultsSummaryPct;
  }

  public getDailyViewDataFor(key: string): DailyBackTestResult {
    return this.rawResults.response_data.dailyAlgoResults[key];
  }

  public getMaxDrowdownData(): { [key: string]: { [key: string]: number } } {
    return this.rawResults.response_data.dayAlgoResults;
  }
  public getRelativePerformanceData(): { [key: string]: { [key: string]: number } } {
    return this.rawResults.response_data.dayAlgoResults;
  }
}
