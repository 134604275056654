import React, { useEffect, useMemo, useState } from 'react';
import { AuthModule } from '../API';
import Header, { CurrentPage } from './Header/Header';
import { Theme } from '../Utils/Constants';
import Footer from './Footer';
import NavBar from './NavBar';
import LiveTodayPageContent from './AIStrategies/LiveTodayHomePage';
import { useNavigate } from 'react-router-dom';
import useWindowSize from './Hooks/useWindowSize';
import { SaveBacktestRequestBody } from '../API/HomePageAPI';

const LiveTodayPage: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated);
        if (!isAuthenticated) {
          navigate('/home');
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
      });
  }, [navigate]);

  const windowSize = useWindowSize();
  const updateRequestBody = (newRequestBody: any) => {};
  const [isUpdated, setIsUpdated] = useState(false);

  return (
    <>
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div style={styles.container}>
        {windowSize > 800 && (
          <div style={styles.navBar}>
            <NavBar
              isUpdated={isUpdated}
              updateRequestBody={updateRequestBody}
            />
          </div>
        )}
        <div
          className={
            windowSize <= 800 ? 'w-full live-today-responsive-container' : ''
          }
        >
          <div
            style={
              windowSize <= 800
                ? styles.fullPageContainerResponsive
                : styles.fullPageContainer
            }
          >
            <LiveTodayPageContent />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex' as const,
  },
  navBar: {
    minWidth: Theme.navBarWidth,
    width: Theme.navBarWidth,
  },
  fullPageContainer: {
    padding: Theme.gapSmall,
    width: '80vw',
    height: '100vh',
  },
  fullPageContainerResponsive: {
    padding: Theme.gapSmall,
    width: '100%',
    height: '100vh',
  },
};

export default LiveTodayPage;
