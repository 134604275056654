import React, { useEffect, useMemo, useState } from 'react';
import { Theme } from '../Utils/Constants';
import { AuthModule } from '../API/Auth';
import StarIcon from '@mui/icons-material/Star';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTube from '@mui/icons-material/YouTube';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import ProfilePic from '../assets/images/profilePic.jpg';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import socialProofImg from '../assets/images/socialproof1.png';
import explrImg from '../assets/images/exploreStrategy.png';
import backtestImg from '../assets/images/backtesting.png';
import newFeatureImg from '../assets/images/newfeature.png';
import arrowImg from '../assets/homepageimages/doubarrow.png';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './Dialogs/Loader';
import borderedRect from '../assets/images/bordered-rect.svg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  SaveBacktestRequestBody,
  deleteSavedBacktest,
  fetchHomePageData,
  getBacktestsData,
} from '../API/HomePageAPI';
import { FixedNumber, formatMoneyManaged, formatNumber } from '../API/FormatNumber';
import { decodeJwtToken } from '../API/DecodeJWTFunction';
import useAppStore from '../store/app.store';
import { UserDetails } from '../API/ProfileAPI';
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../Utils/Toast';
interface NavBarProps {
  // onDataLoaded?: (data: SaveBacktestRequestBody) => void;
  updateRequestBody: (newRequestBody: SaveBacktestRequestBody) => void;
  // setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdated: boolean;
}

interface backtestDataInterface {
  backtest_name: string;
  user_id: number;
  date: string;
  backtest_id: number;
  json_data: SaveBacktestRequestBody;
}
const NavBar: React.FC<NavBarProps> = ({ updateRequestBody, isUpdated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [backtestData, setBacktestData] = useState<backtestDataInterface[]>([]);
  const navigate = useNavigate();
  const { setSelectedSectionIndex } = useAppStore();
  const [userData, setUserData] = useState<any | null>(null);
  const [savedbacktestName, setSavedbacktestName] = useState('');
  const [savedbacktestID, setSavedbacktestID] = useState(0);

  let user_ID: string = '';
  const decodedToken = decodeJwtToken();
  user_ID = String(decodedToken?.user_id ?? '');

  useMemo(() => {
    AuthModule.getInstance()
      .getAboutMe()
      .then((res) => {
        // setUserLevel(res.user_level);
        // setUserRating(res.star_rating);
        // setProfileName(res.name);
        // setProfileImage(res.user_image);
        setUserData(res);
        return res;
      })
      .catch((err) => {
        console.error('error from navBar', err);
      });
  }, []);

  // const [profileName, setProfileName] = useState('');
  const { setProfileName } = useAppStore();
  const { profileName } = useAppStore();
  const {
    profileImage,
    userBacktestData,
    userLevel,
    starRating,
    totalPnlValue,
    monthlyPnlValue,
  } = useAppStore();
  const { setProfileImage } = useAppStore();
  // const [userLevel, setUserLevel] = useState('');
  // const [userRating, setUserRating] = useState('');
  // const [profileImage, setProfileImage] = useState('');
  const [isSection1Open, setIsSection1Open] = useState(true);
  const [isSection2Open, setIsSection2Open] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [homePageData, setHomePageData] = useState<any>(null);
  const [showBacktestDeletePopup, setShowBacktestDeletePopup] = useState(false);
  const [isChanges, setIsChanges] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchHomePageData();
        setHomePageData(data);
      } catch (error: any) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const toggleSection1 = () => {
    setIsSection1Open(!isSection1Open);
  };

  const toggleSection2 = () => {
    setIsSection2Open(!isSection2Open);
  };

  const slides = [
    {
      id: 0,
      content: `${
        homePageData && homePageData['total_ai_users']
      }+ users are now using moneyy.ai now.`,
      img: socialProofImg,
      header: 'Social Proofing',
      link: '/strategy',
      text1: 'Explore Now',
    },
    {
      id: 1,
      img: socialProofImg,
      content: `${
        homePageData && homePageData['total_backtest']
      }+ backtested are done till now`,
      header: 'Social Proofing',
      link: '/backtesting',
      text1: 'Backtest Now',
    },
    {
      id: 2,
      content: `${
        homePageData && formatMoneyManaged(homePageData['total_capital_deployed'])
      }+ are live with our Algo strategies`,
      img: socialProofImg,
      header: 'Social Proofing',
      link: '/strategy',
      text1: 'Check Out Now',
    },
    {
      id: 3,
      content: `${
        homePageData && homePageData['total_backtest']
      }+ Strategy X gave x% returns this month and Y% projected this year.`,
      img: explrImg,
      header: 'Explore Strategies',
      link: '/backtesting',
      text1: 'Go Live Now',
    },
    {
      id: 4,
      content: `${
        homePageData && FixedNumber(Number(homePageData['ai_strategies_cagr']))
      }% + CAGR`,
      img: explrImg,
      header: 'Explore Strategies',
      link: '/strategy',
      text1: 'Explore Now',
    },
    {
      id: 5,
      content: `${homePageData && homePageData['total_broker']}+ broker`,
      img: backtestImg,
      header: 'Backtesting',
      link: '/strategy',
      text1: 'Click Here',
    },
    {
      id: 6,
      content: `We have launched ${
        homePageData && homePageData['no_of_strategies']
      }+ strategies.`,
      img: newFeatureImg,
      header: 'New Feature Launch',
      text2: 'Try Now !',
      text1: 'Click Here',
      link: '/strategy',
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const goBackward = () => {
    setCurrentSlide((prevSlide) => {
      if (prevSlide - 1 < 0) {
        return slides.length - 1;
      } else {
        return (prevSlide - 1) % slides.length;
      }
    });
  };
  const goForward = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleBacktestDeletePopUp = (
    backtestName: string,
    backtest_id: number
  ) => {
    setShowBacktestDeletePopup(!showBacktestDeletePopup);
    setSavedbacktestName(backtestName);
    setSavedbacktestID(backtest_id)
  };

  const handleDeleteBacktest = async (backtestID: number, backtestName:string) => {
    try {
      const response = await deleteSavedBacktest(Number(user_ID), backtestID);
      if (response.response_code === 200) {
        Toast.success(backtestName + ' is deleted succesfully');
        setIsChanges(!isChanges);
      } else {
        Toast.error('Failed to delete backtest');
      }
    } catch (error) {
      console.error('Error deleting backtest:', error);
      Toast.error('An error occurred while deleting backtest');
    }
    setShowBacktestDeletePopup(!showBacktestDeletePopup);
  };

  useEffect(() => {
    setIsLoading(false);
    const timer = setTimeout(() => {}, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const fetchBacktestData = async () => {
      try {
        const responseData = await getBacktestsData(Number(user_ID));
        // console.log(responseData);
        setBacktestData(responseData);
        if (responseData !== undefined) {
          // onDataLoaded(responseData);
        }
      } catch (error) {
        console.log('error fetching backtest data', error);
      }
    };

    // console.log(isUpdated);

    fetchBacktestData();
  }, [user_ID, isUpdated, isChanges]);

  // console.log(backtestData);

  const handleBacktestClick = (jsonData: SaveBacktestRequestBody) => {
    updateRequestBody(jsonData);
    console.log(jsonData);
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  //

  return (
    <>
      <div style={styles.container} className='relative border-box'>
        <img
          src={profileImage ? profileImage : ProfilePic}
          // src={ProfilePic}
          alt='logo'
          style={styles.profileImageStyle}
          // onLoad={handleImageLoad}
        />

        <p style={styles.profileName}>
          {profileName ? profileName : 'Profile Name'}
        </p>
        <div style={styles.ratingCont}>
          <p style={styles.rating}>
            <span style={styles.ratingText}>
              {userLevel ? userLevel : 'Beginner'}
            </span>
            <StarIcon style={styles.ratingIcon} />
            <span style={{ paddingLeft: Theme.gapTiny }}>
              {/* {userRating ? userRating : 1} */}
              {starRating}
            </span>
          </p>
        </div>

        <div style={styles.borderTop}>
          <p onClick={toggleSection1} style={styles.collapseHeader}>
            <AssessmentIcon style={{ paddingRight: Theme.gapTiny }} />
            Your Stats
            {isSection1Open ? (
              <KeyboardArrowUpIcon style={styles.collapseButtonIcon} />
            ) : (
              <KeyboardArrowDownIcon style={styles.collapseButtonIcon} />
            )}
          </p>
          {isSection1Open && (
            <div style={styles.yourstats} className='flex flex-center flex-col'>
              <div style={styles.yourstatsrow} className='flex flex-center'>
                <span style={styles.yourstatsrowstart}>Total Backtests</span>{' '}
                {/* <span style={styles.yourstatsrowend}>
                  {userData?.user_backtest_data.user_backtest_data}
                </span> */}
                <div style={styles.statsRectContainer}>
                  <div style={styles.statsRectFirstPart}></div>
                  <div
                    style={{ ...styles.statsRectangleInner, width: '31.37px' }}
                  >
                    {/* {userData?.user_backtest_data.user_backtest_data} */}
                    {userBacktestData}
                  </div>
                </div>
              </div>
              <div className='flex flex-center' style={styles.yourstatsheading}>
                <img src={arrowImg} alt='' />
                Live Strategies
              </div>
              <div style={styles.yourstatsrow} className='flex flex-center'>
                <span style={styles.yourstatsrowstart}>Total P/L</span>{' '}
                {/* <span style={styles.yourstatsrowend}>50K(10%)</span> */}
                <div style={styles.statsRectContainer}>
                  <div style={styles.statsRectFirstPart}></div>
                  <div
                    style={{ ...styles.statsRectangleInner2, width: '73.29px' }}
                  >
                    {formatNumber(totalPnlValue?.toString() ?? '')}
                  </div>
                </div>
              </div>
              <div style={styles.yourstatsrow} className='flex flex-center'>
                <span style={styles.yourstatsrowstart}>Monthly P/L</span>{' '}
                {/* <span style={styles.yourstatsrowend}>-700(-10%)</span> */}
                <div style={styles.statsRectContainer}>
                  <div style={styles.statsRectFirstPart}></div>
                  <div
                    style={{ ...styles.statsRectangleInner2, width: '92.63px' }}
                  >
                    {formatNumber(monthlyPnlValue?.toString() ?? '')}
                  </div>
                </div>
              </div>
              <button
                className='btn'
                style={styles.viewBtn}
                onClick={() => {
                  setSelectedSectionIndex(4);
                  navigate('/profile/leaderboard', { replace: false });
                }}
              >
                View Leaderboard
              </button>
            </div>
          )}
        </div>

        <div>
          <p onClick={toggleSection2} style={styles.collapseHeader}>
            <BookmarkIcon style={{ paddingRight: Theme.gapTiny }} /> Saved
            BackTests
            {isSection2Open ? (
              <KeyboardArrowUpIcon style={styles.collapseButtonIcon} />
            ) : (
              <KeyboardArrowDownIcon style={styles.collapseButtonIcon} />
            )}
          </p>
          {isSection2Open && (
            <div style={styles.backTestList}>
              {backtestData && backtestData.map((data, index) => {
                return (
                  <p
                    style={{
                      cursor: 'pointer',
                      justifyContent: 'space-between',
                      padding: '0 5%',
                    }}
                    key={index}
                    className='flex flex-center'
                  >
                    <span onClick={() => handleBacktestClick(data.json_data)}>
                      {data.backtest_name}{' '}
                    </span>
                    <span
                      className='flex flex-center'
                      onClick={() =>
                        handleBacktestDeletePopUp(
                          data.backtest_name,
                          data.backtest_id
                        )
                      }
                    >
                      <DeleteForeverIcon />
                    </span>
                  </p>
                );
              })}
            </div>
          )}
        </div>

        <div style={styles.noticeContainer}>
          <div style={styles.notices}>
            <div style={styles.sliderContainer}>
              <p style={styles.noticeHeader}>{slides[currentSlide].header}</p>
              <div style={styles.noticeDescription}>
                {/* <MobileScreenShareIcon />
            <p>100 users are now using moneyy.ai now.</p> */}
                <div style={styles.slide}>
                  <span>
                    <img src={slides[currentSlide].img} alt='' />
                  </span>
                  <br />
                  <span>{slides[currentSlide].content}</span>
                  <br />
                  {slides[currentSlide].text2 && (
                    <div>{slides[currentSlide].text2}</div>
                  )}

                  {slides[currentSlide].text1 && (
                    <Link to={slides[currentSlide].link}>
                      {slides[currentSlide].text1}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={styles.navigateButtonContainer}
            className='flex flex-center'
          >
            <KeyboardDoubleArrowLeftIcon onClick={goBackward} />
            {currentSlide + 1}
            <KeyboardDoubleArrowRightIcon onClick={goForward} />
          </div>
        </div>

        <div style={styles.socialDiv}>
          <div style={styles.socialList}>
            {/* <Facebook style={styles.socialIcon} /> */}
            <Instagram
              style={styles.socialIcon}
              onClick={() => {
                // navigate("/learn");
                window.open('https://www.instagram.com/_moneyy.ai/', '_blank');
              }}
            />
            <Twitter
              style={styles.socialIcon}
              onClick={() => {
                // navigate("/learn");
                window.open('https://twitter.com/moneyy_ai', '_blank');
              }}
            />
            <LinkedInIcon
              style={styles.socialIcon}
              onClick={() => {
                // navigate("/learn");
                window.open(
                  'https://www.linkedin.com/company/moneyy-ai/',
                  '_blank'
                );
              }}
            />
            <YouTube
              style={styles.socialIcon}
              onClick={() => {
                // navigate("/learn");
                window.open(
                  'https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ',
                  '_blank'
                );
              }}
            />
          </div>
        </div>

        {showBacktestDeletePopup && (
          <section className='subscribe-popup-card'>
            <div className='kill-popup-card flex flex-center flex-col'>
              <span
                style={{
                  fontSize: Theme.fontSizes.h2,
                  fontWeight: Theme.fontWeight.medium,
                  color: Theme.colors.black,
                }}
              >
                {/* {selectedStrategy.strategy_name} */}
                {savedbacktestName}
              </span>
              <p
                style={{
                  fontSize: Theme.fontSizes.h4,
                  fontWeight: Theme.fontWeight.light,
                  color: Theme.colors.black,
                }}
              >
                Please Confirm to delete {savedbacktestName}
              </p>
              <div
                className='flex-center popup-card-button-row'
                style={{ width: '100%' }}
              >
                <button
                  className='popup-subscribe-btn flex flex-center'
                  onClick={() => handleDeleteBacktest(savedbacktestID, savedbacktestName)}
                >
                  <DoneIcon />
                  Confirm
                </button>
                <button
                  style={{
                    color: '#2747dd',
                    backgroundColor: '#fff',
                    border: 'solid 0.5px',
                    padding: '-1px',
                  }}
                  className='popup-subscribe-btn flex flex-center '
                  onClick={() => setShowBacktestDeletePopup(false)}
                >
                  <CloseIcon />
                  Cancel
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

const styles = {
  noticeContainer: {
    margin: Theme.gapLarge,
    borderRadius: Theme.borderRadiusLarge,
  },
  noticeHeader: {
    paddingTop: Theme.gapSmall,
    fontSize: Theme.fontSizes.h5,
  },
  notices: {
    backgroundColor: Theme.colors.yellow,
    borderRadius: Theme.borderRadiusLarge,
  },
  noticeDescription: {
    paddingTop: Theme.gapSmall,
    backgroundColor: Theme.colors.white,
    borderRadius: Theme.borderRadiusLarge,
    boxShadow: `0px -1px 5px 0px ${Theme.colors.whiteGrey70}`,
    fontSize: Theme.fontSizes.h5,
    minHeight: '138px',
  },
  backTestList: {
    color: Theme.colors.white,
  },
  socialIcon: {
    color: Theme.colors.blueSolid,
    fontSize: Theme.fontSizes.h2,
  },
  socialList: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: Theme.gapSmall,
    paddingBottom: Theme.gapSmall,
    cursor: 'pointer',
  },
  collapseButtonIcon: {
    color: Theme.colors.white,
    fontSize: Theme.fontSizes.h2,
    margin: 'auto 0 auto auto',
    alignItems: 'right',
  },
  socialDiv: {
    position: 'absolute' as const,
    bottom: '0px',
    width: Theme.navBarWidth,
    backgroundColor: Theme.colors.white,
    marginTop: '58%',
    borderBottom: `solid 0.5px ${Theme.colors.whiteGrey}`,
    borderRight: `solid 0.5px ${Theme.colors.whiteGrey}`,
    // boxShadow: 'rgba(0, 0, 0, 0.25) 4px 5px 0.5',
  },
  collapseHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: Theme.colors.white,
    fontSize: Theme.fontSizes.h5,
    padding: Theme.gapTiny,
    margin: 0,
    backgroundColor: Theme.colors.blueSolid,
    borderBottom: `1px solid ${Theme.colors.white}34`,
    textAlign: 'left' as const,
  },
  borderTop: {
    marginTop: Theme.gapSmall,
    borderTop: `1px solid ${Theme.colors.white}34`,
  },
  ratingCont: {
    margin: 'auto',
    width: 'fit-content',
    padding: Theme.gapTiny,
  },
  ratingIcon: {
    paddingLeft: Theme.gapTiny,
    color: Theme.colors.blueSolid,
    width: Theme.fontSizes.h4,
  },
  rating: {
    margin: 'auto',
    borderRadius: Theme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: Theme.fontSizes.h6,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: Theme.gapTiny,
    paddingRight: Theme.gapTiny,
    backgroundColor: Theme.colors.yellow,
  },
  ratingText: {
    fontSize: Theme.fontSizes.h6,
    borderRight: `1px solid ${Theme.colors.black70}`,
    paddingRight: Theme.gapTiny,
  },
  profileName: {
    color: Theme.colors.white,
    fontSize: Theme.fontSizes.h4,
    fontWeight: Theme.fontWeight.semiBold,
    // padding: Theme.gapSmall,
    margin: '-5px',
  },
  profileImageStyle: {
    width: Theme.profileImageWidth,
    height: Theme.profileImageWidth,
    border: '1px solid white',
    borderRadius: Theme.borderRadius,
  },
  container: {
    paddingTop: Theme.gapLarge,
    textAlign: 'center' as const,
    alignItems: 'center' as const,
    width: Theme.navBarWidth,
    height: '100%',
    backgroundColor: Theme.colors.blueSolid,
    // position: 'fixed' as const,
  },

  sliderContainer: {
    width: '100%',
    // height: '120px',
    // position: 'relative' as const,
  },
  slide: {
    width: '100%',
    height: '100%',
    color: '#333',
    fontSize: '14px',
    marginTop: '0',
  },
  navigateButtonContainer: {
    color: Theme.colors.white,
    gap: '10px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  yourstats: {
    marginTop: '10px',
  },
  yourstatsrow: {
    background: Theme.colors.white,
    width: '83%',
    height: '30px',
    marginBottom: '10px',
    justifyContent: 'space-between',
    // padding: '5px 7px',
    padding: '1px',
    fontSize: Theme.fontSizes.h6,
    borderRadius: '5px',
    position: 'relative' as const,
  },
  statsRectContainer: {
    display: 'flex',

    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    gap: '0px',
  },
  statsRectFirstPart: {
    width: '17.65px',
    height: '32.24px',
    backgroundSize: 'cover',
    backgroundImage: `url(${borderedRect})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '5px center',
  },
  statsRectangleInner: {
    height: '32.24px',
    display: 'flex',

    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    background: '#FFE484',
    borderRadius: '5.37415px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',

    fontWeight: 500,

    color: 'rgba(0, 0, 0, 0.7)',
  },
  statsRectangleInner2: {
    height: '32.24px',
    display: 'flex',

    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    background: '#FFE484',
    borderRadius: '5.37415px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',

    fontWeight: 500,
    fontSize: '11.8231px',
    lineHeight: '18px',

    color: 'rgba(0, 0, 0, 0.7)',
  },
  yourstatsrowstart: {
    padding: '5px 7px',
  },

  yourstatsrowend: {
    maxWidth: '35%',
    minWidth: '10%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Theme.colors.yellow,
    fontSize: Theme.fontSizes.h6,
    position: 'absolute' as const,
    right: '0',
    padding: '0 10px',
    fontWeight: Theme.fontWeight.medium,
    letterSpacing: '1px',
  },
  yourstatsheading: {
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fontSizes.h5,
    color: Theme.colors.white,
    marginBottom: '10px',
    width: '90%',
    left: '0',
    justifyContent: 'flex-start',
  },

  viewBtn: {
    border: 'solid',
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h6,
    background: Theme.colors.yellow,
  },
};

export default NavBar;
