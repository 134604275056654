import DateInput from './Elements/DateInput';
import { Theme } from '../../Utils/Constants';
import TimeInput from './Elements/TimeInput';
import IndexInput from './Elements/IndexInput';
import StrategyInput from './Elements/StrategyInput';
import Slippagesinput from './Elements/SlippagesInput';
import useWindowSize from '../Hooks/useWindowSize';
import { useEffect, useState } from 'react';

export interface IStrategyInput {
  fromDate: Date;
  toDate: Date;
  entryTime: Date;
  exitTime: Date;
  index: string;
  strategy: string;
  slippage: string;
}

export interface IStrategyInputsProps {
  strategyInputs: IStrategyInput;
  onInputsChanged: (strategyInput: IStrategyInput) => void;
  showMore: boolean;
  savedData?: any;
}

export const SavedStrategyInputs: React.FC<IStrategyInputsProps> = ({
  onInputsChanged,
  strategyInputs,
  showMore,
}) => {
  let minDate = new Date('2011-01-01 09:00');
  let maxDate = new Date('2023-05-30 09:00');
  let todayAt9AM = new Date('2023-06-14 09:00');
  let todayAt4PM = new Date('2023-06-14 16:00');

  const { fromDate, toDate, entryTime, exitTime, index, strategy, slippage } =
    strategyInputs;
  // console.log(fromDate, toDate, entryTime, exitTime, index, strategy, slippage);

  const [savedfromDate, setSavedFromDate] = useState(fromDate);

  const setFromDate = (value: Date) => {
    // const updatedInputs = { ...strategyInputs, fromDate: value };
    // strategyInputs.fromDate = value;
    // onInputsChanged(updatedInputs);
  };

  const setToDate = (value: Date) => {
    const updatedInputs = { ...strategyInputs, toDate: value };
    // onInputsChanged(updatedInputs);
  };

  const setEntryTime = (value: Date) => {
    // strategyInputs.entryTime = value;
    // onInputsChanged(strategyInputs);
    const updatedInputs = { ...strategyInputs, entryTime: value };
    // onInputsChanged(updatedInputs);
  };

  const setExitTime = (value: Date) => {
    // strategyInputs.exitTime = value;
    // onInputsChanged(strategyInputs);
    const updatedInputs = { ...strategyInputs, exitTime: value };
    // onInputsChanged(updatedInputs);
  };

  const setIndex = (value: string) => {
    // strategyInputs.index = value;
    // onInputsChanged(strategyInputs);
    const updatedInputs = { ...strategyInputs, index: value };
    // onInputsChanged(updatedInputs);
  };

  const setStrategy = (value: string) => {
    // strategyInputs.strategy = value;
    // onInputsChanged(strategyInputs);
    const updatedInputs = { ...strategyInputs, strategy: value };
    // onInputsChanged(updatedInputs);
  };

  const setSlippage = (value: string) => {
    // strategyInputs.slippage = value;
    // onInputsChanged(strategyInputs);
    const updatedInputs = { ...strategyInputs, slippage: value };
    // onInputsChanged(updatedInputs);
  };

  const handleFromDateChange = (value: Date) => {
    // const updatedInputs = { ...strategyInputs, fromDate: value };
    // setSavedFromDate(value);
    // onInputsChanged(updatedInputs);
  };

  const windowSize = useWindowSize();
  useEffect(() => {
    // console.log(fromDate);
    setSavedFromDate(fromDate);
  }, [fromDate]);
  // console.log(savedfromDate);

  return (
    <>
      {/* <h1>{String(savedfromDate)}</h1> */}
      <div
        style={
          windowSize <= 670 ? styles.inputBoxesResponsive : styles.inputBoxes
        }
      >
        {/* {savedData} */}

        <IndexInput label='Index' onChange={setIndex} defaultIndex={index} />
        <DateInput
          label='Start Date'
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleFromDateChange}
          defaultDate={fromDate}
        />
        <DateInput
          label='End Date'
          minDate={minDate}
          maxDate={maxDate}
          onChange={setToDate}
          defaultDate={toDate}
        />
        <TimeInput
          label='Entry Time'
          minTime={todayAt9AM}
          maxTime={todayAt4PM}
          onChange={setEntryTime}
          defaultTime={entryTime}
        />
        <TimeInput
          label='Exit Time'
          minTime={todayAt9AM}
          maxTime={todayAt4PM}
          onChange={setExitTime}
          defaultTime={exitTime}
        />
        {showMore && (
          <StrategyInput
            label='Select Strategy'
            onChange={setStrategy}
            strategy={strategy}
          />
        )}
        {showMore && (
          <Slippagesinput
            label='Select Strategy'
            onChange={setSlippage}
            slippage={slippage}
          />
        )}
      </div>
    </>
  );
};

export const styles = {
  inputBoxes: {
    justifyContent: 'left',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    backgroundColor: Theme.colors.backgroundF3,
    padding: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
  },
  inputBoxesResponsive: {
    display: 'grid',
    justifyContent: 'left',
    alignItems: 'center',
    flexDirection: 'row' as 'row',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: Theme.colors.backgroundF3,
    padding: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
    rowGap: 26.25,
    columnGap: 4,
  },
};

export default SavedStrategyInputs;
