import React, { useState, useEffect } from 'react';
import PricingPlans from '../Landing/PricingPlans';
import { Styles, Theme } from '../../Utils/Constants';
import { Switch } from '@mui/joy';
import { CheckCircle } from '@mui/icons-material';
import './PlansAndPricing.css';
import PurchaseDialog from '../Dialogs/PurchaseDialog';
import rightArrowImg from '../../assets/homepageimages/rightarrowimg.png';
import leftArrowImg from '../../assets/homepageimages/leftarrowimg.png';
import {
  PlanData,
  UpgradePlan,
  fetchAverageCAGRAndCapital,
  fetchUserPlanPricing,
} from '../../API/ProfileAPI';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import Loader from '../Dialogs/Loader';
import useWindowSize from '../Hooks/useWindowSize';
import useInitiateRazorpay from './initiateRazorpay';
import { AuthModule } from '../../API';
import { useNavigate } from 'react-router-dom';
import getStrategyList from '../AIStrategies/AIstrategiesdata';
import {
  SubscriptionDetailResponseData,
  getSubscriptionDetails,
} from '../../API/LiveTodayAPI';
import useAppStore from '../../store/app.store';
import { formatNumber } from '../../API/FormatNumber';
import Toast from '../../Utils/Toast';

export function generateRandomString() {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}
export function addDaysToDate(daysToAdd: any) {
  const today = new Date();
  today.setDate(today.getDate() + daysToAdd);

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

interface userPlan {
  plan_type: string;
  max_lots: number;
  min_lots: number;
  plan_interval: string;
}

interface PlanAndPricingProps {
  special?: boolean;
  width?: number;
}

interface StrategyDetail {
  strategy_id: string;
  strategy_name: string;
  strategy_description: string;
  author: string;
  strategy_image: string;
  strategy_tag: string;
  minimum_investment_capital: string;
  is_live: boolean;
  created_date: string;
  last_updated: string;
  cagr_pct: number | string;
  risk: string;
  capital_deployed: string;
}

const PlanAndPricing: React.FC<PlanAndPricingProps> = ({ special, width }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const windowSize = useWindowSize();
  const [isBacktest, setIsBacktest] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [isBuyNowClicked, setIsBuyNowClicked] = useState(false);
  // const [planData, setplanData] = useState(null);
  const [planData, setPlanData] = useState<PlanData | null>(null);
  const [StrategyPlanData, setStrategyPlanData] = useState<PlanData | null>(
    null
  );
  const [isStrategyBuyNowClicked, setIsStrategyBuyNowClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlan, setcurrentPlan] = useState('free plan');
  const [strategyPlanDetails, setStrategyPlanDetails] = useState<any>({});
  const [backtestingPlanDetails, setBacktestingPlanDetails] = useState<any>({});
  const [isSliderValueChanged, setIsSliderValueChanged] = useState(false);
  // console.log(isMonthly);
  const [sliderValue, setSliderValue] = useState<number>(1);
  const [sliderState, setSliderState] = useState<number>(1);
  const [slots, setSlots] = useState<number>(1);
  const [userPnL, setUserPnL] = useState<number>(0);
  const [userBackTest, setUserBackTest] = useState<number>(0);
  const { averageCAGR, averageMinCapitalRequired } = useAppStore();

  const [backtest, setBacktest] = useState(false);

  const [strategy, setStrategy] = useState(true);
  const initiateRazorpay = useInitiateRazorpay();
  const [userDetails, setUserDetails] = useState<any>({});
  const [AllAIStrategiesdata, setAllAIStrategiesdata] = useState<any>([]);
  const [matchedStrategiesdata, setMatchedStrategiesdata] = useState<any>([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState<
    SubscriptionDetailResponseData[]
  >([]);
  const [finalAmtWithGST, setFinalAmtWithGST] = useState(999);
  const [finalAmtWithoutGST, setFinalAmtWithoutGST] = useState(999);

  const userPlan: userPlan = {
    plan_type: '',
    max_lots: 0,
    min_lots: 0,
    plan_interval: '',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value);
    if (inputValue >= sliderState) {
      setSliderValue(inputValue);
    }
  };

  useEffect(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated);
      })
      .catch((error) => {
        setIsAuthenticated(false);
      });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetchUserPlanPricing();
        const { strategy, backtesting } = userData.user_plan_details;
        setUserDetails(userData);
        setStrategyPlanDetails(strategy);
        setBacktestingPlanDetails(backtesting);
        if (
          userData?.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
        ) {
          setUserPnL(
            userData.user_pnl_data?.data?.user_pnl_ranking_view[0]?.total_pnl
          );
        } else {
          setUserPnL(0);
        }
        if (userData?.runbacktest_count) {
          setUserBackTest(userData.runbacktest_count.total_backtesting_count);
        } else {
          setUserBackTest(0);
        }

        if (strategyPlanDetails) {
          setSliderValue(strategy.max_lots);
          setSliderState(strategy.max_lots);
          setSlots(strategy.max_lots);
        }
        if (userData) {
          setIsLoading(false);
        }
        if (backtesting.plan_name === 'Free') {
          setIsBuyNowClicked(false);
        } else if (backtesting.plan_name === 'PREMIUM') {
          setIsBuyNowClicked(true);
        }
        if (strategy.plan_name === 'Free') {
          setIsStrategyBuyNowClicked(false);
        } else if (strategy.plan_name === 'PREMIUM') {
          setIsStrategyBuyNowClicked(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStrategyListData = async () => {
      const data: any = await getStrategyList();
      setAllAIStrategiesdata(data);
    };
    const fetchSubscribedStrategyListData = async () => {
      const details = await getSubscriptionDetails();
      const responseDataArray = details.Response_data;
      setSubscriptionDetails(responseDataArray);
    };

    if (isAuthenticated) {
      fetchUserData();
      fetchStrategyListData();
      fetchSubscribedStrategyListData();
    } else if (special) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    isSliderValueChanged,
    isBuyNowClicked,
    isStrategyBuyNowClicked,
    isAuthenticated,
  ]);

  useEffect(() => {
    const subscribedStrategyIds = subscriptionDetails.map(
      (strategy) => strategy.strategy_id
    );
    const matchedStrategies: StrategyDetail[] = [];
    AllAIStrategiesdata.forEach((strategy: StrategyDetail) => {
      if (subscribedStrategyIds.includes(strategy.strategy_id)) {
        matchedStrategies.push(strategy);
      }
    });
    setMatchedStrategiesdata(matchedStrategies);
  }, [subscriptionDetails, AllAIStrategiesdata]);

  const strings = {
    backtestDesc: 'Backtest your strategies with historical data',
    strategyDesc: 'Subscribe to deploy AI strategies in your account',
  };

  const strategyPlans = [
    {
      advantage: true,
      text: 'Access to All AI Strategies',
    },
    {
      advantage: true,
      text: 'One-Click Strategy Subscription',
    },
    {
      advantage: true,
      text: 'Live P&L, Manage Strategies',
    },
    {
      advantage: true,
      text: 'Your Strategy P&L Console',
    },
    {
      advantage: true,
      text: '20+ Performance Ratios',
    },
    {
      advantage: true,
      text: '6+ Comparison Charts',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: true,
      text: 'Trade Level Deep-dive',
    },
  ];

  const freemiums = [
    {
      advantage: true,
      text: 'Save & Download Backtest      ',
    },
    {
      advantage: true,
      text: '20+ Performance Ratios      ',
    },
    {
      advantage: true,
      text: '6+ Comparison Charts      ',
    },
    {
      advantage: true,
      text: 'Trade Level Deep-dive',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: false,
      text: '10 Free Monthly Backtests      ',
    },
    {
      advantage: false,
      text: 'Only BankNifty',
    },
    {
      advantage: false,
      text: ' Last 6 Months Data Only      ',
    },
    {
      advantage: false,
      text: ' Slow Backtest, 5min    ',
    },
  ];
  const premiumsAdvantage = [
    {
      advantage: true,
      text: 'Unlimited Backtests      ',
    },
    {
      advantage: true,
      text: 'All Instruments    ',
    },
    {
      advantage: true,
      text: '6+ Years Data      ',
    },
    {
      advantage: true,
      text: 'Fast Backtest < 10sec',
    },
    {
      advantage: true,
      text: 'Real-time Market Feed',
    },
    {
      advantage: true,
      text: 'Everything in Freemium    ',
    },
  ];
  const getPricingPlansSwitcher = () => {
    return (
      <div style={styles.buttonSwitcher}>
        <p style={styles.hintText}>{strings.strategyDesc}</p>
        <img style={styles.arrowLeft} src={leftArrowImg} alt='' />
        <div style={styles.buttonBox}>
          <button
            style={!isBacktest ? { margin: 0 } : styles.selectedButton}
            onClick={() => setIsBacktest(false)}
          >
            Strategy Plans
          </button>
          <button
            style={
              isBacktest
                ? {
                    margin: 0,
                  }
                : styles.selectedButton
            }
            onClick={() => setIsBacktest(true)}
          >
            BackTest Plans
          </button>
        </div>
        <img style={styles.arrowRight} src={rightArrowImg} alt='' />
        <p style={styles.hintText}>{strings.backtestDesc}</p>
      </div>
    );
  };

  const getPayFrequencySwitcher = () => {
    return isBacktest ? (
      <>
        <div style={styles.frequencyBox}>
          <p style={styles.freqText}>Bill Monthly</p>
          <Switch
            size='lg'
            onChange={() => setIsMonthly(!isMonthly)}
            checked={!isMonthly}
            slotProps={{
              track: {
                sx: {
                  justifyContent: 'space-around',
                  backgroundColor: Theme.colors.blueSolid,
                  color: Theme.colors.yellow,
                },
              },
            }}
            sx={{
              '--Switch-thumbSize': '24px',
              '--Switch-trackWidth': '64px',
              '--Switch-trackHeight': '32px',
            }}
          />
          <p style={styles.freqText}>
            Bill Annually{' '}
            <span style={{ color: Theme.colors.blueSolid }}>( Save 40% )</span>
          </p>
        </div>
      </>
    ) : (
      <></>
    );
  };

  // console.log(planData);

  const getAdvantagesList = (advantages: any) => {
    return advantages.map((freemium: { advantage: any; text: string }) => {
      return (
        <div style={styles.advatages}>
          <CheckCircle
            style={{
              color: freemium.advantage
                ? Theme.colors.blueSolid
                : Theme.colors.red,
              marginRight: 10,
              marginLeft: 12,
            }}
          />
          <p style={{ margin: 5 }}>{freemium.text}</p>
        </div>
      );
    });
  };

  const handleClose = () => {
    setOpenPurchaseDialog(false);
  };

  async function handleClick() {
    let yearlyPlanMonthAmt = 599;
    let yearlyTotalAmount = yearlyPlanMonthAmt * 12;
    const gstPercentage = 0.18;

    // caluclatning gst amount
    const gstAmount = yearlyTotalAmount * gstPercentage;
    let finalAmount = (yearlyTotalAmount + gstAmount) * 100;
    let finalAmountRounded = Math.round(finalAmount);

    // calcualting monthly amount
    let montlyAmt = 999;
    let gstMontlyAmount = montlyAmt * gstPercentage;
    let totalMonthlyAmt = Math.round(montlyAmt + gstMontlyAmount);
    console.log(gstMontlyAmount, totalMonthlyAmt);

    if (isAuthenticated) {
      let paymentDetails = {
        amount: isMonthly ? totalMonthlyAmt * 100 : finalAmountRounded,
        currency: 'INR',
        name: 'Moneyy.ai',
        description: 'BACK TESTING PLAN',
        image: '/images/logo.png ',
        order_id: '',
        prefill: {
          name: userDetails.name,
          email: userDetails.email, // Use the email from paymentDetails if available
          contact: userDetails.phone_number, // Use the contact from paymentDetails if available
        }, // Use the name from paymentDetails
      };
      initiateRazorpay(paymentDetails)
        .then(async (paymentResponse) => {
          console.log('Payment successful! Response:', paymentResponse);
          // Handle successful payment response here
          if (paymentResponse.razorpay_payment_id) {
            setOpenPurchaseDialog(true);
            setIsBuyNowClicked(true);
            try {
              if (isBacktest) {
                userPlan.plan_type = 'BACK TESTING';
                if (isMonthly) {
                  userPlan.max_lots = 1;
                  userPlan.plan_interval = 'Monthly';
                } else {
                  // userPlan.plan_type = 'STRATEGY'
                  userPlan.max_lots = 12;
                  userPlan.plan_interval = 'Yearly';
                }
              } else {
                // 1 lot = 10 dollar
                userPlan.plan_type = 'STRATEGY';
                userPlan.max_lots = sliderValue;
              }
              const upgradePlanData = await UpgradePlan(userPlan, true);
              setPlanData(upgradePlanData);
              // console.log(planData);
            } catch (error) {
              console.error('Error occured in plan api');
            }
          }
        })
        .catch((error) => {
          console.error('Payment failed:', error);
          console.error('Payment failed:', error.error.description);
          // Handle payment failure here
          Toast.error(error.error.description);
        });
    } else {
      navigate('/login');
      window.scrollTo(0, 0); // Scroll to the top of the page directly
    }
  }

  //

  // let finalAmtWithGST = (finalAmount + gstAmt).toFixed(2);

  useEffect(() => {
    // let totalCAGR = 0;
    // // console.log(totalCAGR);

    // AllAIStrategiesdata.map((data: any) => {
    //   // console.log(data.CAGR_PCT);
    //   const cagrPCT = parseFloat(data.CAGR_PCT);
    //   // console.log(cagrPCT);
    //   totalCAGR += cagrPCT;
    // });

    // const averageCAGR = totalCAGR / AllAIStrategiesdata.length;
    let finalAmount =
      ((averageMinCapitalRequired *
        (averageCAGR / 100) *
        (sliderValue - slots)) /
        12) *
      0.106;
    // console.log(averageCAGR, averageMinCapitalRequired);

    setFinalAmtWithoutGST(Math.round(finalAmount));

    let gstAmt = (finalAmount * 18) / 100;
    let finalAmountWithGST = finalAmount + gstAmt;
    setFinalAmtWithGST(Math.round(finalAmountWithGST));
  }, [AllAIStrategiesdata, sliderValue, slots, userPnL]);
  // console.log(finalAmtWithGST);

  async function handleStrategyBuyClick() {
    // console.log(finalAmtWithGST);

    if (isAuthenticated) {
      let paymentDetails = {
        // amount: (sliderValue - slots) * 1000,
        amount: finalAmtWithGST * 100,
        currency: 'INR',
        name: 'Moneyy.ai',
        description: 'STRATEGY PLAN',
        image: '/images/logo.png ',
        order_id: '',
        prefill: {
          name: userDetails.name,
          email: userDetails.email, // Use the email from paymentDetails if available
          contact: userDetails.phone_number, // Use the contact from paymentDetails if available
        }, // Use the name from paymentDetails
      };
      initiateRazorpay(paymentDetails)
        .then(async (paymentResponse) => {
          console.log('Payment successful! Response:', paymentResponse);
          // Handle successful payment response here
          if (paymentResponse.razorpay_payment_id) {
            // 1 lot = 10 dollar
            userPlan.plan_type = 'STRATEGY';
            userPlan.max_lots = sliderValue;
            userPlan.min_lots = slots;
            setOpenPurchaseDialog(true);
            setSliderState(sliderValue);
            setIsStrategyBuyNowClicked(true);
            try {
              // userPlan.min_lots = slots === 1 ? slots : slots;
              const upgradePlanData = await UpgradePlan(userPlan);
              setIsSliderValueChanged(!isSliderValueChanged);
              setStrategyPlanData(upgradePlanData);
              setSliderValue(sliderValue);
              console.log(planData);
            } catch (error) {
              console.error('Error occured in plan api');
            }
          }
        })
        .catch((error) => {
          console.error('Payment failed:', error);
          // Handle payment failure here
          Toast.error(error.error.description);
        });
    } else {
      navigate('/login');
      window.scrollTo(0, 0); // Scroll to the top of the page directly
    }
  }

  // console.log(strategyPlanDetails.plan_name === 'PREMIUM');

  const getFreeBox = () => {
    return (
      <>
        <p style={Styles.h1Text}>Free</p>
        {backtestingPlanDetails.plan_name === 'PREMIUM' && (
          <>
            <p>No Fees, Just Benefits</p>
            <p style={Styles.h1Text}>₹0/Month</p>
            <button style={styles.yellowButton}>Claim Now!</button>
          </>
        )}

        <div style={{ ...Styles.bottomBorderLine, ...styles.divider }}></div>
        <div style={{ textAlign: 'start', padding: '0 24px' }}>
          {getAdvantagesList(freemiums)}
        </div>
      </>
    );
  };

  const getPremiumBox = () => {
    return (
      <>
        <p style={Styles.h1Text}>Premium</p>
        <p> {isMonthly ? 'Your Ideal Plan' : 'Save 40% on Yearly'} </p>
        <p style={Styles.h1Text}>{isMonthly ? '₹999/Month' : '₹599/month'}</p>
        <p>(excl. 18% GST)</p>
        <div style={{ ...Styles.bottomBorderLine, ...styles.divider }}></div>
        <div style={{ textAlign: 'start', padding: '0 24px' }}>
          {getAdvantagesList(premiumsAdvantage)}
        </div>

        {isBuyNowClicked ? (
          // <button>Bought</button>
          backtestingPlanDetails?.plan_interval === 'Monthly' ? (
            <button style={styles.yellowButton} onClick={handleClick}>
              Upgrade Yearly
            </button>
          ) : (
            ''
          )
        ) : (
          <button style={styles.yellowButton} onClick={handleClick}>
            Buy Now!
          </button>
        )}

        {/*  */}
      </>
    );
  };

  const getStrategyPlans = () => {
    return (
      <div
        style={
          windowSize <= 800
            ? styles.expandingContainerStrategyResponsive
            : strategyPlanDetails.plan_name !== 'PREMIUM'
            ? styles.expandingContainerStrategy
            : styles.newPremiumBox
        }
      >
        <div
          style={{
            ...styles.buyStrategyPlans,
            // position: 'relative',
            // width: '100%',
          }}
        >
          <div>
            <h2 style={styles.strategyText}>
              Pay as per your Investment Capital
            </h2>
            {slots > 1 ? (
              <p>You currently possess {slots} lots.</p>
            ) : (
              <p>It's free for first Lot</p>
            )}
            {/* <p>It's free for first Lot</p> */}
          </div>
          <div style={styles.rangeDiv}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column' as const,
                justifyContent: 'center' as const,
              }}
            >
              <div
                style={{
                  margin: 0,
                  backgroundColor: '#2747DD',
                  color: 'white',
                  padding: '0px 8px',
                  borderRadius: '6px',
                  width: 'fit-content',
                  height: 'fit-content',
                  marginBottom: '-10px',
                  marginLeft: '10px',
                }}
              >
                <div className='button-per-month'>
                  {/* {sliderValue >= 1 ? ` ${<CurrencyRupeeIcon/>} ${sliderValue * 10}/month` : 'Free'} */}
                  {sliderValue >= 1 ? (
                    // <span>&#8377; {finalAmtWithGST}/month</span>
                    <span>
                      {' '}
                      {sliderValue} {sliderValue > 1 ? 'lots' : 'lot'}
                    </span>
                  ) : (
                    'Free'
                  )}
                </div>
              </div>
              <input
                type='range'
                min={1}
                max={100}
                value={sliderValue}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div style={styles.rangeDivText}>
              <h2
                style={styles.strategyText}
                className={'strategyTextResponsive1'}
              >
                Total
              </h2>
              <h2
                style={styles.strategyText}
                className={'strategyTextResponsive2'}
              >
                {/* &#8377;{sliderValue * 10}/month */}
                {/* &#8377;{finalAmtWithoutGST}/month + GST */}
                {finalAmtWithoutGST > 0 ? (
                  <span className='flex flex-center'>
                    {<CurrencyRupeeIcon />}
                    {finalAmtWithoutGST}/month + GST
                  </span>
                ) : (
                  'Free'
                )}
              </h2>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column' as const,
              justifyContent: 'center' as const,
              alignItems: 'center' as const,
            }}
          >
            {slots === sliderValue ? (
              // <button>Bought</button>
              ''
            ) : (
              <button
                style={styles.yellowButton}
                onClick={handleStrategyBuyClick}
              >
                {slots > 1 ? `Upgrade Plan` : `Upgrade Plan`}
              </button>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column' as const,
                justifyContent: 'center' as const,
                alignItems: 'center' as const,
                marginBlock: '24px',
              }}
            >
              {special ? (
                <>
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    Freemium: 1 strategy, 1 lot. Pricing based on all AI
                    strategies' CAGR
                  </p>
                </>
              ) : (
                <span
                  className='flex flex-center flex-col'
                  style={{ display: '', gap: '6px' }}
                >
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    Freemium: 1 strategy, 1 lot. Pricing based on all AI
                    strategies' CAGR
                  </p>
                  {/* <p
                    style={{
                      margin: 0,
                    }}
                  >
                    Pricing based on all AI Strategies' CAGR (Monthly Refresh)
                  </p> */}
                </span>
              )}
            </div>
          </div>
          {/* {strategyPlanDetails.plan_name === "PREMIUM" && (
            <div
              style={{
                ...styles.currentPlan,
                position: "absolute",
                top: "-20px",
                right: "0",
                left: "440px"
              }}
            >
              Current Plan
            </div>
          )} */}
        </div>

        {/* 2nd div  */}
        <div style={styles.allPlans} className={'margin-0'}>
          <h2
            style={
              windowSize < 540
                ? {
                    ...styles.strategyText,
                    marginLeft: '14px',
                    textAlign: 'center',
                  }
                : {
                    ...styles.strategyText,
                    marginLeft: '14px',
                  }
            }
          >
            All Plans Include
          </h2>
          {strategyPlans.map((plan, index) => {
            return (
              <div style={styles.advatages} key={index}>
                <CheckCircle
                  style={{
                    color: plan.advantage
                      ? Theme.colors.blueSolid
                      : Theme.colors.red,
                    marginRight: 5,
                    marginLeft: 12,
                  }}
                />
                <p style={{ margin: 5 }}>{plan.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getActualPlans = () => {
    return (
      <>
        {isBacktest ? (
          <div style={styles.expandingContainer}>
            <div
              style={{
                ...styles.freePlanBox,
                border: isBuyNowClicked ? '' : '4px solid rgba(39, 71, 221, 1)',
              }}
            >
              {!isBuyNowClicked && (
                <div style={{ ...styles.currentPlan, position: 'relative' }}>
                  Current Plan
                </div>
              )}
              <div>{getFreeBox()}</div>
            </div>
            <div
              style={{
                ...styles.eachPlanBox,
                backgroundColor: Theme.colors.blueTainted15,
                border: isBuyNowClicked ? '4px solid rgba(39, 71, 221, 1)' : '',
              }}
            >
              {isBuyNowClicked && (
                <div
                  style={{ ...styles.currentPlanPremium, position: 'relative' }}
                >
                  Current Plan
                </div>
              )}
              {getPremiumBox()}
            </div>
          </div>
        ) : (
          getStrategyPlans()
        )}
      </>
    );
  };

  function DropdownIcon(props: any) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='7'
        fill='none'
        viewBox='0 0 16 7'
        {...props}
      >
        <path
          fill='#000'
          d='M7.68 6.814L.81 1.681c-.332-.248-.332-.649 0-.897l.8-.598c.331-.247.868-.248 1.2-.001l5.47 4.068L13.753.185c.331-.247.868-.246 1.198 0l.802.6c.331.247.331.648 0 .896L8.88 6.814c-.332.248-.87.248-1.2 0z'
        ></path>
      </svg>
    );
  }

  const getResponsivePlans = () => {
    return (
      <div
        className='get-responsive-plans'
        style={width ? { width: `${width}px` } : {}}
      >
        <div className='get-responsive-plan'>
          <div
            className='get-responsive-plan-space-between pointer'
            onClick={() => {
              setBacktest(!backtest);
              setStrategy(false);
            }}
          >
            Backtest Plans
            <DropdownIcon className={`pointer ${backtest ? 'rotated' : ''}`} />
          </div>
          <div
            className='get-responsive-plan-sub pointer'
            onClick={() => {
              setBacktest(!backtest);
              setStrategy(false);
            }}
          >
            Backtest your dream strategy in less than 5 seconds at an affordable
            price.
          </div>
          {backtest === true ? (
            <div className='get-responsive-plan-backtest'>
              <div className='get-responsive-plan-backtest-switcher'>
                <p className={'p-text-responsive'}>Bill Monthly</p>
                <Switch
                  size={'lg'}
                  onChange={() => setIsMonthly(!isMonthly)}
                  checked={!isMonthly}
                  slotProps={{
                    track: {
                      sx: {
                        justifyContent: 'space-around',
                        backgroundColor: Theme.colors.blueSolid,
                        color: Theme.colors.yellow,
                      },
                    },
                  }}
                  sx={{
                    '--Switch-thumbSize': '18px',
                    '--Switch-trackWidth': '50px',
                    '--Switch-trackHeight': '25px',
                  }}
                />
                <p className={'p-bold-text-responsive'}>
                  Bill Annually{' '}
                  <span style={{ color: Theme.colors.blueSolid }}>
                    (Save 40%)
                  </span>
                </p>
              </div>
              <div
                style={{
                  ...styles.freePlanBox,
                  border: isBuyNowClicked
                    ? ''
                    : '4px solid rgba(39, 71, 221, 1)',
                }}
              >
                {!isBuyNowClicked && (
                  <div style={{ ...styles.currentPlan, position: 'relative' }}>
                    Current Plan
                  </div>
                )}
                <div>{getFreeBox()}</div>
              </div>

              <div
                style={{
                  ...styles.eachPlanBox,
                  backgroundColor: Theme.colors.blueTainted15,
                  border: isBuyNowClicked
                    ? '4px solid rgba(39, 71, 221, 1)'
                    : '',
                }}
              >
                {isBuyNowClicked && (
                  <div
                    style={{
                      ...styles.currentPlanPremium,
                      position: 'relative',
                    }}
                  >
                    Current Plan
                  </div>
                )}
                {getPremiumBox()}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='get-responsive-plan'>
          <div
            className='get-responsive-plan-space-between pointer'
            onClick={() => {
              setStrategy(!strategy);
              setBacktest(false);
            }}
          >
            Strategy Plans
            <DropdownIcon className={`pointer ${strategy ? 'rotated' : ''}`} />
          </div>
          <div
            className='get-responsive-plan-sub pointer'
            onClick={() => {
              setStrategy(!strategy);
              setBacktest(false);
            }}
          >
            Subscribe and automate trading with our AI strategies in one-click
            and enjoy profits.
          </div>
          {strategy === true ? (
            <div className='responsive-pricing-strategy'>
              {getStrategyPlans()}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return null; // Return null or an empty string if the date is not available
    const dateObject = new Date(dateString);
    return dateObject.toISOString().split('T')[0]; // Extract and return only the date part
  };

  return (
    <div className='special-width-background'>
      {!special && (
        <>
          <h3 style={{ fontWeight: Theme.fontWeight.medium }} className='plan'>
            Current Plan
          </h3>
          <div className='priceCardplan'>
            <div className='priceCol1'>
              <h3 style={{ fontWeight: Theme.fontWeight.medium }}>
                {isBacktest
                  ? backtestingPlanDetails?.plan_name
                  : strategyPlanDetails?.plan_name}{' '}
                Plan
              </h3>
              <h5
                style={{ fontWeight: Theme.fontWeight.medium }}
                className='textSecondary'
              >
                End Date:{' '}
                {isBacktest
                  ? formatDate(backtestingPlanDetails?.expiry_date)
                  : formatDate(strategyPlanDetails?.expiry_date)}
              </h5>
            </div>
            <div className='priceCol2'>
              <h3 style={{ fontWeight: Theme.fontWeight.medium }}>
                Number of Backtests
              </h3>
              <h5 className='pricebacktest'>{userBackTest}</h5>
            </div>
            <div className='priceCol3'>
              <h3 style={{ fontWeight: Theme.fontWeight.medium }}>
                AI Strategies P&L
              </h3>
              <h5 className='pricePL'>{formatNumber(userPnL.toString())}</h5>
            </div>
          </div>
          <h3 style={{ fontWeight: Theme.fontWeight.medium }} className='plan'>
            Upgrade Your Account
          </h3>
          <h5
            style={{ fontWeight: Theme.fontWeight.medium }}
            className='textSecondary'
          >
            (Please select an Upgrade Option.)
          </h5>
        </>
      )}

      {windowSize > 800 && getPricingPlansSwitcher()}
      {windowSize > 800 && getPayFrequencySwitcher()}
      {windowSize > 800 && getActualPlans()}
      {windowSize <= 800 && getResponsivePlans()}

      <PurchaseDialog
        open={openPurchaseDialog}
        handleClose={handleClose}
        planData={isBacktest ? planData : StrategyPlanData}
      />
    </div>
  );
};

const styles = {
  claimBox: {
    display: 'flex',
    flexDirection: 'row' as const,
    backgroundColor: Theme.colors.blueTainted,
    color: Theme.colors.white,
    borderRadius: Theme.borderRadius,
  },
  advatages: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    margin: '10px 0',
  },
  divider: {
    width: '80%',
    backgroundColor: Theme.colors.grey70,
    left: '10%',
    height: 2,
  },
  yellowButton: {
    backgroundColor: Theme.colors.yellow,
    borderRadius: Theme.borderRadius,
    color: Theme.colors.black,
    padding: '10px 24px',
    margin: 10,
    '&:hover': {
      backgroundColor: 'rgb(206 186 115)',
    },
  },
  eachPlanBox: {
    borderRadius: Theme.borderRadius,
    backgroundColor: Theme.colors.white,
    width: 320,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px 14px',
    textAlign: 'center' as const,
    margin: 10,
  },
  freePlanBox: {
    borderRadius: Theme.borderRadius,
    backgroundColor: Theme.colors.white,
    width: 320,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: '6px 10px',
    textAlign: 'center' as const,
    margin: 10,
    height: 'fit-content',
  },
  currentPlanPremium: {
    color: 'white',
    backgroundColor: 'rgba(39, 71, 221, 1)',
    borderRadius: '22px',
    width: 'fit-content',
    padding: '4px',
    top: '-34px',
    left: '101px',
  },
  currentPlan: {
    color: 'white',
    backgroundColor: 'rgba(39, 71, 221, 1)',
    borderRadius: '22px',
    width: 'fit-content',
    padding: '4px',
    top: '-22px',
    left: '101px',
  },
  expandingContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
  },
  freqText: {
    margin: 24,
    fontSize: Theme.fontSizes.h3,
  },
  frequencyBox: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    margin: 'auto 24px 0 24px',
  },
  descText: {
    textAlign: 'center' as const,
  },
  selectedButton: {
    backgroundColor: Theme.colors.white,
    color: Theme.colors.blueSolid,
    margin: 0,
  },
  buttonBox: {
    margin: 'auto 24px 0 24px',
    backgroundColor: Theme.colors.blueSolid,
    borderRadius: Theme.borderRadius,
    padding: 0,
  },
  hintText: {
    textAlign: 'center' as const,
    backgroundColor: Theme.colors.white,
    padding: 10,
    borderRadius: 10,
    border: '1px dashed ' + Theme.colors.black70,
    fontSize: Theme.fontSizes.h6,
    maxWidth: 126,
  },
  buttonSwitcher: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
  },
  expandingContainerStrategy: {
    display: 'flex',
    flexDirection: 'row' as const,
    margin: '24px',
    // padding: '24px',
    width: '100%',
  },
  expandingContainerStrategyResponsive: {
    display: 'flex',
    flexDirection: 'column' as const,
    margin: '24px',
    padding: '24px',
  },
  buyStrategyPlans: {
    display: 'flex',
    flex: 0.6,
    flexDirection: 'column' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    borderRadius: Theme.borderRadius,
    background: 'linear-gradient(166.04deg, #9FB0FF 4.52%, #D6D9F5 92.5%)',
    padding: '84px 30px 84px 20px',
  },
  allPlans: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column' as const,
    backgroundColor: Theme.colors.yellow,
    borderRadius: Theme.borderRadius,
    padding: '84px 25px 84px 20px',
    // width: '110%',
  },
  strategyText: {
    fontWeight: '500',
    fontSize: '20px',
  },
  rangeDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column' as const,
    justifyContent: 'space-between' as const,
  },
  rangeDivText: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'center' as const,
  },
  newPremiumBox: {
    color: 'black',
    display: ' flex',
    // border: "4px solid rgba(39, 71, 221, 1)",
    margin: '48px 0',
    width: '100%',
  },
  arrowRight: {
    height: '46px',
    margin: '43px 10px 0 -20px',
  },
  arrowLeft: {
    height: '46px',
    margin: '43px -20px 0 10px',
  },
  //
};

export default PlanAndPricing;
