import React, { useState, useEffect } from 'react';
import getStrategyList from './AIstrategiesdata';
import useWindowSize from '../Hooks/useWindowSize';
import { set } from 'date-fns';
// import tradeImg from '../../assets/images/trade.jpg';
import Loader from '../Dialogs/Loader';
import { formatCAGR, formatNumber } from '../../API/FormatNumber';
interface StrategyCardProps {
  AIStrategiesdata: any;
  special?: boolean;
}

const StrategyCard: React.FC<StrategyCardProps> = ({
  AIStrategiesdata,
  special,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // const [AIStrategiesdata, setAIStrategiesdata] = useState<any>([]);
  const windowSize = useWindowSize();

  function getStrategyDetails(id: any) {
    window.location.href = `/strategypage/${id}`;
  }

  // console.log(AIStrategiesdata);
  if (!AIStrategiesdata) {
    return <Loader />;
  }

  if (AIStrategiesdata.length === 0) {
    return (
      <div
        style={
          windowSize <= 800
            ? {
                padding: '40px 0px',
                textAlign: 'center',
              }
            : {
                padding: '15rem',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }
        }
      >
        No AIStrategies available based on applied sorting and searching
      </div>
    );
  }

  return (
    <div
      style={special ? { height: '100%', width: '100%' } : { height: '100%' }}
    >
      {AIStrategiesdata?.map((data: any) => {
        return (
          <div
            className={
              special
                ? 'horizontal-card-responsive-special'
                : windowSize <= 900
                ? 'horizontal-card-responsive'
                : 'horizontal-card'
            }
            onClick={() => {
              if (!special) {
                getStrategyDetails(data.strategy_id);
              }
            }}
            key={data.strategy_id}
          >
            {windowSize <= 500 ? (
              <>
                <div
                  className={
                    special
                      ? 'left-half-resposive-special'
                      : 'left-half-resposive'
                  }
                >
                  <img
                    src={data.strategy_image}
                    // src={tradeImg}
                    alt='Trade Imagz'
                    className='card-image-responsive'
                  />
                  <div className='card-info-resposive'>
                    <div className='card-title-responsive'>
                      <h5 className='card-heading-responsive'>
                        {data.strategy_name}
                      </h5>
                      <div className='card-title-inner-responsive'>
                        <p className='card-author-responsive'>
                          by {data.author}
                        </p>
                        <div className='badge-container'>
                          <div className='badge flex-center new-badge-responsive'>
                            {data.Strategy_tag}
                          </div>
                          <div
                            className='badge flex-center risk-badge-responsive'
                            style={{
                              color:
                                data.risk === 'High Risk'
                                  ? '#F82929'
                                  : data.risk === 'Medium Risk'
                                  ? '#E7AF1D'
                                  : '#2ACD1C',
                            }}
                          >
                            {data.risk === 'Low Risk' ? (
                              <svg
                                className='low'
                                style={{ width: '16.991px', height: '9.329px' }}
                                xmlns='http://www.w3.org/2000/svg'
                                width='8'
                                height='15'
                                viewBox='0 0 8 15'
                                fill='none'
                              >
                                <path
                                  d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                                  fill='#2ACD1C'
                                />
                              </svg>
                            ) : data.risk === 'Medium Risk' ? (
                              <svg
                                className='medium'
                                style={{ width: '16.991px', height: '9.329px' }}
                                xmlns='http://www.w3.org/2000/svg'
                                width='14'
                                height='17'
                                viewBox='0 0 14 17'
                                fill='none'
                              >
                                <path
                                  d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                                  fill='#E7AF1D'
                                />
                              </svg>
                            ) : (
                              <svg
                                className='icon'
                                xmlns='http://www.w3.org/2000/svg'
                                width='6'
                                height='11'
                                viewBox='0 0 6 11'
                                fill='none'
                              >
                                <path
                                  d='M3.90316 3.33741L3.90316 10.8369H2.57103L2.57103 3.33741H0.572825L3.2371 0.8459L5.90137 3.33741H3.90316Z'
                                  fill='#F82929'
                                />
                              </svg>
                            )}

                            {data.risk}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className={
                    special
                      ? 'card-desc-responsive-special'
                      : 'card-desc-responsive'
                  }
                >
                  {data.strategy_description}
                </p>
              </>
            ) : (
              <div className='left-half'>
                <img
                  // src={data.Image}
                  // src={tradeImg}
                  src={data.strategy_image}
                  alt='Trade Imagz'
                  className='card-image'
                />
                <div className='card-info'>
                  <div className='card-title'>
                    <h5 className='card-heading'>{data.strategy_name}</h5>
                    <br />
                    <div className='badge-container flex'>
                      <div className='badge flex-center new-badge'>
                        {data.strategy_tag}
                      </div>
                      <div
                        className='badge flex-center risk-badge'
                        style={{
                          color:
                            data.risk === 'High Risk'
                              ? '#F82929'
                              : data.risk === 'Medium Risk'
                              ? '#E7AF1D'
                              : '#2ACD1C',
                        }}
                      >
                        {data.risk === 'Low Risk' ? (
                          <svg
                            // className='icon'
                            style={{
                              height: '15px',
                              width: '7px',
                              margin: '1px 2px 0 2px',
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            width='6'
                            height='11'
                            viewBox='0 0 12 17'
                            fill='none'
                          >
                            <path
                              d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                              fill='#2ACD1C'
                            />
                          </svg>
                        ) : data.risk === 'Medium Risk' ? (
                          <svg
                            // className='icon'
                            style={{
                              height: '15px',
                              width: '12px',
                              margin: '1px 2px 0 2px',
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            // width='8'
                            // height='18'
                            viewBox='0 0 17 18'
                            fill='none'
                          >
                            <path
                              d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                              fill='#E7AF1D'
                            />
                          </svg>
                        ) : (
                          <svg
                            // className='icon'
                            style={{
                              height: '12px',
                              width: '10px',
                              margin: '1px 2px 0 2px',
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 8 12'
                            fill='none'
                          >
                            <path
                              d='M3.90316 3.33741L3.90316 10.8369H2.57103L2.57103 3.33741H0.572825L3.2371 0.8459L5.90137 3.33741H3.90316Z'
                              fill='#F82929'
                            />
                          </svg>
                        )}
                        {data.risk}
                      </div>
                    </div>
                  </div>
                  <p className='card-desc'>{data.strategy_description}</p>
                  <p className='card-author'>by {data.Author}</p>
                </div>
              </div>
            )}

            <div
              className={
                windowSize <= 900 ? 'right-half-responsive' : 'right-half'
              }
            >
              <div
                className={
                  windowSize <= 900 ? 'card-metrics-responsive' : 'card-metrics'
                }
              >
                {windowSize <= 500 ? (
                  <>
                    <div>
                      <span className='span-cut'>
                        <p className='min-cap-responsive'>Min. Capital</p>
                        <p className='value-responsive'>
                          ₹ {formatNumber(data.minimum_investment_capital)}
                        </p>
                      </span>
                    </div>
                    <div>
                      <span className='span-cut'>
                        <p className='cap-deployed-responsive'>
                          Capital Deployed
                        </p>
                        <p className='value-responsive'>
                          ₹ {formatNumber(data.capital_deployed)}
                        </p>
                      </span>
                    </div>
                    <div>
                      <span className='span-cut'>
                        <p className='cagr-responsive'>CAGR</p>
                        <p className='value-responsive cagr-value-responsive'>
                          {special
                            ? data.cagr_pct
                            : formatCAGR(String(data.cagr_pct))}
                        </p>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <span className='span-cut'>
                        <p className='min-cap'>Min. Capital</p>
                        <p className='value'>
                          ₹ {formatNumber(data.minimum_investment_capital)}
                        </p>
                      </span>
                    </div>
                    <div>
                      <span className='span-cut'>
                        <p className='cap-deployed'>Capital Deployed</p>
                        <p className='value'>
                          ₹ {formatNumber(data.capital_deployed)}
                        </p>
                      </span>
                    </div>
                    <div>
                      <span className='span-cut'>
                        <p className='cagr'>CAGR</p>
                        <p className='value cagr-value'>
                          {formatCAGR(String(data.cagr_pct))}
                        </p>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { StrategyCard };
