import React from "react";

function DownloadIconResponsive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="14"
            fill="none"
            viewBox="0 0 14 17"
        >
            <path
                fill="#000"
                fillOpacity="0.7"
                d="M14 6h-4V0H4v6H0l7 7 7-7zM6 8V2h2v6h1.17L7 10.17 4.83 8H6zm-6 7h14v2H0v-2z"
            ></path>
        </svg>
    );
}

export default DownloadIconResponsive;
