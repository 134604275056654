import React, { useState, useEffect } from "react";
import DailyViewContainer from "../Results/DailyViewContainer";
import MonthlyViewContainer from "../Results/MonthlyViewContainer";
import WeekDayViewContainer from "../Results/WeekDayViewContainer";
import ResultsSummaryContainer from "../Results/ResultsSummaryContainer";
import TestResultsParser from "../../Utils/TestResultsParser";
import { BackTestAPIResponse } from "../../API/API.interface";
import { AuthModule } from "../../API/Auth";
import { Theme } from "../../Utils/Constants";
import PLLineGraph from "../Graphs/PLLineGraph/PLLineGraph";
import Loader from "../Dialogs/Loader";
import { decodeJwtToken } from "../../API/DecodeJWTFunction";
import FilterIcon from "../Vector Components/FilterIcon";
import { MaxDropDownGraph } from "../Graphs/MaxDrawdownGraph/MaxDrawDownGraph";
import TradingCalendar from "../Graphs/YearlyPerformance/TradingCalander";
import "./StrategyPnl.css";
import Footer from "../Footer";
import useWindowSize from "../Hooks/useWindowSize";

type StrategyPnLProps = {
  payload: {
    userId: string;
    filters: {
      strategy_id: string;
      from_date: string;
      to_date: string;
      instrument: string;
    };
  };
};

const StrategyPnL: React.FC<StrategyPnLProps> = ({
  payload,
}: StrategyPnLProps) => {

  const windowSize = useWindowSize();

  const [results, setResults] = useState<TestResultsParser>(null as any);
  const [showROIPerct, setROIPerct] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const decodedToken = decodeJwtToken();
  const userId = String(decodedToken?.user_id) || "";
  const [isAbsoluteSelected, setIsAbsoluteSelected] = useState(false);

  useEffect(() => {
    const apiPayload = {
      userId: userId,
      ...(payload && {
        filters: {
          strategy_id: payload.filters.strategy_id,
          from_date: payload.filters.from_date,
          to_date: payload.filters.to_date,
          instrument: payload.filters.instrument,
        },
      }),
    };

    const getResults = async () => {
      const accessToken = AuthModule.getInstance().getAccessToken();
      const apiUrl = process.env.REACT_APP_BASE_URL as string + 'strategy/user-strategy-performance';
      try {
        const response = await fetch(
          apiUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              ...apiPayload,
            }),
          }
        );
        setIsLoading(true);
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          if (jsonResponse == null) {
            setResults(jsonResponse)
          }
          showResults(jsonResponse as BackTestAPIResponse);
        } else {
          throw new Error("Network response was not ok.");
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error:", error);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    getResults();
  }, [payload]);

  function showResults(results: BackTestAPIResponse) {
    const optionsParse = new TestResultsParser(results);
    setResults(optionsParse);
  }

  const handleClick = () => {
    setIsAbsoluteSelected(!isAbsoluteSelected);
  };

  function getDailyResultsContainer() {
    return (
      <div
        style={{ ...styles.container, ...styles.marginTopper }}
        className="border-box strategy-pnl-items"
      >
        <StrategyPerformance name={"Trade Log"} />

        <DailyViewContainer key="DailyViewContainer" results={results} />
      </div>
    );
  }

  function getMonthlyViewContainer() {
    return (
      <div
        style={{ ...styles.container, ...styles.marginTopper }}
        className="border-box strategy-pnl-items"
      >
        {/* <p style={styles.metricsHeader}>Month Wise Breakup</p> */}
        <StrategyPerformance name={"Month Wise Breakup"} />
        <MonthlyViewContainer
          key="monthlyViewContainer"
          showPerct={showROIPerct}
          margins={results?.getMargins()}
          results={results?.getMonthlyView()}
        />
      </div>
    );
  }

  function getWeekDayViewContainer() {
    return (
      <div
        style={{ ...styles.container, ...styles.marginTopper }}
        className="border-box strategy-pnl-items"
      >
        <StrategyPerformance name={"Day Wise Breakup"} />
        <WeekDayViewContainer
          key="weeklyDayView"
          showPerct={showROIPerct}
          margins={results?.getMargins()}
          results={results?.getWeekDayView()}
        />
      </div>
    );
  }

  function getMaxDrawDownContainer() {
    return (
      <div style={styles.containerSpecialNew} className="border-box strategy-pnl-items">
        {/* <StrategyPerformance name={'Key Parameters'} /> */}
        <MaxDropDownGraph
          special={true}
          key="resultsSummaryContainer"
          results={results.rawResults}
        />
      </div>
    );
  }

  function getResultsSummaryContainer() {
    return (
      <div style={styles.containerSpecial} className="border-box strategy-pnl-items">
        <StrategyPerformance name={"Key Parameters"} />
        <ResultsSummaryContainer
          key="resultsSummaryContainer"
          data={isAbsoluteSelected?results?.getResultsSummary():results?.getResultsSummaryPct()}
          isAbsoluteSelected={!isAbsoluteSelected}
        />
      </div>
    );
  }

  if (isLoading) {
    return <Loader />;
  }
  function StrategyPerformance(params: { name: string }) {
    if (params.name === "Key Parameters") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={windowSize <= 500 ? styles.metricsHeaderResponsiveNoMarginBottom : styles.metricsHeader} className="relative">
            {params.name} <div className={windowSize <= 500 ? "small-dot-responsive" : "small-dot"}></div>
          </p>
          <div
        style={{
          borderRadius: '5px',
          width: '172px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '27px',
          boxShadow: isAbsoluteSelected
            ? 'none'
            : 'inset 4px 4px 5px 0px #BBBE189C',
          backgroundColor: '#FFE484',
          marginBottom: Theme.gap15,
        }}
      >
        
        <button
          style={{
            backgroundColor: isAbsoluteSelected ? '#FFFFFF' : 'rgba(0,0,0,0)',
            margin: '0',
            color: '#000000B2',
            height: '100%',
            padding: '4px 0px 3px 8px',
            width: '92px',
            boxShadow: isAbsoluteSelected
              ? 'inset 0px 1px 4px 0px #00000040'
              : 'none',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
          }}
          onClick={handleClick}
        >
          Percentage
        </button>
        <button
          style={{
            backgroundColor: isAbsoluteSelected ? 'rgba(0,0,0,0)' : '#FFFFFF',
            margin: '0',
            color: '#000000B2',
            height: '100%',
            padding: '4px 0px 3px 8px',
            width: '80px',
            boxShadow: isAbsoluteSelected
              ? 'none'
              : 'inset 0px 1px 4px 0px #00000040',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
          }}
          onClick={handleClick}
        >
          Absolute
        </button>
      </div>
          {/* {windowSize > 500 && <span
            onClick={() => alert("Filter")}
            className="filter-span-single-strategy-page"
          >
            {" "}
            <FilterIcon /> Filters
          </span>} */}
        </div>
      );
    } else {
      return (
        <p style={windowSize <= 500 ? styles.metricsHeaderResponsive : styles.metricsHeader} className="relative">
          {params.name} <div className="small-dot-responsive"></div>
        </p>
      );
    }
  }

  if (
    !results?.rawResults?.response_data?.algoAPIRequest ||
    Object.keys(results?.rawResults?.response_data?.algoAPIRequest).length === 0 ||
    !results?.rawResults?.response_data?.dailyAlgoResults ||
    Object.keys(results?.rawResults?.response_data?.dailyAlgoResults).length ===
    0 ||
    !results?.rawResults?.response_data?.dayAlgoResults ||
    Object.keys(results?.rawResults?.response_data?.dayAlgoResults).length === 0 ||
    !results?.rawResults?.response_data?.monthAlgoResults ||
    Object.keys(results?.rawResults?.response_data?.monthAlgoResults).length ===
    0 ||
    !results?.rawResults?.response_data?.yearlyAlgoResults ||
    Object.keys(results?.rawResults?.response_data?.yearlyAlgoResults).length === 0 || results == null || results.rawResults.response_data == null || results.rawResults.response_data == undefined
  ) {
    // If results are empty, display a message or do nothing
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        No Strategy Performance Available
      </div>
    );
  }

  return (
    <div style={styles.strategyPLContainer}>
      {isLoading && <Loader />}
      {results != null && (
        <div className="strategy-pnl">
          <div style={styles.graphContainer} className="strategy-pnl-summary">
            {getResultsSummaryContainer()}
          </div>

          {results.rawResults.response_data.dayAlgoResults && (
            <div style={styles.graphContainer} className="strategy-pnl-weekly">
              {getWeekDayViewContainer()}
            </div>
          )}
          {results.rawResults.response_data.monthAlgoResults && (
            <div style={styles.graphContainer} className="strategy-pnl-monthly">
              {getMonthlyViewContainer()}
            </div>
          )}
          {results.rawResults.response_data.dailyAlgoResults && (
            <div style={styles.graphContainer} className="strategy-pnl-yearly">
              <TradingCalendar results={results.rawResults} avgCapital = {results.getResultsSummary().estimatedMargin}/>

              {/* {getMaxDrawDownContainer()} */}
            </div>
          )}

          {results.rawResults.response_data.monthAlgoResults && (
            <div
              style={styles.graphContainer}
              className="strategy-pnl-relative"
            >
              <PLLineGraph
                special={true}
                unsortedData={results.rawResults.response_data.relative_performance}
              />
            </div>
          )}

          {results.rawResults.response_data.dailyAlgoResults && (
            <div style={styles.graphContainer} className="strategy-pnl-max">
              {getMaxDrawDownContainer()}
            </div>
          )}

          {results.rawResults.response_data.dailyAlgoResults && (
            <div style={styles.graphContainer} className="strategy-pnl-trade">
              {getDailyResultsContainer()}
            </div>
          )}
          <Footer />
        </div>
      )}
    </div>
  );
};

const styles = {
  strategyPLContainer: {
    width: "100%",
  },

  graphContainer: {
    minWidth: "100%",
    backgroundColor: "white",
    marginBottom: "64px",
  },
  marginTopper: {
    marginTop: "4px",
  },
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginBottom: 1,
    paddingBottom: Theme.gapSmall,
    width: "fit-content",
    fontWeight: Theme.fontWeight.semiBold,
    borderBottom: "2px solid " + Theme.colors.whiteGrey70,
    marginTop: "20px",
  },
  metricsHeaderResponsive: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: "0.63px solid " + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: "9.27px",
    marginBottom: "33px"
  },
  metricsHeaderResponsiveNoMarginBottom: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: "0.63px solid " + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: "9.27px",
  },
  headerText: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginTop: Theme.gapLarge,
  },
  buttonIconGrey: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginRight: Theme.gapTiny,
    margin: "auto",
    verticalAlign: "middle",
  },
  containerSpecial: {
    // backgroundColor: Theme.colors.backgroundF3,
    width: "100%",
    backgroundColor: "#F3F6FF",
    padding: Theme.gap26,
    borderRadius: Theme.borderRadiusLarge,
  },
  container: {
    // backgroundColor: Theme.colors.backgroundF3,
    width: "100%",
    backgroundColor: "#F3F6FF",
    padding: Theme.gap26,
    paddingLeft: "34px",
    borderRadius: Theme.borderRadiusLarge,
  },
  containerSpecialNew: {
    // backgroundColor: Theme.colors.backgroundF3,
    width: "100%",
    backgroundColor: "#F3F6FF",
    borderRadius: Theme.borderRadiusLarge,
  },
};

export default StrategyPnL;
