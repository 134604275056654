import React, { useState, useEffect } from 'react';

interface AIStrategiesFilterProps {
  setAIStrategiesdata: (data: any) => void;
  AIStrategiesdata: any;
}

const AIStrategiesFilter: React.FC<AIStrategiesFilterProps> = ({
  setAIStrategiesdata,
  AIStrategiesdata,
}) => {
  const [subscriptionType, setSubscriptionType] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState('Any');
  const [selectedRisk, setSelectedRisk] = useState('');
  const [tab, setTab] = useState('All');
  const [currentFilteredData, setCurrentFilteredData] =
    useState(AIStrategiesdata);
  const [noOfFilters, setNoOfFilters] = useState(0);

  useEffect(() => {
    let filteredData = AIStrategiesdata;

    if (investmentAmount !== 'Any') {
      filteredData = filteredData.filter((strategy: any) => {
        const capital = parseInt(
          strategy.minimum_investment_capital?.replace(/,/g, '')
        );
        if (isNaN(capital)) return false;
        switch (investmentAmount) {
          case 'Under 1 Lakh':
            return capital <= 100000;
          case 'Under 5 lakhs':
            return capital <= 500000;
          case 'Above 5 Lakhs':
            return capital >= 500000;
          default:  // This is not needed
            return true;
        }
      });
    }

    if (selectedRisk) {
      filteredData = filteredData.filter(
        (strategy: any) => strategy.Risk === selectedRisk
      );
    }

    setAIStrategiesdata(filteredData);
    setCurrentFilteredData(filteredData);
    setNoOfFilters(
      (investmentAmount !== 'Any' ? 1 : 0) + (selectedRisk ? 1 : 0)
    );
  }, [investmentAmount, selectedRisk, AIStrategiesdata, setAIStrategiesdata]);

  const handleInvestmentAmountChange = (value: string) => {
    setInvestmentAmount(value);
  };

  const handleRiskSelection = (value: string) => {
    setSelectedRisk(value);
  };

  const handleTabChange = (selectedTab: string) => {
    setTab(selectedTab);
  };

  const ClearAll = () => {
    setSelectedRisk('');
    setInvestmentAmount('Any');
    setSubscriptionType('');
    setAIStrategiesdata(AIStrategiesdata); // Reset to original data
    setNoOfFilters(0);
  };

  return (
    <div className='filters'>
      <div className='filter-title-bar'>
        <h3>
          Filters <span className='filter-count'>{noOfFilters}</span>
        </h3>
        <h3 onClick={ClearAll}>Clear All</h3>
      </div>
      <div className='filter-section'>
        <h3>Investment Amount</h3>
        <label>
          <input
            type='radio'
            value='Any'
            checked={investmentAmount === 'Any'}
            onChange={() => handleInvestmentAmountChange('Any')}
          />
          Any
        </label>
        <label>
          <input
            type='radio'
            value='Under 1 Lakh'
            checked={investmentAmount === 'Under 1 Lakh'}
            onChange={() => handleInvestmentAmountChange('Under 1 Lakh')}
          />
          Under 1 Lakh
        </label>
        <label>
          <input
            type='radio'
            value='Under 5 lakhs'
            checked={investmentAmount === 'Under 5 lakhs'}
            onChange={() => handleInvestmentAmountChange('Under 5 lakhs')}
          />
          Under 5 Lakhs
        </label>
        <label>
          <input
            type='radio'
            value='Above 5 Lakhs'
            checked={investmentAmount === 'Above 5 Lakhs'}
            onChange={() => handleInvestmentAmountChange('Above 5 Lakhs')}
          />
          Above 5 Lakhs
        </label>
      </div>
      <div className='filter-section'>
        <h3>Risk</h3>
        <div className='risk-options'>
          <div
            className={`risk-option flex-center ${
              selectedRisk === 'Low Risk' ? 'selected' : ''
            }`}
            onClick={() => handleRiskSelection('Low Risk')}
            style={{ border: selectedRisk === 'Low Risk' ? 'solid 1px' : '' }}
          >
            <svg
              className='low'
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='15'
              viewBox='0 0 8 15'
              fill='none'
            >
              <path
                d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                fill='#2ACD1C'
              />
            </svg>
            Low
          </div>
          <div
            className={`risk-option flex-center ${
              selectedRisk === 'Medium Risk' ? 'selected' : ''
            }`}
            onClick={() => handleRiskSelection('Medium Risk')}
            style={{
              border: selectedRisk === 'Medium Risk' ? 'solid 1px' : '',
              width: '70px',
            }}
          >
            <svg
              className='medium'
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='17'
              viewBox='0 0 14 17'
              fill='none'
            >
              <path
                d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                fill='#E7AF1D'
              />
            </svg>
            Medium
          </div>
          <div
            className={`risk-option flex-center ${
              selectedRisk === 'High Risk' ? 'selected' : ''
            }`}
            onClick={() => handleRiskSelection('High Risk')}
            style={{ border: selectedRisk === 'High Risk' ? 'solid 1px' : '' }}
          >
            <svg
              className='high'
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='15'
              viewBox='0 0 8 15'
              fill='none'
            >
              <path
                d='M5 4.04467L5 14.7168H3L3 4.04467L0 4.04467L4 0.499138L8 4.04467H5Z'
                fill='#F82929'
              />
            </svg>
            High
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIStrategiesFilter;
