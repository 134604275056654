import { DailyBackTestResult } from '../../API/API.interface';
import { Theme } from '../../Utils/Constants';
import useWindowSize from '../Hooks/useWindowSize';

interface IExpiryDateSections {
  data: DailyBackTestResult;
}

const DailyViewPerDayBox: React.FC<IExpiryDateSections> = (props) => {
  const dayStrs = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
  ];

  const { data } = props;

  function getStrategyLegs() {
    const legs = data.strategyLegs.sort((a, b) => a.sequence - b.sequence);
    return legs.map((leg) => {
      return (
        <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={leg.sequence}>
          {leg?.legPnL?.toFixed(2)}
          {/* <p style={styles.cellStringText}>{getSubStringForCell(leg)}</p> */}
        </td>
      );
    });
  }

  function getDateStr(dateStr: string) {
    const date = new Date(dateStr);
    const day = dayStrs[date.getDay()];
    return `${date.getDate()} ${date.toLocaleString('default', {
      month: 'short',
    })} ${date.getFullYear()}, ${day}`;
  }

  function getExpiryDate(tableName: string) {
    const last7Chars = tableName.slice(-7);
    const date = new Date(last7Chars);
    return `${date.getDate()} ${date.toLocaleString('default', {
      month: 'short',
    })} ${date.getFullYear()}`;
  }

  // function getSubStringForCell(leg: DailyLegResult) {
  //   const startPrice = leg?.startValue?.toFixed(2);
  //   const endPrice = leg?.endValue?.toFixed(2);
  //   const diff = (leg?.startValue - leg?.endValue).toFixed(2);
  //   const CEPE = leg?.isCE ? "CE" : "PE";
  //   const endTime = new Date(leg?.endTime);
  //   const endTimeStr = `${endTime.getUTCHours()}:${endTime.getUTCMinutes()}`;
  //   return `( ${startPrice}-${endPrice}=${diff}) ${data.strikePrice}${CEPE} ${endTimeStr}`;
  // }

  const { strategyLegs } = data;
  // console.log(strategyLegs.length);

  const windowSize = useWindowSize();

  return (
    <>
      {strategyLegs.map((leg, index) => (
        <tr key={index}>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`date-${index}`}>
            {getDateStr(leg.dateStr).split(',')[0]}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`expiryornonexpiry-${index}`}>
            {leg.expiryornonexpiry}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`indexcolumn-${index}`}>
            {leg.indexcolumn}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`positions-${index}`}>
            {leg.positions}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`entryprice-${index}`}>
            {leg.entryprice.toFixed(2)}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`exitprice-${index}`}>
            {leg.exitprice.toFixed(2)}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`entrytime-${index}`}>
            {leg.entrytime}
          </td>
          <td style={windowSize <= 500 ? styles.cellStyleResponsive : styles.cellStyle} key={`exittime-${index}`}>
            {leg.exittime}
          </td>
          {/* <td style={windowSize <= 500 ? styles.cellStyleResponsive: styles.cellStyle} key={`indiavix-${index}`}>
          {leg.indiavix}
      </td> */}
          {/* <td style={windowSize <= 500 ? styles.cellStyleResponsive: styles.cellStyle} key={`lotSize-${index}`}>
          {leg.lotSize}
      </td> */}
        </tr>
      ))}
    </>
  );
};

const styles = {
  cellStyle: {
    padding: Theme.gapTiny,
    borderBottom: '1px solid ' + Theme.colors.whiteGrey70,
    textAlign: 'center' as const,
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h5,
  },
  cellStyleResponsive: {
    padding: Theme.gapTiny,
    borderBottom: '1px solid ' + Theme.colors.whiteGrey70,
    textAlign: 'center' as const,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12.1905px',
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  cellStringText: {
    color: Theme.colors.black70,
  },
};

export default DailyViewPerDayBox;
