import React, { useEffect, useMemo, useState } from 'react';
import { AuthModule, ResultsAPIModule } from '../API';
import ResultsPage from './Results/ResultsPage';
import InputsPage, { IInputsPageResult } from './StrategyInputs/InputsPage';
import Header, { CurrentPage } from './Header/Header';
import { Theme } from '../Utils/Constants';
import { convertTestInputsTypes } from '../Utils/Converters';
import TestResultsParser from '../Utils/TestResultsParser';
import { BackTestAPIResponse, BackTestResponse } from '../API/API.interface';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import NavBar from './NavBar';
import LoaderDialog from './Dialogs/LoaderDialog';
import { Samples } from '../API/samples';
import { set } from 'date-fns';
import useWindowSize from './Hooks/useWindowSize';
import Loader from './Dialogs/Loader';
import BackTestResultsParser from '../Utils/BackTestResultParser';
import { SaveBacktestRequestBody } from '../API/HomePageAPI';
import useAppStore from '../store/app.store';
import { is } from 'date-fns/locale';

enum HomePageStep {
  Inputs,
  Loading,
  Results,
  Error,
}

const HomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  const navigate = useNavigate();
  useMemo(() => {
    AuthModule.getInstance()
      .isAuthenticated()
      .then((isAuthenticated) => {
        setIsAuthenticated(isAuthenticated);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inputs, setInputs] = useState<IInputsPageResult>(null as any);
  const [step, setStep] = useState<HomePageStep>(HomePageStep.Inputs);
  const [results, setResults] = useState<BackTestResultsParser>(null as any);
  const [savedResults, setSavedResults] = useState<BackTestResultsParser>(
    null as any
  );
  const [error, setError] = useState<string>('' as any);
  const [rawResponse, setRawResponse] = useState<any>(null);
  const {isStartBacktestClicked, setIsStartBacktestClicked} = useAppStore();
  const [savedData, setSavedData] = useState<SaveBacktestRequestBody>({
    input_filters: {
      index: '',
      strategy: '',
      start_date: '',
      end_date: '',
      entry_time: '',
      exit_time: '',
      underlying: '',
      strategy_type: '',
      risk_percent: '',
      legs: [],
    },
    runtest_data: {
      dailyBackTestResults: {},
      dayBackTestResults: {},
      mockTestAPIRequest: {},
      monthBackTestResults: {},
      resultsSummary: {},
      relative_performance: {},
    },
    metadata: {
      name: '',
      date: '',
      user_id: '',
    },
  });
  const [isNavUpdated, setisNavUpdated] = useState(false);
  const onInputsChanged = (strategy: IInputsPageResult) => {
    if (!isAuthenticated) {
      setIsStartBacktestClicked(true);
      navigate('/login', { replace: true });
    }
    setInputs(strategy);
    setStep(HomePageStep.Loading);
  };

  useMemo(() => {
    if (inputs === null) {
      return;
    }
    const dataObj = convertTestInputsTypes(inputs);
    // console.log('Inputs changed', dataObj);
    setStep(HomePageStep.Loading);
    new ResultsAPIModule()
      .getResults(dataObj)
      .then(async (res) => {
        const response = await res?.json();
        // console.log(response);

        if (res?.status === 200) {
          setRawResponse(response);
          showResults(response as BackTestResponse);
        } else {
          setStep(HomePageStep.Error);
          setError('Error processing the test results. Try again.');
        }
      })
      .catch((err) => {
        setStep(HomePageStep.Inputs);
        console.log(err);
      });
  }, [inputs]);

  function showResults(results: BackTestResponse) {
    setStep(HomePageStep.Results);
    const optionsParse = new BackTestResultsParser(results);
    setResults(optionsParse);
    updateRunBacktests();
  }

  function updateRunBacktests() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  
    const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  
    const apiUrl = process.env.REACT_APP_ME_API as string + 'backtest/save-run-details';
    const accessToken = AuthModule.getInstance().getAccessToken();
    const requestBody = {
      "datetime": currentDateTime,
    };
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }
 


  const windowSize = useWindowSize();
  const updateRequestBody = (newRequestBody: SaveBacktestRequestBody) => {
    setSavedData(newRequestBody);
  };

  return (
    <>
      <Header currentPage={CurrentPage.BackTesting} />
      {/* {isLoading && <Loader />} */}
      {isLoading ? (
        <Loader />
      ) : (
      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            marginBottom: isAuthenticated ? '' : '7%',
          }}
        >
          {isAuthenticated && windowSize > 800 && (
            <div style={styles.navBar}>
              {/* <NavBar onDataLoaded={handleDataLoaded} /> */}
              <NavBar
                updateRequestBody={updateRequestBody}
                isUpdated={isUpdated}
              />
            </div>
          )}

          {!isAuthenticated && windowSize > 800 && (
            <div style={{ width: '10%' }}>
              {/* <NavBar onDataLoaded={handleDataLoaded} /> */}
            </div>
          )}
          <div className='' style={windowSize > 800 ? { width: '78%' } : { width: 'calc(100% - 40px)' }}>
            {savedData.metadata.name === '' && (
              <div style={{ ...styles.fullPageContainer }}>
                <InputsPage
                  onInputsChanged={onInputsChanged}
                  isLoading={step === HomePageStep.Loading}
                  showPrintButton={step === HomePageStep.Results}
                  rawResults={rawResponse}
                  savedData={savedData}
                  setIsUpdated={setIsUpdated}
                />
                {step === HomePageStep.Loading && <LoaderDialog />}
                {step === HomePageStep.Results && (
                  <ResultsPage results={results} />
                )}
                {step === HomePageStep.Error && (
                  <div style={{ color: 'red' }}>{error}</div>
                )}
              </div>
            )}
            {savedData.metadata.name !== '' && (
              <div style={styles.fullPageContainer}>
                <InputsPage
                  onInputsChanged={onInputsChanged}
                  isLoading={step === HomePageStep.Loading}
                  showPrintButton={step === HomePageStep.Results}
                  rawResults={savedData.runtest_data}
                  savedData={savedData}
                  setIsUpdated={setIsUpdated}
                />
                {/* {step === HomePageStep.Loading && <LoaderDialog />} */}
                {Object.keys(savedData.runtest_data.dailyBackTestResults)
                  .length !== 0 && (
                  <ResultsPage
                    results={new BackTestResultsParser(savedData.runtest_data)}
                  />
                )}
                {step === HomePageStep.Error && (
                  <div style={{ color: 'red' }}>{error}</div>
                )}
              </div>
            )}
          </div>
          {!isAuthenticated && windowSize > 800 && (
            <div style={{ width: '10%' }}>
              {/* <NavBar onDataLoaded={handleDataLoaded} /> */}
            </div>
          )}
        </div>

        <div>
          <Footer />
        </div>
      </div>
      )}
    </>
  );
};

const styles = {
  container: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
  },
  navBar: {
    minWidth: Theme.navBarWidth,
    width: Theme.navBarWidth,
    // position: 'fixed' as const,
  },
  fullPageContainer: {
    padding: Theme.gapSmall,
    width: '100%',
  },
};

export default HomePage;
